import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import {
    Checkbox,
    IconButton,
    MenuItem,
    Select,
    type SxProps,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import * as Immutable from "immutable";
import { useCallback, useMemo } from "react";

import { config } from "../config";
import type { CategorizedAd } from "../meta/ad";
import {
    AD_PARAMETERS,
    type AdParameter,
    type AdParameterValues,
    type AdType,
    type Campaign,
} from "../meta/types";

const FAILED_AD_IDS = Immutable.Set(config.meta.failed_ad_ids);

const FAILED_AD_STYLES: SxProps = {
    color: "red",
};

const AD_PARAMETER_TITLES: Record<AdParameter, string> = {
    style: "Style",
    pillar: "Pillar",
    category: "Ad Category",
    assetType: "Asset Type",
    copy: "Ad Copy",
    influencerCode: "Influencer Code",
    reserved: "Reserved",
    timestamp: "Timestamp",
    iteration: "Iteration",
};

export interface MetaAdTableProps {
    adType: AdType;
    adParameters: Partial<AdParameterValues>;
    setAdParameters?: (adParameters: Partial<AdParameterValues>) => void;
    parameterValueSets: Immutable.Map<AdParameter, Immutable.Set<string>>;
    campaignsById: Immutable.Map<string, Campaign>;
    filteredAds: Immutable.List<CategorizedAd>;
    selectedAds?: Immutable.Set<string>;
    onToggleAd?: (ad: CategorizedAd, selected: boolean) => void;
    onCopyAdName?: (ad: CategorizedAd) => void;
}

export function MetaAdTable({
    adType,
    adParameters,
    parameterValueSets,
    setAdParameters,
    filteredAds,
    campaignsById,
    selectedAds,
    onToggleAd,
    onCopyAdName,
}: MetaAdTableProps) {
    const groupedAdsWithCampaigns = useMemo(
        () =>
            filteredAds
                .groupBy((ad) => ad.campaign_id)
                .map(
                    (ads, campaignId) =>
                        [ads, campaignsById.get(campaignId)!] satisfies [
                            Immutable.List<CategorizedAd>,
                            Campaign,
                        ],
                )
                .sortBy(([, campaign]) => campaign.name),
        [filteredAds, campaignsById],
    );

    const groupedAdRows = useMemo(
        () =>
            groupedAdsWithCampaigns
                .valueSeq()
                .map(([ads, campaign]) => (
                    <AdRowGroup
                        key={campaign.id}
                        campaign={campaign}
                        ads={ads}
                        selectedAds={selectedAds}
                        onToggleAd={onToggleAd}
                        onCopyAdName={onCopyAdName}
                    />
                )),
        [groupedAdsWithCampaigns, selectedAds, onToggleAd, onCopyAdName],
    );

    const hasHeader = adType === "standard" && setAdParameters !== undefined;

    return (
        <Table
            size="small"
            sx={{
                tableLayout: hasHeader ? "fixed" : "auto",
                "& .MuiTableCell-root": { padding: "4px" },
            }}
        >
            {hasHeader && (
                <MetaAdTableHeader
                    adParameters={adParameters}
                    parameterValueSets={parameterValueSets}
                    setAdParameters={setAdParameters}
                    selectedAds={selectedAds}
                    onToggleAd={onToggleAd}
                    onCopyAdName={onCopyAdName}
                />
            )}
            <TableBody>{groupedAdRows}</TableBody>
        </Table>
    );
}

interface MetaAdTableHeaderProps {
    adParameters: Partial<AdParameterValues>;
    parameterValueSets: Immutable.Map<AdParameter, Immutable.Set<string>>;
    setAdParameters: (adParameters: Partial<AdParameterValues>) => void;
    selectedAds?: Immutable.Set<string>;
    onToggleAd?: (ad: CategorizedAd, selected: boolean) => void;
    onCopyAdName?: (ad: CategorizedAd) => void;
}

function MetaAdTableHeader({
    adParameters,
    parameterValueSets,
    setAdParameters,
    onToggleAd,
    onCopyAdName,
}: MetaAdTableHeaderProps) {
    const parameterNameCells = useMemo(
        () =>
            AD_PARAMETERS.map((parameter) => (
                <TableCell key={parameter}>{AD_PARAMETER_TITLES[parameter]}</TableCell>
            )),
        [],
    );

    const setParameterValue = useCallback(
        (parameter: AdParameter, value: string) => {
            setAdParameters({ ...adParameters, [parameter]: value });
        },
        [adParameters, setAdParameters],
    );

    const parameterInputCells = useMemo(
        () =>
            AD_PARAMETERS.map((parameter) => {
                const valueSet = parameterValueSets.get(parameter);
                const value = adParameters[parameter];
                return (
                    <TableCell key={parameter}>
                        <Select
                            fullWidth
                            size="small"
                            value={value ?? ""}
                            disabled={valueSet === undefined || valueSet.size <= 1}
                            onChange={(event) => {
                                setParameterValue(parameter, event.target.value);
                            }}
                        >
                            <MenuItem value="">&nbsp;</MenuItem>
                            {valueSet?.map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </TableCell>
                );
            }),
        [parameterValueSets, adParameters, setParameterValue],
    );

    return (
        <TableHead>
            <TableRow
                sx={{
                    "& .MuiTableCell-root": {
                        paddingBottom: 0,
                    },
                }}
            >
                {onToggleAd && <TableCell sx={{ width: "3rem" }}>&nbsp;</TableCell>}
                {parameterNameCells}
                {onCopyAdName && <TableCell sx={{ width: "3rem" }}>&nbsp;</TableCell>}
            </TableRow>
            <TableRow>
                {onToggleAd && <TableCell>&nbsp;</TableCell>}
                {parameterInputCells}
                {onCopyAdName && <TableCell>&nbsp;</TableCell>}
            </TableRow>
        </TableHead>
    );
}

interface AdRowGroupProps {
    campaign: Campaign;
    ads: Immutable.List<CategorizedAd>;
    selectedAds?: Immutable.Set<string>;
    onToggleAd?: (ad: CategorizedAd, selected: boolean) => void;
    onCopyAdName?: (ad: CategorizedAd) => void;
}

function AdRowGroup({ campaign, ads, selectedAds, onToggleAd, onCopyAdName }: AdRowGroupProps) {
    return (
        <>
            <TableRow
                sx={{
                    "& .MuiTableCell-root": {
                        paddingTop: 2,
                        fontWeight: "bold",
                    },
                }}
            >
                {onToggleAd && <TableCell>&nbsp;</TableCell>}
                <TableCell colSpan={AD_PARAMETERS.length}>{campaign.name}</TableCell>
                {onCopyAdName && <TableCell>&nbsp;</TableCell>}
            </TableRow>
            {ads.map((ad) => {
                const failed = FAILED_AD_IDS.has(ad.id);
                return (
                    <TableRow
                        key={ad.id}
                        sx={{
                            "& .MuiTableCell-root": {
                                fontSize: "16px",
                            },
                            "&:hover .MuiTableCell-root": {
                                opacity: 1,
                            },
                            "& .MuiCheckbox-root": {
                                padding: 0,
                            },
                        }}
                    >
                        {onToggleAd && (
                            <TableCell>
                                <Checkbox
                                    checked={selectedAds?.includes(ad.id)}
                                    onChange={(event) => {
                                        onToggleAd(ad, event.target.checked);
                                    }}
                                    disabled={failed}
                                />
                            </TableCell>
                        )}
                        {ad.type === "standard" &&
                            AD_PARAMETERS.map((parameter) => (
                                <TableCell
                                    key={parameter}
                                    sx={{
                                        "&.MuiTableCell-root": {
                                            paddingLeft: "18px",
                                        },
                                        ...(failed && FAILED_AD_STYLES),
                                    }}
                                >
                                    {ad[parameter]}
                                </TableCell>
                            ))}
                        {ad.type === "dynamic" && (
                            <TableCell
                                colSpan={AD_PARAMETERS.length}
                                sx={{
                                    ...(failed && FAILED_AD_STYLES),
                                }}
                            >
                                {ad.name}
                            </TableCell>
                        )}
                        {onCopyAdName && (
                            <TableCell
                                align="right"
                                sx={{
                                    "&.MuiTableCell-root": {
                                        opacity: 0,
                                    },
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        onCopyAdName(ad);
                                    }}
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                );
            })}
        </>
    );
}
