import { createFileRoute } from "@tanstack/react-router";
import { Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/shopify/pricing")({
    component: Pricing,
    beforeLoad: () => ({
        getTitle: () => "Pricing",
    }),
});

function Pricing() {
    return <Outlet />;
}
