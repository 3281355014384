import { useQuery } from "@tanstack/react-query";

import { useApiClient } from "../api";

interface ProductImageProps {
    asin: string;
}

export function ProductImage({ asin }: ProductImageProps) {
    const apiClient = useApiClient();

    const catalogItemQuery = useQuery({
        queryKey: ["catalogItem", asin],
        queryFn: () =>
            apiClient.getCatalogItem({
                params: {
                    asin,
                },
            }),
    });

    const catalogItem = catalogItemQuery.data;
    const image = catalogItem?.images?.[0].images[0];
    return <img style={{ maxWidth: "100%" }} src={image?.link} alt={image?.variant} />;
}
