import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";

import type { SelectedProductDynamic } from "../amazon/types";
import { useApiClient } from "../api";

const DEFAULT_BAND = "42";
const DEFAULT_BUST = "DD";

interface ProductSelectProps {
    id: string;
    product: SelectedProductDynamic;
    onChange: (product: SelectedProductDynamic) => void;
    disabled?: boolean;
}

export function ProductSelect({ id, product, onChange, disabled = false }: ProductSelectProps) {
    const { style, color, band, bust, options } = product;

    const apiClient = useApiClient();

    const handleStyleChange = useCallback(
        (event: SelectChangeEvent) => {
            const style = Number.parseInt(event.target.value);
            onChange({
                state: "loading",
                style,
            });
        },
        [onChange],
    );

    const handleColorChange = useCallback(
        (event: SelectChangeEvent) => {
            const color = event.target.value;
            onChange({ ...product, state: "loading", color });
        },
        [onChange, product],
    );

    const handleBandChange = useCallback(
        (event: SelectChangeEvent) => {
            const band = event.target.value;
            onChange({ ...product, state: "loading", band });
        },
        [onChange, product],
    );

    const handleBustChange = useCallback(
        (event: SelectChangeEvent) => {
            const bust = event.target.value;
            onChange({ ...product, state: "loading", bust });
        },
        [onChange, product],
    );

    const stylesQuery = useQuery({
        queryKey: ["styles"],
        queryFn: () => apiClient.getStyles(),
    });

    const optionsQuery = useQuery({
        queryKey: ["options", style],
        queryFn: () => apiClient.getStyleOptions({ params: { style } }),
    });

    const productQuery = useQuery({
        enabled: [color, band, bust].every((value) => !!value),
        queryKey: ["products", style, color, band, bust],
        queryFn: async () => {
            const products = await apiClient.searchProducts({
                queries: {
                    style,
                    color: color!,
                    band: band!,
                    bust: bust!,
                },
            });
            return products[0];
        },
    });

    const styles = stylesQuery.data;
    const loadedOptions = optionsQuery.data;
    const loadedProduct = productQuery.data;

    useEffect(() => {
        if (product.state === "loading" && loadedProduct) {
            onChange({ ...loadedProduct, state: "loaded" });
        }
    }, [product, loadedProduct, onChange]);

    useEffect(() => {
        if (!options && loadedOptions) {
            onChange({
                ...product,
                color: product.color ?? loadedOptions.colors[0],
                band: product.band ?? DEFAULT_BAND,
                bust: product.bust ?? DEFAULT_BUST,
                options: loadedOptions,
            });
        }
    }, [options, loadedOptions, product, onChange]);

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id={`${id}-style-label`}>Style</InputLabel>
                <Select
                    labelId={`${id}-style-label`}
                    id={`${id}-style`}
                    label="Style"
                    value={style.toString()}
                    onChange={handleStyleChange}
                    disabled={disabled || !styles}
                >
                    {styles?.map((style) => (
                        <MenuItem key={style} value={style}>
                            {style}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id={`${id}-color-label`}>Color</InputLabel>
                <Select
                    labelId={`${id}-color-label`}
                    id={`${id}-color`}
                    label="Color"
                    value={color ?? ""}
                    onChange={handleColorChange}
                    disabled={disabled || !options}
                >
                    {color === undefined && <MenuItem key="" value="" />}
                    {options?.colors.map((color) => (
                        <MenuItem key={color} value={color}>
                            {color}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id={`${id}-band-label`}>Band</InputLabel>
                <Select
                    labelId={`${id}-band-label`}
                    id={`${id}-band`}
                    label="Band"
                    value={band ?? ""}
                    onChange={handleBandChange}
                    disabled={disabled || !options}
                >
                    {band === undefined && <MenuItem key="" value="" />}
                    {options?.bands.map((band) => (
                        <MenuItem key={band} value={band}>
                            {band}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id={`${id}-bust-label`}>Bust</InputLabel>
                <Select
                    labelId={`${id}-bust-label`}
                    id={`${id}-bust`}
                    label="Bust"
                    value={bust ?? ""}
                    onChange={handleBustChange}
                    disabled={disabled || !options}
                >
                    {bust === undefined && <MenuItem key="" value="" />}
                    {options?.busts.map((bust) => (
                        <MenuItem key={bust} value={bust}>
                            {bust}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
