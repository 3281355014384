import { round } from "../utils";

export function calculateKeywordBid(
    keywordBidType: "relative" | "absolute",
    keywordBidRelative: number,
    keywordBidAbsolute: number,
    initialKeywordBidAbsolute: number,
) {
    if (keywordBidType === "absolute") {
        return keywordBidAbsolute;
    }

    const adjustmentFactor = 1 + keywordBidRelative / 100;
    const adjustedBid = initialKeywordBidAbsolute * adjustmentFactor;
    return round(adjustedBid, 2);
}
