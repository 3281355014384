import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, CircularProgress, FormControl, Paper, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useApiClient } from "../../api";
import { ErrorMessage, PageTitle } from "../../components";

const formSchema = z.object({
    email: z.string().email(),
});

type FormValues = z.infer<typeof formSchema>;

export const Route = createFileRoute("/_no-auth/forgot-password")({
    component: ForgotPassword,
    beforeLoad: () => ({
        getTitle: () => "Forgot Password",
    }),
});

function ForgotPassword() {
    const navigate = Route.useNavigate();

    const apiClient = useApiClient();

    const mutation = useMutation({
        mutationFn: apiClient.requestPasswordReset,
        onSuccess: async () => {
            await navigate({ to: "/password-reset-sent" });
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(formSchema),
    });

    const onSubmit = useCallback(
        async (data: FormValues) => {
            await mutation.mutateAsync(data);
        },
        [mutation],
    );

    const busy = mutation.isPending;

    return (
        <Paper sx={{ padding: 2 }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <PageTitle />
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    sx={{ marginTop: 2 }}
                >
                    <FormControl fullWidth>
                        <Controller
                            name="email"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email Address"
                                    autoComplete="email"
                                    required
                                    autoFocus
                                    error={errors.email !== undefined}
                                    helperText={errors.email?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        disabled={busy}
                    >
                        {mutation.isPending ? (
                            <CircularProgress />
                        ) : (
                            <span>Send Password Reset Email</span>
                        )}
                    </Button>
                    {mutation.error && <ErrorMessage>{mutation.error.message}</ErrorMessage>}
                </Box>
            </Box>
        </Paper>
    );
}
