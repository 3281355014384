import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
    environmentSchema,
    newPricingUpdateSchema,
    pricesHeatmapDataSchema,
    pricingUpdateSchema,
    pricingUpdateStatusSchema,
    refreshPricesRequestSchema,
    themeSchema,
} from "../shopify/types";

export const api = makeApi([
    {
        method: "get",
        path: "/style-colors",
        alias: "shopifyGetStyleColors",
        description: "Get style colors",
        parameters: [],
        response: z.record(z.string(), z.array(z.string())),
    },
    {
        method: "get",
        path: "/themes",
        alias: "shopifyGetThemes",
        description: "Get themes",
        parameters: [],
        response: z.array(themeSchema),
    },
    {
        method: "get",
        path: "/prices",
        alias: "shopifyGetPrices",
        description: "Get prices",
        parameters: [
            {
                name: "environment",
                type: "Query",
                schema: environmentSchema,
            },
        ],
        response: pricesHeatmapDataSchema,
    },
    {
        method: "post",
        path: "/prices/refresh",
        alias: "shopifyRefreshPrices",
        description: "Refresh prices",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: refreshPricesRequestSchema,
            },
        ],
        response: z.unknown(),
    },
    {
        method: "get",
        path: "/pricing",
        alias: "shopifyGetPricingUpdates",
        description: "Get pricing updates",
        parameters: [
            {
                name: "statuses",
                type: "Query",
                schema: z.array(pricingUpdateStatusSchema).optional(),
            },
        ],
        response: z.array(pricingUpdateSchema),
    },
    {
        method: "get",
        path: "/pricing/:updateId",
        alias: "shopifyGetPricingUpdate",
        description: "Get pricing update",
        parameters: [],
        response: pricingUpdateSchema,
    },
    {
        method: "post",
        path: "/pricing",
        alias: "shopifyCreatePricingUpdate",
        description: "Create pricing update",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: newPricingUpdateSchema,
            },
        ],
        response: pricingUpdateSchema,
    },
    {
        method: "put",
        path: "/pricing/:updateId",
        alias: "shopifyUpdatePricingUpdate",
        description: "Update pricing update",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: newPricingUpdateSchema.partial(),
            },
        ],
        response: pricingUpdateSchema,
    },
    {
        method: "delete",
        path: "/pricing/:updateId",
        alias: "shopifyDeletePricingUpdate",
        description: "Delete pricing update",
        response: z.unknown(),
    },
    {
        method: "post",
        path: "/pricing/:updateId/duplicate",
        alias: "shopifyDuplicatePricingUpdate",
        description: "Duplicate pricing update",
        response: pricingUpdateSchema,
    },
    {
        method: "get",
        path: "/pricing-persistent",
        alias: "shopifyGetPersistentPricingUpdate",
        description: "Get persistent pricing update",
        response: pricingUpdateSchema,
    },
    {
        method: "put",
        path: "/pricing-persistent",
        alias: "shopifyUpdatePersistentPricingUpdate",
        description: "Update persistent pricing update",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: newPricingUpdateSchema.partial(),
            },
        ],
        response: pricingUpdateSchema,
    },
]);
