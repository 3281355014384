import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { useApiClient } from "../../../../api";
import { Loading, MetaAdForm } from "../../../../components";
import type { AdUpdate } from "../../../../meta/types";

export const Route = createFileRoute("/_auth/meta/ad-updates/$updateId/edit")({
    component: UpdatePricingUpdate,
    beforeLoad: () => ({
        getTitle: () => "Edit",
    }),
});

function UpdatePricingUpdate() {
    const apiClient = useApiClient();
    const queryClient = useQueryClient();
    const { updateId } = Route.useParams();
    const navigate = Route.useNavigate();

    const updateQuery = useQuery({
        queryKey: ["metaAdUpdates", updateId],
        queryFn: () => apiClient.metaGetAdUpdate({ params: { updateId } }),
    });

    const mutation = useMutation({
        mutationFn: async (data: Partial<AdUpdate>) =>
            apiClient.metaUpdateAdUpdate(data, { params: { updateId } }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["metaAdUpdates"] });
            await navigate({ to: "/meta/ad-updates" });
        },
    });

    return updateQuery.isLoading ? (
        <Loading />
    ) : (
        <MetaAdForm initialData={updateQuery.data} mutation={mutation} />
    );
}
