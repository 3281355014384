import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import { type ChangeEvent, useCallback, useMemo, useState } from "react";

import type { MediaAsset } from "../amazon/types";

type AssetSelectModalType = "video" | "image";

export interface AssetSelectModalProps {
    title: string;
    type: AssetSelectModalType;
    options: MediaAsset[];
    value: string | null;
    onChange: (value: string | null) => void;
    open: boolean;
    onClose: () => void;
}

export function AssetSelectModal({
    title,
    type,
    options,
    // value,
    onChange,
    open,
    onClose,
}: AssetSelectModalProps) {
    const [pendingValue, setPendingValue] = useState<string>(options[0]?.id ?? "");
    const [searchText, setSearchText] = useState("");

    const filteredOptions = useMemo(() => {
        return options.filter((option) =>
            option.name.toLowerCase().includes(searchText.toLowerCase()),
        );
    }, [options, searchText]);

    const gridItems = useMemo(
        () =>
            filteredOptions.map(({ id, url, name }) => {
                const handleClick = () => {
                    setPendingValue(id);
                };

                const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
                    setPendingValue(event.target.value);
                };

                return (
                    <Grid item xs={6} key={id}>
                        {type === "image" ? (
                            <img
                                style={{ width: "100%" }}
                                src={url}
                                alt={url}
                                onClick={handleClick}
                            />
                        ) : (
                            <video
                                style={{ width: "100%" }}
                                src={url}
                                controls
                                onClick={handleClick}
                            />
                        )}
                        <RadioGroup value={pendingValue} onChange={handleChange}>
                            <FormControlLabel
                                sx={{ overflowWrap: "anywhere" }}
                                value={id}
                                control={<Radio />}
                                label={name}
                            />
                        </RadioGroup>
                    </Grid>
                );
            }),
        [filteredOptions, type, pendingValue],
    );

    const onSelect = useCallback(() => {
        onChange(pendingValue);
        onClose();
    }, [onChange, pendingValue, onClose]);

    const handleSearchTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }, []);

    return (
        <Dialog maxWidth="md" fullWidth scroll="paper" open={open} onClose={onClose}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <DialogTitle>{title}</DialogTitle>
                <FormControl sx={{ minWidth: "300px", padding: 3 }}>
                    <TextField
                        label="Search by name"
                        value={searchText}
                        onChange={handleSearchTextChange}
                    />
                </FormControl>
            </Box>
            <DialogContent>
                <Grid container spacing={2}>
                    {gridItems}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSelect}>Select</Button>
            </DialogActions>
        </Dialog>
    );
}
