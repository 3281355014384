import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    type SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import chroma from "chroma-js";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useApiClient } from "../../../../api";
import { Loading } from "../../../../components";
import { CURRENCY_CODES_BY_COUNTRY } from "../../../../shopify/pricing";
import {
    ACTIVE_COUNTRY_CODES,
    ENVIRONMENTS,
    ENVIRONMENT_LABELS,
    type Environment,
} from "../../../../shopify/types";
import { CARD_STYLE } from "../../../../styles";
import { formatDateTime } from "../../../../utils";

export const Route = createFileRoute("/_auth/shopify/pricing/heatmap")({
    component: PricingHeatmap,
    beforeLoad: () => ({
        getTitle: () => "Heatmap",
    }),
});

const COLOR_SCALE = chroma.scale("OrRd");

function contrastColor(color: chroma.Color) {
    if (color.luminance() > 0.5) {
        return color.darken(3.0);
    }

    return color.brighten(3.0);
}

function PricingHeatmap() {
    const apiClient = useApiClient();
    const queryClient = useQueryClient();
    const [environment, setEnvironment] = useState<Environment>("production");

    const pricesQuery = useQuery({
        queryKey: ["shopify", "prices", environment],
        queryFn: () => apiClient.shopifyGetPrices({ queries: { environment } }),
    });

    const refreshMutation = useMutation({
        mutationFn: () => apiClient.shopifyRefreshPrices({ environment }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["shopify", "prices", environment] });
        },
    });

    const onEnvironmentChange = (event: SelectChangeEvent) => {
        setEnvironment(event.target.value as Environment);
    };

    const { prices, last_ingested } = pricesQuery.data ?? {};
    const priceRows = useMemo(
        () =>
            prices?.map((priceData) => (
                <TableRow key={priceData.style + priceData.color_code}>
                    <TableCell>{priceData.style}</TableCell>
                    <TableCell>{priceData.color_code}</TableCell>
                    {ACTIVE_COUNTRY_CODES.map((country) => {
                        const price = priceData.prices[country];
                        const msrp = priceData.msrps[country];

                        if (price === undefined || msrp === undefined) {
                            return null;
                        }

                        const discount = 1.0 - +price / +msrp;
                        const discountPercent = discount * 100;
                        const bg = COLOR_SCALE(discount);
                        const fg = contrastColor(bg);
                        return (
                            <TableCell
                                key={country}
                                sx={{
                                    fontWeight: "bold",
                                    backgroundColor: bg.hex(),
                                    color: fg.hex(),
                                }}
                            >
                                {price}{" "}
                                {discount > 0 && (
                                    <Typography variant="caption">
                                        {discountPercent.toFixed(2)}% off
                                    </Typography>
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
            )),
        [prices],
    );

    const formattedDate = last_ingested ? formatDateTime(DateTime.fromISO(last_ingested)) : null;

    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            ...CARD_STYLE,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <FormControl>
                            <InputLabel id="environment-label">Environment</InputLabel>
                            <Select
                                size="small"
                                id="environment"
                                labelId="environment-label"
                                label="Environment"
                                value={environment}
                                onChange={onEnvironmentChange}
                            >
                                {ENVIRONMENTS.map((environment) => (
                                    <MenuItem key={environment} value={environment}>
                                        {ENVIRONMENT_LABELS[environment]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => refreshMutation.mutate()}
                            disabled={refreshMutation.isPending}
                        >
                            {refreshMutation.isPending ? (
                                <CircularProgress size={30} />
                            ) : (
                                <span>Refresh</span>
                            )}
                        </Button>
                        {formattedDate && <Typography>Data as of {formattedDate}</Typography>}
                        <Box sx={{ flex: 1 }} />
                        {/* environment selector */}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ ...CARD_STYLE, display: "flex", flexDirection: "column" }}>
                        {pricesQuery.isLoading && <Loading />}
                        {!pricesQuery.isLoading && (
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Style</TableCell>
                                            <TableCell>Color</TableCell>
                                            {ACTIVE_COUNTRY_CODES.map((country) => (
                                                <TableCell key={country}>
                                                    {country}{" "}
                                                    <Typography variant="caption">
                                                        {CURRENCY_CODES_BY_COUNTRY[country]}
                                                    </Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{priceRows}</TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
