import { Box, CircularProgress, List, ListItem } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import type { CampaignType } from "../amazon/types";
import { useApiClient } from "../api";

export interface CampaignListProps {
    profileId: number;
    campaignIds: string[];
    campaignType: CampaignType;
}

export function CampaignList({ profileId, campaignIds, campaignType }: CampaignListProps) {
    const apiClient = useApiClient();

    const campaignNamesQuery = useQuery({
        queryKey: ["campaignNames", profileId, campaignIds],
        queryFn: () =>
            apiClient.getCampaignNames({
                profile_id: profileId,
                campaign_ids: campaignIds,
                campaign_type: campaignType,
            }),
    });

    const campaignNames = campaignNamesQuery.data;

    return campaignNames ? (
        <List disablePadding>
            {campaignIds.map((campaignId) => (
                <ListItem key={campaignId} disablePadding>
                    {campaignNames[campaignId]}
                </ListItem>
            ))}
        </List>
    ) : (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress size={60} />
        </Box>
    );
}
