import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    List,
    ListItem,
    MenuItem,
    Paper,
    Select,
    type SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { isAxiosError } from "axios";
import * as Immutable from "immutable";
import { type ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
    AssetSelectModal,
    CampaignList,
    ErrorMessage,
    Loading,
    ProductSelect,
    SalesList,
    UpdateConfirmationModal,
} from ".";
import { useAlerts } from "../alerts";
import { STYLE_NONE, getCommonStyles, getStylesFromCampaignName } from "../amazon/campaign";
import type { CampaignType, SelectedProductDynamic } from "../amazon/types";
import { useApiClient } from "../api";
import { sponsoredVideoStoreCreativeUpdateSchema } from "../api/types";
import { AD_THUMBNAIL_STYLE, CARD_STYLE } from "../styles";
import { pluralize } from "../utils";

export interface VideoStoreFormProps {
    profile_id: number;
    campaign_ids: string[];
    campaign_type: CampaignType;
}

export function VideoStoreForm({ profile_id, campaign_ids, campaign_type }: VideoStoreFormProps) {
    const navigate = useNavigate();

    const { addAlert } = useAlerts();

    const apiClient = useApiClient();
    const queryClient = useQueryClient();

    const campaignNamesQuery = useQuery({
        queryKey: ["campaignNames", profile_id, campaign_ids],
        queryFn: () =>
            apiClient.getCampaignNames({
                profile_id,
                campaign_ids,
                campaign_type: "sponsored-brands",
            }),
    });

    const logosQuery = useQuery({
        queryKey: ["logos", profile_id],
        queryFn: () => apiClient.getLogos({ queries: { profile_id } }),
    });

    const videosQuery = useQuery({
        queryKey: ["videos", profile_id],
        queryFn: () => apiClient.getVideos({ queries: { profile_id } }),
    });

    const headlinesQuery = useQuery({
        queryKey: ["headlines", profile_id],
        queryFn: () => apiClient.getHeadlines({ queries: { profile_id } }),
    });

    const videoStoreQuery = useQuery({
        queryKey: ["videoStoreCreative", profile_id, campaign_ids],
        queryFn: () => apiClient.getSponsoredVideoStoreCreative({ profile_id, campaign_ids }),
        retry: false,
    });

    const mutation = useMutation({
        mutationFn: apiClient.updateSponsoredVideoStoreCreative,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["videoStoreCreative", profile_id, campaign_ids],
            });
            const campaignCount = campaign_ids.length;
            const updatesWord = pluralize(campaignCount, "update");
            addAlert({
                type: "success",
                message: `${campaignCount} ${updatesWord} queued`,
            });
            await navigate({
                to: "/amazon/bulk-creative-update",
                search: { campaignType: "sponsored-video" },
            });
        },
    });

    const [logoModalOpen, setLogoModalOpen] = useState(false);
    const [usingCurrentLogo, setUsingCurrentLogo] = useState(false);
    const [newLogoId, setNewLogoId] = useState<string | null>(null);
    const [editingLogo, setEditingLogo] = useState(true);

    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [usingCurrentVideo, setUsingCurrentVideo] = useState(false);
    const [newVideoId, setNewVideoId] = useState<string | null>(null);
    const [editingVideo, setEditingVideo] = useState(true);

    const [selectingHeadline, setSelectingHeadline] = useState(false);
    const [selectedHeadline, setSelectedHeadline] = useState<string | null>(null);
    const [newHeadline, setNewHeadline] = useState("");
    const [editingHeadline, setEditingHeadline] = useState(true);

    const [selectedProducts, setSelectedProducts] = useState<
        Immutable.List<SelectedProductDynamic>
    >(Immutable.List());
    const [editingProducts, setEditingProducts] = useState(Immutable.List([true, true, true]));

    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const campaignNames = campaignNamesQuery.data;
    const logos = logosQuery.data;
    const videos = videosQuery.data;
    const headlines = headlinesQuery.data;
    const initialData = videoStoreQuery.data;

    const currentLogo = initialData?.logo;
    const newLogo = logos?.find((logo) => logo.id === newLogoId);
    const logo = usingCurrentLogo ? currentLogo : newLogo;
    const logoId = logo?.id;

    const currentVideo = initialData?.video;
    const newVideo = videos?.find((video) => video.id === newVideoId);
    const video = usingCurrentVideo ? currentVideo : newVideo;
    const videoId = video?.id;

    const headline = selectingHeadline ? selectedHeadline : newHeadline;

    const validatedProducts = useMemo(
        () => selectedProducts.toArray().filter((product) => product.state === "loaded"),
        [selectedProducts],
    );

    const styles = useMemo(
        () => validatedProducts.map((product) => product.style),
        [validatedProducts],
    );

    const commonStyles = useMemo(() => {
        const styleGroups = Object.values(campaignNames ?? {})
            .map((name) => getStylesFromCampaignName(name))
            .filter((styles) => styles !== undefined);
        return getCommonStyles(styleGroups);
    }, [campaignNames]);

    const enabledProducts = useMemo(
        () => commonStyles.map((style) => style !== STYLE_NONE),
        [commonStyles],
    );

    const asins = useMemo(
        () =>
            validatedProducts.map((product, index) =>
                enabledProducts[index] && editingProducts.get(index) ? product.asin : null,
            ),
        [validatedProducts, enabledProducts, editingProducts],
    );

    const updateResult = useMemo(() => {
        return sponsoredVideoStoreCreativeUpdateSchema.safeParse({
            profile_id,
            campaign_ids,
            campaign_type,
            headline: editingHeadline ? headline : null,
            logo_id: editingLogo ? logoId : null,
            video_id: editingVideo ? videoId : null,
            asins,
        });
    }, [
        profile_id,
        campaign_ids,
        campaign_type,
        editingHeadline,
        headline,
        editingLogo,
        logoId,
        editingVideo,
        videoId,
        asins,
    ]);

    const updateErrors = useMemo(() => {
        return updateResult.error?.format();
    }, [updateResult]);

    const valid = updateErrors === undefined;
    const busy = mutation.isPending;

    useEffect(() => {
        if (initialData) {
            const products: SelectedProductDynamic[] = initialData.products.map((product) => ({
                state: "loaded",
                ...product,
            }));

            setSelectedProducts(Immutable.List(products));
            setNewLogoId(initialData.logo?.id ?? null);
            setNewVideoId(initialData.video?.id ?? null);
            setUsingCurrentLogo(initialData.logo !== null);
            setUsingCurrentVideo(initialData.video !== null);
            setSelectingHeadline(initialData.headline !== null);
            setSelectedHeadline(initialData.headline);
            setNewHeadline(initialData.headline ?? "");
        }
    }, [initialData]);

    const handleUsingCurrentLogoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setUsingCurrentLogo(event.target.checked);
    }, []);

    const handleEditingLogoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingLogo(event.target.checked);
    }, []);

    const handleUsingCurrentVideoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setUsingCurrentVideo(event.target.checked);
    }, []);

    const handleEditingVideoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingVideo(event.target.checked);
    }, []);

    const handleSelectingHeadlineChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSelectingHeadline(event.target.checked);
    }, []);

    const handleSelectedHeadlineChange = useCallback((event: SelectChangeEvent<string | null>) => {
        setSelectedHeadline(event.target.value);
    }, []);

    const handleNewHeadlineChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setNewHeadline(event.target.value);
    }, []);

    const handleEditingHeadlineChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingHeadline(event.target.checked);
    }, []);

    const handleProductChange = useCallback((index: number, product: SelectedProductDynamic) => {
        setSelectedProducts((products) => products.set(index, product));
    }, []);

    const handleEditingProductChange = useCallback(
        (index: number, event: ChangeEvent<HTMLInputElement>) => {
            setEditingProducts((products) => products.set(index, event.target.checked));
        },
        [],
    );

    const showConfirmation = useCallback(() => {
        setConfirmationVisible(true);
    }, []);

    const hideConfirmation = useCallback(() => {
        setConfirmationVisible(false);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (updateResult.success) {
            await mutation.mutateAsync(updateResult.data);
        }
    }, [updateResult, mutation]);

    const productGridItems = useMemo(
        () =>
            selectedProducts.map((product, index) => {
                const humanIndex = index + 1;
                return (
                    // biome-ignore lint/suspicious/noArrayIndexKey:
                    <Grid item xs={4} key={index}>
                        <Paper sx={CARD_STYLE}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            enabledProducts[index] && editingProducts.get(index)
                                        }
                                        onChange={(event) =>
                                            handleEditingProductChange(index, event)
                                        }
                                    />
                                }
                                label={
                                    <Typography fontWeight={500}>
                                        Current ASIN {humanIndex}
                                    </Typography>
                                }
                                disabled={!enabledProducts[index]}
                            />
                            <ProductSelect
                                id={`product-${humanIndex}`}
                                product={product}
                                onChange={(product) => {
                                    handleProductChange(index, product);
                                }}
                                disabled={!enabledProducts[index] || !editingProducts.get(index)}
                            />
                        </Paper>
                    </Grid>
                );
            }),
        [
            selectedProducts,
            editingProducts,
            handleEditingProductChange,
            handleProductChange,
            enabledProducts,
        ],
    );

    const error = videoStoreQuery.error;
    const errorIs404 = isAxiosError(error) && error.response?.status === 404;

    return (
        <Box>
            <AssetSelectModal
                type="image"
                title="Available Logos"
                options={logos ?? []}
                value={newLogoId}
                onChange={setNewLogoId}
                open={logoModalOpen}
                onClose={() => {
                    setLogoModalOpen(false);
                }}
            />
            <AssetSelectModal
                type="video"
                title="Available Videos"
                options={videos ?? []}
                value={newVideoId}
                onChange={setNewVideoId}
                open={videoModalOpen}
                onClose={() => {
                    setVideoModalOpen(false);
                }}
            />
            <UpdateConfirmationModal
                open={confirmationVisible}
                busy={busy}
                onClose={hideConfirmation}
                onConfirm={handleSubmit}
            >
                <List>
                    {editingVideo && (
                        <>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>Video:</Typography>
                                &nbsp;
                                {video?.id}
                            </ListItem>
                            {video && (
                                <ListItem>
                                    <video src={video.url} style={AD_THUMBNAIL_STYLE} controls />
                                </ListItem>
                            )}
                        </>
                    )}
                    {editingHeadline && (
                        <ListItem disablePadding>
                            <Typography fontWeight={500}>Headline:</Typography>
                            &nbsp;
                            {headline}
                        </ListItem>
                    )}
                    {editingLogo && (
                        <>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>Logo:</Typography>
                                &nbsp;
                                {logo?.id}
                            </ListItem>
                            {logo && (
                                <ListItem>
                                    <img style={AD_THUMBNAIL_STYLE} src={logo.url} />
                                </ListItem>
                            )}
                        </>
                    )}
                    {validatedProducts.map(
                        (product, index) =>
                            enabledProducts[index] &&
                            editingProducts.get(index) && (
                                <Fragment key={product.sku}>
                                    <ListItem disablePadding>
                                        <Typography fontWeight={500}>SKU {index + 1}:</Typography>
                                        &nbsp;
                                        {product.sku}
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Typography fontWeight={500}>ASIN {index + 1}:</Typography>
                                        &nbsp;
                                        {product.asin}
                                    </ListItem>
                                </Fragment>
                            ),
                    )}
                </List>
            </UpdateConfirmationModal>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Sales by Style/Color</Typography>
                        <SalesList profileId={profile_id} styles={styles} />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Selected Campaigns</Typography>
                        <CampaignList
                            profileId={profile_id}
                            campaignIds={campaign_ids}
                            campaignType="sponsored-video"
                        />
                    </Paper>
                </Grid>
                {error ? (
                    <Grid item xs={12}>
                        {errorIs404 ? (
                            <Paper sx={CARD_STYLE}>
                                <Typography fontWeight={500}>
                                    No ads currently live. Please create or enable a Sponsored Video
                                    ad first.
                                </Typography>
                            </Paper>
                        ) : (
                            <Paper sx={CARD_STYLE}>
                                <ErrorMessage>{error.message}</ErrorMessage>
                            </Paper>
                        )}
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={4}>
                            <Paper sx={CARD_STYLE}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editingLogo}
                                            onChange={handleEditingLogoChange}
                                        />
                                    }
                                    label={<Typography fontWeight={500}>Logo</Typography>}
                                />
                                <Box sx={{ opacity: editingLogo ? 1 : 0.5 }}>
                                    <Typography>Current logo:</Typography>
                                    {logo ? (
                                        <img src={logo.url} style={AD_THUMBNAIL_STYLE} />
                                    ) : (
                                        <Typography fontStyle="italic">None</Typography>
                                    )}
                                </Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={usingCurrentLogo}
                                            onChange={handleUsingCurrentLogoChange}
                                        />
                                    }
                                    label="Use current logo"
                                    disabled={!editingLogo}
                                />
                                {!usingCurrentLogo && (
                                    <FormControl
                                        fullWidth
                                        error={updateErrors?.logo_id !== undefined}
                                        disabled={!editingLogo}
                                    >
                                        <Button
                                            onClick={() => {
                                                setLogoModalOpen(true);
                                            }}
                                        >
                                            Select Logo
                                        </Button>
                                        {updateErrors?.logo_id && (
                                            <FormHelperText>
                                                {updateErrors.logo_id._errors[0]}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={CARD_STYLE}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editingVideo}
                                            onChange={handleEditingVideoChange}
                                        />
                                    }
                                    label={<Typography fontWeight={500}>Video</Typography>}
                                />
                                <Box sx={{ opacity: editingVideo ? 1 : 0.5 }}>
                                    <Typography>Current video:</Typography>
                                    {video ? (
                                        <video
                                            src={video.url}
                                            style={AD_THUMBNAIL_STYLE}
                                            controls
                                        />
                                    ) : (
                                        <Typography fontStyle="italic">None</Typography>
                                    )}
                                </Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={usingCurrentVideo}
                                            onChange={handleUsingCurrentVideoChange}
                                            disabled={!editingVideo}
                                        />
                                    }
                                    label="Use current video"
                                />
                                {!usingCurrentVideo && (
                                    <FormControl
                                        fullWidth
                                        error={updateErrors?.video_id !== undefined}
                                        disabled={!editingVideo}
                                    >
                                        <Button
                                            onClick={() => {
                                                setVideoModalOpen(true);
                                            }}
                                        >
                                            Select Video
                                        </Button>
                                        {updateErrors?.video_id && (
                                            <FormHelperText>
                                                {updateErrors.video_id._errors[0]}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={CARD_STYLE}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editingHeadline}
                                            onChange={handleEditingHeadlineChange}
                                        />
                                    }
                                    label={<Typography fontWeight={500}>Headline</Typography>}
                                />
                                <Box sx={{ opacity: editingHeadline ? 1 : 0.5 }}>
                                    <Typography>Current headline:</Typography>
                                    <Typography fontWeight={500}>
                                        {headline ? headline : <span>&nbsp;</span>}
                                    </Typography>
                                </Box>
                                <FormControl fullWidth disabled={!selectingHeadline || !headlines}>
                                    <Select
                                        value={selectedHeadline ?? ""}
                                        onChange={handleSelectedHeadlineChange}
                                        error={updateErrors?.headline !== undefined}
                                    >
                                        {headlines?.map((headline, index) => (
                                            <MenuItem key={headline} value={headline}>
                                                {headline}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {selectingHeadline && updateErrors?.headline && (
                                        <FormHelperText>
                                            {updateErrors.headline._errors[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectingHeadline}
                                            onChange={handleSelectingHeadlineChange}
                                        />
                                    }
                                    label="Select an existing headline"
                                    disabled={!editingHeadline}
                                />
                                {!selectingHeadline && (
                                    <FormControl
                                        fullWidth
                                        sx={{ marginTop: 2 }}
                                        disabled={!editingHeadline}
                                    >
                                        <TextField
                                            id="new-headline"
                                            label="New headline"
                                            value={newHeadline}
                                            onChange={handleNewHeadlineChange}
                                            error={updateErrors?.headline !== undefined}
                                            helperText={updateErrors?.headline?._errors[0]}
                                        />
                                    </FormControl>
                                )}
                            </Paper>
                        </Grid>
                        {productGridItems}
                        {videoStoreQuery.isLoading && (
                            <Grid item xs={12}>
                                <Loading />
                            </Grid>
                        )}
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            {mutation.error && (
                                <ErrorMessage>{mutation.error.message}</ErrorMessage>
                            )}
                            <Button
                                variant="contained"
                                onClick={showConfirmation}
                                disabled={!valid || busy}
                            >
                                Submit Ad
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}
