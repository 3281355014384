import { ArrowCircleRight as ArrowCircleRightIcon } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { type UseMutationResult, useQuery } from "@tanstack/react-query";
import * as Immutable from "immutable";
import { DateTime } from "luxon";
import {
    type ChangeEvent,
    type FormEvent,
    type SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import { useApiClient } from "../api";
import {
    type AdCopy,
    type CategorizedAd,
    categorizeAds,
    filterAds,
    filterParametersFromValueSets,
    getAdCopy,
    getParameterValueSets,
} from "../meta/ad";
import {
    type AdParameterValues,
    type AdStatus,
    type AdType,
    type AdUpdate,
    type NewAdUpdate,
    newAdUpdateSchema,
} from "../meta/types";
import { CARD_STYLE } from "../styles";
import type { Mutator } from "../types";
import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";
import { MetaAdTable } from "./MetaAdTable";
import { PageTitle } from "./PageTitle";

type FormSection = "ads" | "details";

export interface MetaAdFormProps {
    initialData?: AdUpdate;
    mutation: UseMutationResult<AdUpdate, Error, Partial<NewAdUpdate>>;
}

export function MetaAdForm({ initialData, mutation }: MetaAdFormProps) {
    const apiClient = useApiClient();

    const isNew = initialData === undefined;
    const [activeSection, setActiveSection] = useState<FormSection>(isNew ? "ads" : "details");

    const [selectedCampaigns, setSelectedCampaigns] = useState<Immutable.Set<string>>(
        Immutable.Set(initialData?.campaigns ?? []),
    );
    const [selectedAds, setSelectedAds] = useState<Immutable.Set<string>>(
        Immutable.Set(initialData?.ads ?? []),
    );
    const [adType, setAdType] = useState<AdType>(initialData?.ad_type ?? "standard");
    const [adStatus, setAdStatus] = useState<AdStatus | undefined>(
        initialData?.ad_status ?? "ACTIVE",
    );
    const [adParameters, setAdParameters] = useState<Partial<AdParameterValues>>(
        initialData?.ad_parameters ?? {},
    );

    const [selectedPrimaryText, setSelectedPrimaryText] = useState("");
    const [selectedHeadline, setSelectedHeadline] = useState("");
    const [selectedDescription, setSelectedDescription] = useState("");
    const [selectedUrlQueryString, setSelectedUrlQueryString] = useState("");

    const [primaryText, setPrimaryText] = useState(initialData?.primary_text ?? "");
    const [headline, setHeadline] = useState(initialData?.headline ?? "");
    const [description, setDescription] = useState(initialData?.description ?? "");
    const [urlQueryString, setUrlQueryString] = useState(initialData?.url_query_string ?? "");

    const [scheduledAt, setScheduledAt] = useState<DateTime | null>(
        initialData?.scheduled_at ? DateTime.fromISO(initialData.scheduled_at) : null,
    );
    const [draft, setDraft] = useState(initialData ? initialData.status === "draft" : true);
    const [name, setName] = useState(initialData?.name ?? "");

    const campaignsQuery = useQuery({
        queryKey: ["metaCampaigns"],
        queryFn: () => apiClient.metaGetCampaigns(),
    });

    const campaigns = useMemo(
        () => Immutable.List(campaignsQuery.data ?? []).sortBy((campaign) => campaign.name),
        [campaignsQuery.data],
    );
    const campaignsById = useMemo(
        () => Immutable.Map(campaigns.map((campaign) => [campaign.id, campaign])),
        [campaigns],
    );
    const campaignIds = useMemo(() => campaignsById.keySeq().toArray(), [campaignsById]);

    const adsQuery = useQuery({
        enabled: !!campaignIds,
        queryKey: ["metaAds", campaignIds],
        queryFn: () => apiClient.metaGetAds({ campaign_ids: campaignIds }),
    });

    const rawAds = useMemo(
        () => Immutable.List(adsQuery.data ?? []).sortBy((ad) => ad.name),
        [adsQuery.data],
    );
    const { ads, standardAds } = useMemo(() => categorizeAds(rawAds), [rawAds]);

    const adCopyValues = useMemo(
        () => ads.filter((ad) => ad.type === adType).map((ad) => getAdCopy(ad)),
        [ads, adType],
    );
    const primaryTextValues = useMemo(
        () => Immutable.Set(adCopyValues.map((copy) => copy?.primaryText)).sort(),
        [adCopyValues],
    );
    const headlineValues = useMemo(
        () => Immutable.Set(adCopyValues.map((copy) => copy?.headline)).sort(),
        [adCopyValues],
    );
    const descriptionValues = useMemo(
        () => Immutable.Set(adCopyValues.map((copy) => copy?.description)).sort(),
        [adCopyValues],
    );
    const urlQueryStringValues = useMemo(
        () =>
            Immutable.Set(ads.map((ad) => ad.creative.url_tags))
                .filter((value) => !!value)
                .sort() as Immutable.Set<string>,
        [ads],
    );

    const parameterValueSets = useMemo(
        () => getParameterValueSets(standardAds.toArray()),
        [standardAds],
    );

    const filteredAds = useMemo(
        () =>
            filterAds({
                adType,
                adStatus,
                adParameters,
                selectedCampaignIds: selectedCampaigns,
                ads,
            }),
        [ads, adType, adStatus, adParameters, selectedCampaigns],
    );

    const filteredSelectedAds = useMemo(
        () => filteredAds.filter((ad) => selectedAds.includes(ad.id)),
        [filteredAds, selectedAds],
    );

    const [primaryAd, primaryAdCopy] = useMemo(
        () =>
            filteredAds
                .toSeq()
                .map((ad) => [ad, getAdCopy(ad)] satisfies [CategorizedAd, AdCopy | undefined])
                .find(([, copy]) => copy !== undefined) ?? [],
        [filteredAds],
    );

    const onCopyAdName = useCallback(async (ad: CategorizedAd) => {
        await navigator.clipboard.writeText(ad.name);
    }, []);

    const onSectionChange = useCallback((event: SyntheticEvent, section: FormSection) => {
        setActiveSection(section);
    }, []);

    const updateSelectedCampaigns = useCallback((mutator: Mutator<Immutable.Set<string>>) => {
        setSelectedCampaigns((selectedCampaigns) => mutator(selectedCampaigns));
    }, []);

    const toggleSelectedCampaign = useCallback(
        (campaignId: string, selected: boolean) => {
            updateSelectedCampaigns((selectedCampaigns) =>
                selected ? selectedCampaigns.add(campaignId) : selectedCampaigns.delete(campaignId),
            );
        },
        [updateSelectedCampaigns],
    );

    const updateSelectedAds = useCallback((mutator: Mutator<Immutable.Set<string>>) => {
        setSelectedAds((selectedAds) => mutator(selectedAds));
    }, []);

    const toggleSelectedAd = useCallback(
        (ad: CategorizedAd, selected: boolean) => {
            updateSelectedAds((selectedAds) =>
                selected ? selectedAds.add(ad.id) : selectedAds.delete(ad.id),
            );
        },
        [updateSelectedAds],
    );

    const onPrimaryTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPrimaryText(event.target.value);
    }, []);

    const onHeadlineChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setHeadline(event.target.value);
    }, []);

    const onDescriptionChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    }, []);

    const onUrlQueryStringChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setUrlQueryString(event.target.value);
    }, []);

    const grabPrimaryText = useCallback(() => {
        setPrimaryText(selectedPrimaryText);
    }, [selectedPrimaryText]);

    const grabHeadline = useCallback(() => {
        setHeadline(selectedHeadline);
    }, [selectedHeadline]);

    const grabDescription = useCallback(() => {
        setDescription(selectedDescription);
    }, [selectedDescription]);

    const grabUrlQueryString = useCallback(() => {
        setUrlQueryString(selectedUrlQueryString);
    }, [selectedUrlQueryString]);

    useEffect(() => {
        if (!campaigns.isEmpty()) {
            setSelectedCampaigns(campaigns.map((campaign) => campaign.id).toSet());
        }
    }, [campaigns]);

    useEffect(() => {
        if (Object.keys(adParameters).length === 0 && parameterValueSets.size > 0) {
            const filterParameters = filterParametersFromValueSets(parameterValueSets);
            setAdParameters(filterParameters);
        }
    }, [adParameters, parameterValueSets]);

    useEffect(() => {
        if (primaryAdCopy) {
            setSelectedPrimaryText((primaryText) => primaryText || primaryAdCopy.primaryText);
            setSelectedHeadline((headline) => headline || primaryAdCopy.headline);
            setSelectedDescription((description) => description || primaryAdCopy.description);
        }
    }, [primaryAdCopy]);

    useEffect(() => {
        if (primaryAd) {
            setSelectedUrlQueryString(
                (urlQueryString) => urlQueryString || (primaryAd.creative.url_tags ?? ""),
            );
        }
    }, [primaryAd]);

    const updateResult = useMemo(
        () =>
            newAdUpdateSchema.safeParse({
                name,
                scheduled_at: scheduledAt?.toISO(),
                status: draft ? "draft" : "pending",
                primary_text: primaryText,
                headline,
                description: adType === "standard" ? description : "",
                campaigns: selectedCampaigns.toArray(),
                ads: selectedAds.toArray(),
                ad_status: adStatus,
                ad_type: adType,
                ad_parameters: adType === "standard" ? adParameters : {},
                url_query_string: urlQueryString,
            }),
        [
            name,
            scheduledAt,
            draft,
            primaryText,
            headline,
            description,
            selectedCampaigns,
            selectedAds,
            adStatus,
            adType,
            adParameters,
            urlQueryString,
        ],
    );

    const updateErrors = useMemo(() => updateResult.error?.format(), [updateResult]);

    const valid = updateErrors === undefined;
    const busy = mutation.isPending;

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (updateResult.success) {
                await mutation.mutateAsync(updateResult.data);
            }
        },
        [updateResult, mutation],
    );

    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <Paper sx={CARD_STYLE}>
                        <pre>{JSON.stringify(updateErrors, null, 2)}</pre>
                    </Paper>
                </Grid> */}
                <Grid item xs={12}>
                    <Paper sx={{ ...CARD_STYLE, flexDirection: "row", alignItems: "center" }}>
                        <Box>
                            <PageTitle />
                        </Box>
                        <Tabs
                            sx={{ marginBottom: -1 }}
                            value={activeSection}
                            onChange={onSectionChange}
                        >
                            <Tab value="ads" label="Ads" />
                            <Tab value="details" label="Details" />
                        </Tabs>
                    </Paper>
                </Grid>
                {activeSection === "ads" && (
                    <>
                        <Grid item xs={12}>
                            <Paper sx={CARD_STYLE}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                    <Typography fontWeight="bold">Campaigns</Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            "& .MuiCheckbox-root": { padding: "4px" },
                                        }}
                                    >
                                        {campaigns.map((campaign) => (
                                            <FormControlLabel
                                                key={campaign.id}
                                                control={
                                                    <Checkbox
                                                        checked={selectedCampaigns.includes(
                                                            campaign.id,
                                                        )}
                                                        onChange={(event) => {
                                                            toggleSelectedCampaign(
                                                                campaign.id,
                                                                event.target.checked,
                                                            );
                                                        }}
                                                    />
                                                }
                                                label={campaign.name}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                                {campaignsQuery.isPending && <Loading />}
                                {campaignsQuery.error && (
                                    <ErrorMessage>{campaignsQuery.error.message}</ErrorMessage>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={CARD_STYLE}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    <Typography fontWeight="bold">Ads</Typography>
                                    <ToggleButtonGroup
                                        size="small"
                                        exclusive
                                        value={adStatus ?? ""}
                                        onChange={(event, value: AdStatus | "") => {
                                            setAdStatus(value || undefined);
                                        }}
                                    >
                                        <ToggleButton value="">All</ToggleButton>
                                        <ToggleButton value="ACTIVE" color="success">
                                            Active
                                        </ToggleButton>
                                        <ToggleButton value="PAUSED" color="warning">
                                            Paused
                                        </ToggleButton>
                                        <ToggleButton value="FAILED" color="error">
                                            Failed
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <ToggleButtonGroup
                                        size="small"
                                        exclusive
                                        value={adType}
                                        onChange={(event, value: AdType) => {
                                            setAdType(value);
                                        }}
                                    >
                                        <ToggleButton value="standard" color="primary">
                                            Standard
                                        </ToggleButton>
                                        <ToggleButton value="dynamic" color="secondary">
                                            Advantage+
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                <MetaAdTable
                                    adType={adType}
                                    adParameters={adParameters}
                                    setAdParameters={setAdParameters}
                                    parameterValueSets={parameterValueSets}
                                    campaignsById={campaignsById}
                                    filteredAds={filteredAds}
                                    selectedAds={selectedAds}
                                    onToggleAd={toggleSelectedAd}
                                    onCopyAdName={onCopyAdName}
                                />
                                {(campaignsQuery.isPending || adsQuery.isPending) && <Loading />}
                                {!campaignsQuery.isPending &&
                                    !adsQuery.isPending &&
                                    filteredAds.isEmpty() && (
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography>No ads found</Typography>
                                        </Box>
                                    )}
                                {adsQuery.error && (
                                    <ErrorMessage>{adsQuery.error.message}</ErrorMessage>
                                )}
                            </Paper>
                        </Grid>
                    </>
                )}
                {activeSection === "details" && (
                    <>
                        <Grid item xs={12}>
                            <Paper sx={CARD_STYLE}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 1,
                                        maxWidth: "800px",
                                    }}
                                >
                                    <Typography fontWeight="bold">Selected Ads</Typography>
                                    {filteredSelectedAds.size > 0 ? (
                                        <MetaAdTable
                                            adType={adType}
                                            adParameters={adParameters}
                                            parameterValueSets={parameterValueSets}
                                            campaignsById={campaignsById}
                                            filteredAds={filteredSelectedAds}
                                        />
                                    ) : (
                                        <Typography>No ads selected</Typography>
                                    )}
                                </Box>
                                {campaignsQuery.isPending && <Loading />}
                                {campaignsQuery.error && (
                                    <ErrorMessage>{campaignsQuery.error.message}</ErrorMessage>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper sx={CARD_STYLE}>
                                <Typography fontWeight="bold">Existing Creative</Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel id="primary-text-select-label">
                                            Primary Text
                                        </InputLabel>
                                        <Select
                                            label="Primary Text"
                                            labelId="primary-text-select-label"
                                            value={selectedPrimaryText}
                                            onChange={(event) => {
                                                setSelectedPrimaryText(event.target.value);
                                            }}
                                            disabled={primaryTextValues.isEmpty()}
                                            sx={{
                                                "& .MuiSelect-select": {
                                                    whiteSpace: "normal !important",
                                                },
                                            }}
                                        >
                                            {primaryTextValues.map((value) => (
                                                <MenuItem
                                                    key={value}
                                                    value={value}
                                                    sx={{ maxWidth: "600px", whiteSpace: "normal" }}
                                                >
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <IconButton
                                        color="primary"
                                        edge="end"
                                        onClick={grabPrimaryText}
                                        disabled={!primaryAdCopy?.primaryText}
                                    >
                                        <ArrowCircleRightIcon />
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel id="headline-select-label">Headline</InputLabel>
                                        <Select
                                            label="Headline"
                                            labelId="headline-select-label"
                                            value={selectedHeadline}
                                            onChange={(event) => {
                                                setSelectedHeadline(event.target.value);
                                            }}
                                            disabled={headlineValues.isEmpty()}
                                        >
                                            {headlineValues.map((value) => (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <IconButton
                                        color="primary"
                                        edge="end"
                                        onClick={grabHeadline}
                                        disabled={!primaryAdCopy?.headline}
                                    >
                                        <ArrowCircleRightIcon />
                                    </IconButton>
                                </Box>
                                {adType === "standard" && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FormControl sx={{ flex: 1 }}>
                                            <InputLabel id="description-select-label">
                                                Description
                                            </InputLabel>
                                            <Select
                                                label="Description"
                                                labelId="description-select-label"
                                                value={selectedDescription}
                                                onChange={(event) => {
                                                    setSelectedDescription(event.target.value);
                                                }}
                                                disabled={descriptionValues.isEmpty()}
                                            >
                                                {descriptionValues.map((value) => (
                                                    <MenuItem key={value} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <IconButton
                                            color="primary"
                                            edge="end"
                                            onClick={grabDescription}
                                            disabled={!primaryAdCopy?.description}
                                        >
                                            <ArrowCircleRightIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel id="url-query-string-select-label">
                                            URL Parameters
                                        </InputLabel>
                                        <Select
                                            label="URL Parameters"
                                            labelId="url-query-string-select-label"
                                            value={selectedUrlQueryString}
                                            onChange={(event) => {
                                                setSelectedUrlQueryString(event.target.value);
                                            }}
                                            disabled={urlQueryStringValues.isEmpty()}
                                        >
                                            {urlQueryStringValues.map((value) => (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <IconButton
                                        color="primary"
                                        edge="end"
                                        onClick={grabUrlQueryString}
                                        disabled={!primaryAd?.creative.url_tags}
                                    >
                                        <ArrowCircleRightIcon />
                                    </IconButton>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper sx={CARD_STYLE}>
                                <Typography fontWeight="bold">New Creative</Typography>
                                <TextField
                                    multiline={adType === "standard"}
                                    rows={adType === "standard" ? 3 : 1}
                                    label="Primary Text"
                                    value={primaryText}
                                    onChange={onPrimaryTextChange}
                                />
                                <TextField
                                    label="Headline"
                                    value={headline}
                                    onChange={onHeadlineChange}
                                />
                                {adType === "standard" && (
                                    <TextField
                                        label="Description"
                                        value={description}
                                        onChange={onDescriptionChange}
                                    />
                                )}
                                <TextField
                                    label="URL Parameters"
                                    value={urlQueryString}
                                    onChange={onUrlQueryStringChange}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper component="form" onSubmit={onSubmit} sx={CARD_STYLE}>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <FormControl sx={{ minWidth: "350px" }}>
                                        <TextField
                                            label="Name"
                                            value={name}
                                            onChange={(event) => {
                                                setName(event.target.value);
                                            }}
                                        />
                                    </FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={draft}
                                                onChange={(event) => {
                                                    setDraft(event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Draft"
                                    />
                                    <FormControl sx={{ minWidth: "260px" }}>
                                        <DateTimePicker
                                            label="Scheduled at"
                                            value={scheduledAt}
                                            onChange={setScheduledAt}
                                            slotProps={{
                                                textField: {
                                                    // @ts-expect-error: typings don't know about `clearable` (?)
                                                    clearable: true,
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
                                    {mutation.error && (
                                        <ErrorMessage>{mutation.error.message}</ErrorMessage>
                                    )}
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={!valid || busy}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Paper sx={CARD_STYLE}>
                                <pre>{JSON.stringify(filteredAds, null, 2)}</pre>
                            </Paper>
                        </Grid> */}
                    </>
                )}
            </Grid>
        </Container>
    );
}
