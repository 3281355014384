import { Container } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";

import { LookerStudioReport } from "../../../components";

const REPORT_ID = "1b2a5296-e35c-43f0-8bfa-f499a4113f90";
const PAGE = "p_6u24a3jgjd";

export const Route = createFileRoute("/_auth/amazon/reports")({
    component: Report,
    beforeLoad: () => ({
        getTitle: () => "Reports",
    }),
});

function Report() {
    return (
        // TODO: extract style and use on other pages
        <Container maxWidth={false} sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <LookerStudioReport reportId={REPORT_ID} page={PAGE} />
        </Container>
    );
}
