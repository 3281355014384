import { createFileRoute } from "@tanstack/react-router";

import { TaskResultsTablePage } from "../../../components";

export const Route = createFileRoute("/_auth/amazon/campaigns")({
    component: Campaigns,
    beforeLoad: () => ({
        getTitle: () => "Campaign History",
    }),
});

const COLUMNS = {
    // "Campaign ID": ["campaign_id"],
    "Campaign Name": ["campaign_name"],
    // "Ad Group ID": ["ad_group_id"],
    "Ad Group Name": ["ad_group_name"],
    "Ad ID": ["ad_id"],
    // "Ad Name": ["ad_name"],
    // SKUs: ["sku", "skus"],
    Changed: ["changed"],
    From: ["from"],
    To: ["to"],
    // Details: ["details"],
};

function Campaigns() {
    return <TaskResultsTablePage name="amazon.bulk_update" columns={COLUMNS} />;
}
