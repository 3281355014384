import { z } from "zod";
import configJson from "../../config.json";
import { profileSchema } from "./amazon/types";

const amazonAdsConfigSchema = z.object({
    profiles: z.array(profileSchema),
});

const metaConfigSchema = z.object({
    failed_ad_ids: z.array(z.string()),
});

const configSchema = z.object({
    admin_emails: z.array(z.string()),
    amazon_ads: amazonAdsConfigSchema,
    meta: metaConfigSchema,
});

export const config = configSchema.parse(configJson);
