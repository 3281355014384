import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    Paper,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { useAlerts } from "../../../alerts";
import type { SelectedProductDynamic } from "../../../amazon/types";
import { useApiClient } from "../../../api";
import {
    type SponsoredProductsBidAdjustments,
    type SponsoredProductsBidPlacement,
    sponsoredProductsCreativeUpdateSchema,
} from "../../../api/types";
import {
    CampaignList,
    ErrorMessage,
    Loading,
    PageTitle,
    ProductImage,
    ProductSelect,
    SalesList,
    UpdateConfirmationModal,
} from "../../../components";
import { ACTIVE_CARD_STYLE, CARD_STYLE } from "../../../styles";
import { pluralize } from "../../../utils";

const searchSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const Route = createFileRoute("/_auth/amazon/sponsored-products-creative")({
    component: SponsoredProductsCreative,
    validateSearch: (search) => searchSchema.parse(search),
    beforeLoad: () => ({
        getTitle: () => "Sponsored Products (Creative)",
    }),
    onError: (error: { routerCode: string }) => {
        if (error.routerCode === "VALIDATE_SEARCH") {
            throw redirect({
                to: "/amazon/bulk-creative-update",
                search: { campaignType: "sponsored-products" },
            });
        }
    },
});

function SponsoredProductsCreative() {
    const { profile_id, campaign_ids } = Route.useSearch();
    const navigate = Route.useNavigate();

    const { addAlert } = useAlerts();

    const [product, setProduct] = useState<SelectedProductDynamic | null>(null);
    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const apiClient = useApiClient();
    const queryClient = useQueryClient();

    const headlinesQuery = useQuery({
        queryKey: ["headlines", profile_id],
        queryFn: () => apiClient.getHeadlines({ queries: { profile_id } }),
    });

    const productsQuery = useQuery({
        queryKey: ["sponsoredProductsCreative", profile_id, campaign_ids],
        queryFn: () =>
            apiClient.getSponsoredProductsCreative({
                profile_id,
                campaign_ids,
            }),
    });

    const initialData = productsQuery.data;
    const sku = product?.state === "loaded" ? product.sku : null;
    const asin = product?.state === "loaded" ? product.asin : null;
    const style = product?.style;
    const color = product?.color;
    const styles = useMemo(() => (style !== undefined ? [style] : []), [style]);

    const productVariantsQuery = useQuery({
        enabled: style !== undefined,
        queryKey: ["productVariants", style],
        queryFn: () => apiClient.getProductVariants({ params: { style: style! } }),
    });

    const mutation = useMutation({
        mutationFn: apiClient.updateSponsoredProductsCreative,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["sponsoredProductsCreative", profile_id, campaign_ids],
            });

            const campaignCount = campaign_ids.length;
            const updatesWord = pluralize(campaignCount, "update");
            addAlert({ type: "success", message: `${campaignCount} ${updatesWord} queued` });
            await navigate({
                to: "/amazon/bulk-creative-update",
                search: { campaignType: "sponsored-products" },
            });
        },
    });

    const [editingProduct, setEditingProduct] = useState(true);

    const handleEditingProductChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingProduct(event.target.checked);
    }, []);

    const updateResult = useMemo(() => {
        return sponsoredProductsCreativeUpdateSchema.safeParse({
            profile_id,
            campaign_ids,
            asin: editingProduct ? asin : null,
        });
    }, [profile_id, campaign_ids, editingProduct, asin]);

    const updateErrors = useMemo(() => {
        return updateResult.error?.format();
    }, [updateResult]);

    const valid = updateErrors === undefined;
    const busy = headlinesQuery.isLoading || productsQuery.isLoading || mutation.isPending;

    useEffect(() => {
        if (initialData) {
            setProduct({
                state: "loaded",
                ...initialData.product,
            });
        }
    }, [initialData]);

    const showConfirmation = useCallback(() => {
        setConfirmationVisible(true);
    }, []);

    const hideConfirmation = useCallback(() => {
        setConfirmationVisible(false);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (updateResult.success) {
            await mutation.mutateAsync(updateResult.data);
        }
    }, [updateResult, mutation]);

    const productGridItems = useMemo(() => {
        if (productVariantsQuery.isLoading) {
            return (
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 4,
                    }}
                >
                    <CircularProgress size={60} />
                </Grid>
            );
        }

        return productVariantsQuery.data?.map((product, index) => {
            return (
                // biome-ignore lint/suspicious/noArrayIndexKey:
                <Grid item xs={3} key={index}>
                    <Paper sx={product.color === color ? ACTIVE_CARD_STYLE : CARD_STYLE}>
                        <Typography fontWeight={500}>{product.color}</Typography>
                        <ProductImage asin={product.asin} />
                    </Paper>
                </Grid>
            );
        });
    }, [productVariantsQuery, color]);

    return (
        <Container maxWidth="lg">
            <UpdateConfirmationModal
                open={confirmationVisible}
                busy={busy}
                onClose={hideConfirmation}
                onConfirm={handleSubmit}
            >
                <List>
                    {editingProduct && (
                        <>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>SKU:</Typography>
                                &nbsp;
                                {sku}
                            </ListItem>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>ASIN:</Typography>
                                &nbsp;
                                {asin}
                            </ListItem>
                        </>
                    )}
                </List>
            </UpdateConfirmationModal>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <PageTitle />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Sales by Style/Color</Typography>
                        <SalesList profileId={profile_id} styles={styles} />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Selected Campaigns</Typography>
                        <CampaignList
                            profileId={profile_id}
                            campaignIds={campaign_ids}
                            campaignType="sponsored-products"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={editingProduct}
                                    onChange={handleEditingProductChange}
                                />
                            }
                            label={<Typography fontWeight={500}>Current ASIN</Typography>}
                        />
                        {product ? (
                            <ProductSelect id="product" product={product} onChange={setProduct} />
                        ) : (
                            <CircularProgress />
                        )}
                        <Box sx={{ textAlign: "center" }}>
                            {product?.state === "loaded" ? (
                                <>
                                    <Typography>
                                        <Typography component="span" fontWeight={500}>
                                            SKU:
                                        </Typography>{" "}
                                        {product.sku}
                                    </Typography>
                                    <Typography>
                                        <Typography component="span" fontWeight={500}>
                                            ASIN:
                                        </Typography>{" "}
                                        {product.asin}
                                    </Typography>
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                        </Box>
                    </Paper>
                </Grid>
                {productGridItems}
                {productsQuery.isLoading && (
                    <Grid item xs={12}>
                        <Loading />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {mutation.error && <ErrorMessage>{mutation.error.message}</ErrorMessage>}
                    <Button
                        variant="contained"
                        onClick={showConfirmation}
                        disabled={!valid || busy}
                    >
                        Submit Ad
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}
