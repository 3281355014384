import { createContext, useContext } from "react";

import { type AuthenticatedUser, type Credentials, authenticatedUserSchema } from "./types";

const STORAGE_KEY_USER = "glamorise_tools.auth.user";

interface AuthLoading {
    state: "loading";
}

interface AuthLoggedOut {
    state: "loggedOut";
}

interface AuthLoggedIn {
    state: "loggedIn";
    user: AuthenticatedUser;
    remember: boolean;
}

export interface LogOutOptions {
    callApi?: boolean;
}

export type Auth = AuthLoading | AuthLoggedOut | AuthLoggedIn;

export type UseAuthHandle =
    | AuthLoading
    | (AuthLoggedOut & {
          logIn: (user: AuthenticatedUser, remember: boolean) => Promise<void>;
      })
    | (AuthLoggedIn & {
          logOut: (options?: LogOutOptions) => Promise<void>;
          refresh: (credentials: Credentials) => Promise<void>;
      });

export const AuthContext = createContext<UseAuthHandle | null>(null);

export async function getStoredUser(storage: LocalForage): Promise<AuthenticatedUser | null> {
    const serializedUser = await storage.getItem(STORAGE_KEY_USER);
    if (!serializedUser) {
        return null;
    }

    try {
        return authenticatedUserSchema.parse(serializedUser);
    } catch (error) {
        await setStoredUser(storage, null);
        return null;
    }
}

export async function setStoredUser(storage: LocalForage, user: AuthenticatedUser | null) {
    if (user) {
        await storage.setItem(STORAGE_KEY_USER, user);
    } else {
        await storage.removeItem(STORAGE_KEY_USER);
    }
}

export function useAuth(): UseAuthHandle {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
}
