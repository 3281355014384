import {
    Add as AddIcon,
    Delete as DeleteIcon,
    ExpandMore as ExpandMoreIcon,
    FileCopy as FileCopyIcon,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    Container,
    Grid,
    IconButton,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import { DateTime } from "luxon";
import { type SyntheticEvent, useCallback, useMemo, useState } from "react";

import { useApiClient } from "../../../../api";
import { ExpandMoreIconButton, Loading, PageTitle } from "../../../../components";
import { currencySymbolForRow } from "../../../../shopify/pricing";
import {
    ENVIRONMENT_LABELS,
    type PricingUpdate,
    type PricingUpdateRow,
    type PricingUpdateStatus,
} from "../../../../shopify/types";
import { CARD_STYLE } from "../../../../styles";
import { formatDateTime } from "../../../../utils";
export const Route = createFileRoute("/_auth/shopify/pricing/")({
    component: PricingIndex,
    beforeLoad: () => ({
        getTitle: undefined,
    }),
});

function updateIsEditable(update: PricingUpdate) {
    return update.status === "draft" || update.status === "pending";
}

function PricingIndex() {
    const apiClient = useApiClient();
    const queryClient = useQueryClient();

    const [statusFilter, setStatusFilter] = useState<PricingUpdateStatus>("draft");
    const [expandedDraftId, setExpandedDraftId] = useState<number | null>(null);

    const themesQuery = useQuery({
        queryKey: ["shopifyThemes"],
        queryFn: () => apiClient.shopifyGetThemes(),
    });
    const themes = useMemo(() => themesQuery.data ?? [], [themesQuery.data]);

    const updatesQuery = useQuery({
        queryKey: ["pricing", statusFilter],
        queryFn: () =>
            apiClient.shopifyGetPricingUpdates({ queries: { statuses: [statusFilter] } }),
    });
    const drafts = useMemo(() => updatesQuery.data ?? [], [updatesQuery.data]);

    const getThemeName = useCallback(
        (themeId: string) => {
            const theme = themes.find((theme) => theme.id === themeId);
            return theme?.name;
        },
        [themes],
    );

    const deleteMutation = useMutation({
        mutationFn: (updateId: number) =>
            apiClient.shopifyDeletePricingUpdate(undefined, { params: { updateId } }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["pricing"] });
        },
    });

    const duplicateMutation = useMutation({
        mutationFn: (updateId: number) =>
            apiClient.shopifyDuplicatePricingUpdate(undefined, { params: { updateId } }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["pricing"] });
        },
    });

    const onStatusFilterChange = useCallback(
        (event: SyntheticEvent, status: PricingUpdateStatus) => {
            setStatusFilter(status);
        },
        [],
    );

    const onDelete = useCallback(
        (updateId: number) => {
            const message = "Are you sure you want to delete this draft?";
            const confirmed = window.confirm(message);
            if (confirmed) {
                deleteMutation.mutate(updateId);
            }
        },
        [deleteMutation],
    );

    const onDuplicate = useCallback(
        async (updateId: number) => {
            await duplicateMutation.mutateAsync(updateId);
            await queryClient.invalidateQueries({ queryKey: ["pricing"] });
            setStatusFilter("draft");
        },
        [duplicateMutation, queryClient],
    );

    const gridItems = useMemo(
        () =>
            drafts.map((update) => (
                <Grid item xs={12} key={update.id}>
                    <Card>
                        <CardHeader
                            title={
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    {update.name || <em>Untitled</em>}
                                    {update.status === "draft" && <Chip label="Draft" />}
                                    {update.status === "pending" && (
                                        <Chip label="Pending" color="info" />
                                    )}
                                    {update.status === "succeeded" && (
                                        <Link
                                            to="/shopify/tasks"
                                            search={{ id: update.task_id ?? undefined }}
                                        >
                                            <Chip label="Succeeded" color="success" />
                                        </Link>
                                    )}
                                    {update.status === "failed" && (
                                        <Link
                                            to="/shopify/tasks"
                                            search={{ id: update.task_id ?? undefined }}
                                        >
                                            <Chip label="Failed" color="error" />
                                        </Link>
                                    )}
                                </Box>
                            }
                            subheader={
                                updateIsEditable(update) ? (
                                    update.scheduled_at && (
                                        <span>
                                            Scheduled for{" "}
                                            {formatDateTime(DateTime.fromISO(update.scheduled_at))}
                                        </span>
                                    )
                                ) : (
                                    <span>
                                        Submitted at{" "}
                                        {formatDateTime(DateTime.fromISO(update.updated_at!))}
                                    </span>
                                )
                            }
                            action={
                                updateIsEditable(update) ? (
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            onDelete(update.id!);
                                        }}
                                        disabled={deleteMutation.isPending}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        // color="error"
                                        onClick={() => {
                                            onDuplicate(update.id!);
                                        }}
                                        disabled={duplicateMutation.isPending}
                                    >
                                        <FileCopyIcon />
                                    </IconButton>
                                )
                            }
                        />
                        <CardContent sx={{ paddingTop: 0, paddingBottom: 1 }}>
                            <Typography>
                                <strong>Theme:</strong>{" "}
                                {update.theme_id ? (
                                    getThemeName(update.theme_id)
                                ) : (
                                    <em>No change</em>
                                )}
                            </Typography>
                            <Typography>
                                <strong>Environments:</strong>{" "}
                                {update.environments
                                    .map((environment) => ENVIRONMENT_LABELS[environment])
                                    .join(", ")}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            {updateIsEditable(update) && (
                                <Button
                                    variant="text"
                                    component={Link}
                                    to={`/shopify/pricing/${update.id}/edit`}
                                >
                                    Edit
                                </Button>
                            )}
                            <ExpandMoreIconButton
                                expand={expandedDraftId === update.id}
                                onClick={() => {
                                    setExpandedDraftId((id) =>
                                        id === update.id ? null : update.id,
                                    );
                                }}
                            >
                                <ExpandMoreIcon />
                            </ExpandMoreIconButton>
                        </CardActions>
                        <Collapse
                            in={expandedDraftId === update.id}
                            timeout="auto"
                            unmountOnExit
                            sx={{
                                "& .MuiCardContent-root:last-child": {
                                    paddingBottom: 2,
                                },
                            }}
                        >
                            <CardContent>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Country</TableCell>
                                            <TableCell>Style</TableCell>
                                            <TableCell>Color</TableCell>
                                            <TableCell align="right">Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {update.rows.map((row, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey:
                                            <Row key={index} {...row} />
                                        ))}
                                    </TableBody>
                                    {update.persistent_rows && (
                                        <TableBody>
                                            {update.persistent_rows.map((row, index) => (
                                                // biome-ignore lint/suspicious/noArrayIndexKey:
                                                <Row key={index} {...row} />
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Grid>
            )),
        [
            drafts,
            expandedDraftId,
            getThemeName,
            onDelete,
            deleteMutation,
            onDuplicate,
            duplicateMutation,
        ],
    );

    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <Paper sx={{ ...CARD_STYLE, flexDirection: "row", alignItems: "center" }}>
                        <pre>{JSON.stringify(updatesQuery.error, null, 2)}</pre>
                    </Paper>
                </Grid> */}
                <Grid item xs={12}>
                    <Paper sx={{ ...CARD_STYLE, flexDirection: "row", alignItems: "center" }}>
                        <Box>
                            <PageTitle />
                        </Box>
                        <Tabs
                            sx={{ marginBottom: -1 }}
                            value={statusFilter}
                            onChange={onStatusFilterChange}
                        >
                            <Tab value="draft" label="Drafts" />
                            <Tab value="pending" label="Pending" />
                            <Tab value="succeeded" label="Succeeded" />
                            <Tab value="failed" label="Failed" />
                        </Tabs>
                        <Box sx={{ flex: 1 }} />
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            component={Link}
                            to="/shopify/pricing/create"
                        >
                            Create Pricing
                        </Button>
                        {/* <pre>{JSON.stringify(updatesQuery, null, 2)}</pre> */}
                    </Paper>
                </Grid>
                {gridItems}
                {updatesQuery.isLoading && (
                    <Grid item xs={12}>
                        <Loading />
                    </Grid>
                )}
                {!updatesQuery.isLoading && updatesQuery.data?.length === 0 && (
                    <Grid item xs={12}>
                        <Paper
                            sx={{ ...CARD_STYLE, alignItems: "center", justifyContent: "center" }}
                        >
                            <Typography>No updates found</Typography>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

function Row(row: PricingUpdateRow) {
    const { countries, styles, colors, price } = row;
    return (
        <TableRow>
            <TableCell>{countries.join(", ")}</TableCell>
            <TableCell>{styles.join(", ")}</TableCell>
            <TableCell>{colors.join(", ")}</TableCell>
            <TableCell align="right">
                {price.type === "msrp" && "MSRP"}
                {price.type === "discount" && `${price.percentage}%`}
                {price.type === "fixed" && `${currencySymbolForRow(row)}${price.price}`}
            </TableCell>
        </TableRow>
    );
}
