import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Link as RouterLink, createFileRoute, useRouter } from "@tanstack/react-router";
import { isAxiosError } from "axios";
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useApiClient } from "../../api";
import { useAuth } from "../../auth";
import { ErrorMessage, PageTitle } from "../../components";

const searchSchema = z.object({
    redirect: z.string().optional(),
});

const formSchema = z.object({
    email: z.string().email(),
    password: z.string().min(8),
});

type FormValues = z.infer<typeof formSchema>;

export const Route = createFileRoute("/_no-auth/log-in")({
    component: LogIn,
    beforeLoad: () => ({
        getTitle: () => "Log In",
    }),
    validateSearch: (search) => searchSchema.parse(search),
});

function LogIn() {
    const router = useRouter();
    const { redirect } = Route.useSearch();

    const auth = useAuth();
    const apiClient = useApiClient();

    const [showPassword, setShowPassword] = useState(false);
    const [remember, setRemember] = useState(false);

    const toggleShowPassword = useCallback(() => {
        setShowPassword((value) => !value);
    }, []);

    const handleRememberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setRemember(event.target.checked);
    }, []);

    const mutation = useMutation({
        mutationFn: apiClient.logIn,
        onSuccess: async ({ user, access_token, refresh_token }) => {
            if (auth.state !== "loggedOut") {
                return;
            }

            const authenticatedUser = { ...user, access_token, refresh_token };
            await auth.logIn(authenticatedUser, remember);
            await router.invalidate();
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(formSchema),
    });

    const onSubmit = useCallback(
        async ({ email, password }: FormValues) => {
            const formData = new FormData();
            formData.set("username", email);
            formData.set("password", password);
            await mutation.mutateAsync(formData);
        },
        [mutation],
    );

    const busy = mutation.isPending;

    useEffect(() => {
        if (auth.state === "loggedIn") {
            const path = redirect ?? "/";
            router.history.push(path);
        }
    }, [auth, redirect, router]);

    const errorMessage = useMemo(() => {
        const { error } = mutation;
        if (error === null) {
            return null;
        }

        if (isAxiosError(error)) {
            const status = error.response?.status;

            if (status === 401) {
                return "Invalid password";
            }

            if (status === 404) {
                return "User not found";
            }
        }

        return error.message;
    }, [mutation]);

    return (
        <Paper sx={{ padding: 2 }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <PageTitle />
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    sx={{ marginTop: 2 }}
                >
                    <FormControl fullWidth>
                        <Controller
                            name="email"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email Address"
                                    autoComplete="email"
                                    required
                                    autoFocus
                                    disabled={mutation.isPending}
                                    error={errors.email !== undefined}
                                    helperText={errors.email?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                        <Controller
                            name="password"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    required
                                    disabled={mutation.isPending}
                                    error={errors.password !== undefined}
                                    helperText={errors.password?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowPassword}
                                                    onMouseDown={toggleShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControlLabel
                        label="Remember me"
                        control={
                            <Checkbox
                                value="remember"
                                checked={remember}
                                onChange={handleRememberChange}
                                disabled={mutation.isPending}
                            />
                        }
                        sx={{ marginTop: 2 }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={busy}
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        startIcon={
                            mutation.isPending && <CircularProgress color="inherit" size={24} />
                        }
                    >
                        Log In
                    </Button>
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    <Grid container>
                        <Grid item xs>
                            <Link component={RouterLink} to="/forgot-password">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    );
}
