import { makeApi } from "@zodios/core";
import { z } from "zod";

import { credentialsSchema, loginResponseSchema } from "../types";

export const api = makeApi([
    {
        method: "post",
        path: "/log-in",
        alias: "logIn",
        description: "Log in",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.instanceof(FormData),
            },
        ],
        response: loginResponseSchema,
    },
    {
        method: "post",
        path: "/refresh",
        alias: "refresh",
        description: "Refresh credentials",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    refresh_token: z.string(),
                }),
            },
        ],
        response: credentialsSchema,
    },
    {
        method: "post",
        path: "/log-out",
        alias: "logOut",
        description: "Log out",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    refresh_token: z.string(),
                }),
            },
        ],
        response: z.unknown(),
    },
    {
        method: "post",
        path: "/request-password-reset",
        alias: "requestPasswordReset",
        description: "Request password reset",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    email: z.string(),
                }),
            },
        ],
        response: z.unknown(),
    },
    {
        method: "post",
        path: "/reset-password",
        alias: "resetPassword",
        description: "Reset password",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    reset_token: z.string(),
                    new_password: z.string(),
                }),
            },
        ],
        response: z.unknown(),
    },
]);
