import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_admin")({
    component: Auth,
    beforeLoad: ({ context }) => {
        if (context.auth.state === "loggedIn" && !context.auth.user.is_admin) {
            throw redirect({
                to: "/",
            });
        }
    },
});

function Auth() {
    return <Outlet />;
}
