import {
    Clear as ClearIcon,
    Download as DownloadIcon,
    Launch as LaunchIcon,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    type SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import { Link as RouterLink } from "@tanstack/react-router";
import * as Immutable from "immutable";
import { DateTime } from "luxon";
import { type ChangeEvent, useCallback, useMemo, useState } from "react";

import {
    CAMPAIGN_TYPE_CREATIVE_UPDATE_ROUTES,
    getCampaignTypeFromCampaignName,
} from "../amazon/campaign";
import { COUNTRY_CODES, type CampaignType, type CountryCode } from "../amazon/types";
import { useApiClient } from "../api";
import { CARD_STYLE } from "../styles";
import { createTaskIndex } from "../tasks";
import type { Task } from "../types";
import { formatDateTime } from "../utils";
import { Loading } from "./Loading";

// BigQuery JSON objects don't preserve key order :'(
const SORTED_COLUMN_KEYS: string[] = [
    "campaign_id",
    "campaign_name",
    "ad_group_id",
    "ad_group_name",
    "ad_group",
    "ad_id",
    "ad_name",
    "changed",
    "field",
    "from",
    "to",
    "sku",
    "skus",
    "country",
    "product",
    "style",
    "color",
    "price",
    "details",
];

const COLUMN_SORT_INDEX: Record<string, number> = Object.fromEntries(
    SORTED_COLUMN_KEYS.map((column, index) => [column, index]),
);

export interface TaskListPageProps {
    name: string;
    pinnedTaskDisplayNames?: string[];
    pinnedLabel?: string;
    includeCountryFilter?: boolean;
    selectedTaskId?: string;
    onSelectedTaskIdChange: (taskId: string | undefined) => void;
    enableSelection?: boolean;
}

export function TaskListPage({
    name,
    pinnedTaskDisplayNames,
    pinnedLabel,
    selectedTaskId,
    onSelectedTaskIdChange,
    includeCountryFilter = false,
    enableSelection = false,
}: TaskListPageProps) {
    const apiClient = useApiClient();
    const tasksQuery = useQuery({
        queryKey: ["tasks", name],
        queryFn: () => apiClient.getTasks({ queries: { name } }),
    });

    const [countryCode, setCountryCode] = useState<CountryCode | null>(null);
    const [afterDate, setAfterDate] = useState<DateTime | null>(null);
    const [searchText, setSearchText] = useState("");
    const [includeNoResults, setIncludeNoResults] = useState(true);

    const tasks = useMemo(() => Immutable.List(tasksQuery.data ?? []), [tasksQuery.data]);

    const _selectedTask: Task | undefined = useMemo(
        () => (selectedTaskId ? tasks.find((task) => task.id === selectedTaskId) : undefined),
        [tasks, selectedTaskId],
    );

    const taskQuery = useQuery({
        enabled: _selectedTask !== undefined && taskHasResults(_selectedTask),
        queryKey: ["task", selectedTaskId],
        queryFn: () => apiClient.getTask({ params: { id: selectedTaskId! } }),
    });

    const selectedTask = taskQuery.data ?? _selectedTask;

    const [selectedSuccessIndices, setSelectedSuccessIndices] = useState<Immutable.Set<number>>(
        Immutable.Set(),
    );
    const [selectedFailureIndices, setSelectedFailureIndices] = useState<Immutable.Set<number>>(
        Immutable.Set(),
    );

    const taskIndex = useMemo(() => createTaskIndex(tasks), [tasks]);
    const matchingTaskIds = useMemo(
        () =>
            searchText
                ? Immutable.Set(taskIndex.search(searchText).map((task) => task.id))
                : Immutable.Set(),
        [taskIndex, searchText],
    );
    const filteredTasks = useMemo(
        () =>
            tasks
                .filter((task) => countryCode === null || task.display_name.startsWith(countryCode))
                .filter(
                    (task) => afterDate === null || DateTime.fromISO(task.started_at) >= afterDate,
                )
                .filter((task) => searchText === "" || matchingTaskIds.includes(task.id))
                .filter((task) => includeNoResults || taskHasResults(task)),
        [tasks, countryCode, afterDate, searchText, matchingTaskIds, includeNoResults],
    );
    const filtered = filteredTasks.size < tasks.size;

    const pinnedTasks = useMemo(
        () =>
            Immutable.OrderedSet(
                pinnedTaskDisplayNames?.map((name) =>
                    tasks.find((task) => task.display_name === name),
                ),
            ),
        [tasks, pinnedTaskDisplayNames],
    );
    const unpinnedTasks = useMemo(
        () =>
            pinnedTasks.size > 0
                ? tasks
                      .filter((task) => !pinnedTasks.includes(task))
                      .filter((task) => includeNoResults || taskHasResults(task))
                : tasks,
        [tasks, pinnedTasks, includeNoResults],
    );

    const _selectedRows = useMemo(() => {
        if (!selectedTask) {
            return [];
        }

        const selectedSuccesses =
            selectedTask.successes?.filter((_, index) => selectedSuccessIndices.has(index)) ?? [];
        const selectedFailures =
            selectedTask.failures?.filter((_, index) => selectedFailureIndices.has(index)) ?? [];
        return [...selectedSuccesses, ...selectedFailures];
    }, [selectedTask, selectedSuccessIndices, selectedFailureIndices]);
    const selectedCampaignType = useMemo<CampaignType | undefined>(
        () => getCampaignTypeFromCampaignName(_selectedRows[0]?.campaign_name ?? ""),
        [_selectedRows],
    );
    const selectedRows = useMemo(
        () =>
            _selectedRows.filter(
                (row) =>
                    getCampaignTypeFromCampaignName(row.campaign_name ?? "") ===
                    selectedCampaignType,
            ),
        [_selectedRows, selectedCampaignType],
    );

    const selectedProfileId = useMemo(() => selectedRows[0]?.profile_id, [selectedRows]);
    const selectedCampaignIds = useMemo(
        () => selectedRows.map((row) => row.campaign_id),
        [selectedRows],
    );

    const startedAtString = useMemo(
        () =>
            selectedTask?.started_at
                ? formatDateTime(DateTime.fromISO(selectedTask.started_at))
                : undefined,
        [selectedTask],
    );
    const finishedAtString = useMemo(
        () =>
            selectedTask?.finished_at
                ? formatDateTime(DateTime.fromISO(selectedTask.finished_at))
                : undefined,
        [selectedTask],
    );

    const renderTaskListItem = useCallback(
        (task: Task | undefined) =>
            task ? (
                <TaskListItem
                    key={task.id}
                    task={task}
                    selected={task.id === selectedTaskId}
                    onClick={() => {
                        onSelectedTaskIdChange(task.id);
                    }}
                />
            ) : null,
        [selectedTaskId, onSelectedTaskIdChange],
    );

    const pinnedTaskListItems = useMemo(
        () => pinnedTasks.map((task) => renderTaskListItem(task)),
        [pinnedTasks, renderTaskListItem],
    );
    const unpinnedTaskListItems = useMemo(
        () => unpinnedTasks.map((task) => renderTaskListItem(task)),
        [unpinnedTasks, renderTaskListItem],
    );
    const filteredTaskListItems = useMemo(
        () => filteredTasks.map((task) => renderTaskListItem(task)),
        [filteredTasks, renderTaskListItem],
    );

    const handleCountryCodeChange = useCallback((event: SelectChangeEvent) => {
        setCountryCode(event.target.value as CountryCode);
    }, []);

    const handleAfterDateChange = useCallback(
        (date: DateTime | null) => {
            setAfterDate(date);
            onSelectedTaskIdChange(undefined);
        },
        [onSelectedTaskIdChange],
    );

    const handleSearchTextChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setSearchText(event.target.value);
            onSelectedTaskIdChange(undefined);
        },
        [onSelectedTaskIdChange],
    );

    const handleIncludeNoResultsChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIncludeNoResults(event.target.checked);
    }, []);

    return (
        <Container
            maxWidth={false}
            sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}
        >
            <Box>
                <Paper
                    sx={{
                        ...CARD_STYLE,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 1,
                    }}
                >
                    <FormControl sx={{ width: "200px" }}>
                        <DatePicker
                            label="After Date"
                            value={afterDate}
                            onChange={handleAfterDateChange}
                            slotProps={{
                                textField: {
                                    // @ts-expect-error: typings don't know about `clearable` (?)
                                    clearable: true,
                                },
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ minWidth: "300px" }}>
                        <InputLabel htmlFor="search">Search</InputLabel>
                        <OutlinedInput
                            id="search"
                            label="Search"
                            value={searchText}
                            onChange={handleSearchTextChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        aria-label="clear search"
                                        onClick={() => {
                                            setSearchText("");
                                        }}
                                        sx={{ visibility: searchText ? "visible" : "hidden" }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {includeCountryFilter && (
                        <FormControl sx={{ minWidth: "120px" }}>
                            <InputLabel id="country-label">Country</InputLabel>
                            <Select
                                labelId="country-label"
                                id="country"
                                label="Country"
                                value={countryCode ?? ""}
                                onChange={handleCountryCodeChange}
                            >
                                <MenuItem value="">All</MenuItem>
                                {COUNTRY_CODES.map((code) => (
                                    <MenuItem key={code} value={code}>
                                        {code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <FormControl sx={{ marginLeft: 1, marginRight: -1 }}>
                        <FormControlLabel
                            label="Include tasks with no results"
                            control={
                                <Checkbox
                                    checked={includeNoResults}
                                    onChange={handleIncludeNoResultsChange}
                                />
                            }
                        />
                    </FormControl>
                </Paper>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    gap: 2,
                    maxHeight: "calc(100vh - 216px)",
                }}
            >
                <Box sx={{ width: "300px" }}>
                    <Paper sx={{ ...CARD_STYLE, overflow: "auto" }}>
                        {tasksQuery.isPending ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <CircularProgress size={60} />
                            </Box>
                        ) : filtered ? (
                            <Box>
                                {filteredTaskListItems ? (
                                    <List dense>{filteredTaskListItems}</List>
                                ) : (
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Typography>No tasks found</Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box>
                                {pinnedTaskListItems.size > 0 && (
                                    <>
                                        <List
                                            dense
                                            subheader={
                                                <ListSubheader
                                                    sx={{
                                                        lineHeight: 1,
                                                        paddingY: 1,
                                                        paddingLeft: 0,
                                                    }}
                                                >
                                                    {pinnedLabel}
                                                </ListSubheader>
                                            }
                                        >
                                            {pinnedTaskListItems}
                                        </List>
                                        <Divider />
                                    </>
                                )}
                                <List dense>{unpinnedTaskListItems}</List>
                            </Box>
                        )}
                    </Paper>
                </Box>
                <Box sx={{ flex: 1, minWidth: 0 }}>
                    <Paper sx={CARD_STYLE}>
                        {selectedTask && (
                            <Box>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography variant="h6">
                                        {selectedTask.display_name}
                                    </Typography>
                                    <Box sx={{ flex: 1 }} />
                                    {/* TODO: don't hardcode link/button into component */}
                                    {enableSelection && (
                                        <RouterLink
                                            to={
                                                selectedCampaignType
                                                    ? CAMPAIGN_TYPE_CREATIVE_UPDATE_ROUTES[
                                                          selectedCampaignType
                                                      ]
                                                    : ""
                                            }
                                            search={{
                                                profile_id: selectedProfileId,
                                                campaign_ids: selectedCampaignIds,
                                            }}
                                            target="_blank"
                                            disabled={
                                                !selectedCampaignType || _selectedRows.length === 0
                                            }
                                        >
                                            <Button
                                                size="small"
                                                startIcon={<LaunchIcon />}
                                                disabled={
                                                    !selectedCampaignType ||
                                                    _selectedRows.length === 0
                                                }
                                            >
                                                Bulk Creative Update
                                            </Button>
                                        </RouterLink>
                                    )}
                                    <Button
                                        size="small"
                                        component="a"
                                        href={`${apiClient.baseURL}/tasks/export/${selectedTask.id}.csv`}
                                        startIcon={<DownloadIcon />}
                                        disabled={!taskHasResults(selectedTask)}
                                    >
                                        Download CSV
                                    </Button>
                                </Box>
                                <Table size="small" sx={{ width: "auto" }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th">
                                                <Typography fontWeight={500}>User</Typography>
                                            </TableCell>
                                            <TableCell>{selectedTask.user ?? "None"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th">
                                                <Typography fontWeight={500}>Started at</Typography>
                                            </TableCell>
                                            <TableCell>{startedAtString}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th">
                                                <Typography fontWeight={500}>
                                                    Finished at
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{finishedAtString}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        )}
                        {taskQuery.isLoading && <Loading />}
                        {!taskQuery.isLoading &&
                            selectedTask?.finished_at &&
                            (taskHasResults(selectedTask) ? (
                                <TaskResultsTable
                                    task={selectedTask}
                                    selectedSuccessIndices={selectedSuccessIndices}
                                    selectedFailureIndices={selectedFailureIndices}
                                    onSelectedSuccessIndicesChange={setSelectedSuccessIndices}
                                    onSelectedFailureIndicesChange={setSelectedFailureIndices}
                                    enableSelection={enableSelection}
                                />
                            ) : (
                                <Typography>No results</Typography>
                            ))}
                    </Paper>
                </Box>
            </Box>
        </Container>
    );
}

interface TaskListItemProps {
    task: Task;
    selected: boolean;
    onClick: () => void;
}

function TaskListItem({ task, selected, onClick }: TaskListItemProps) {
    const resultCount = task.success_count + task.failure_count;

    return (
        <ListItem disablePadding key={task.id}>
            <ListItemButton selected={selected} onClick={onClick}>
                <ListItemText>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontWeight={500}>{task.display_name}</Typography>
                        <Box sx={{ flex: 1 }} />
                        {task.type === "action" && (
                            <Box sx={{ display: "flex", gap: "2px" }}>
                                {task.success_count > 0 && (
                                    <Chip size="small" color="primary" label={task.success_count} />
                                )}
                                {task.failure_count > 0 && (
                                    <Chip size="small" color="error" label={task.failure_count} />
                                )}
                            </Box>
                        )}
                        {task.type === "report" && (
                            <Box sx={{ display: "flex", gap: "2px" }}>
                                {resultCount > 0 && (
                                    <Chip size="small" color="error" label={resultCount} />
                                )}
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <Typography variant="body2">
                            {formatDateTime(DateTime.fromISO(task.started_at))}
                        </Typography>
                    </Box>
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );
}

interface TaskResultsTableProps {
    task: Task;
    selectedSuccessIndices: Immutable.Set<number>;
    selectedFailureIndices: Immutable.Set<number>;
    onSelectedSuccessIndicesChange: (successes: Immutable.Set<number>) => void;
    onSelectedFailureIndicesChange: (failures: Immutable.Set<number>) => void;
    enableSelection: boolean;
}

function TaskResultsTable({
    task,
    selectedSuccessIndices,
    selectedFailureIndices,
    onSelectedSuccessIndicesChange,
    onSelectedFailureIndicesChange,
    enableSelection,
}: TaskResultsTableProps) {
    const columns = Immutable.List(Object.entries(task.columns)).sortBy(
        ([key]) => COLUMN_SORT_INDEX[key],
    );

    const allSuccessesSelected = selectedSuccessIndices.size === task.successes?.length;
    const allFailuresSelected = selectedFailureIndices.size === task.failures?.length;
    const allTasksSelected = allSuccessesSelected && allFailuresSelected;

    const handleSuccessSelectedChange = useCallback(
        (index: number, event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                onSelectedSuccessIndicesChange(selectedSuccessIndices.add(index));
            } else {
                onSelectedSuccessIndicesChange(selectedSuccessIndices.delete(index));
            }
        },
        [onSelectedSuccessIndicesChange, selectedSuccessIndices],
    );

    const handleFailureSelectedChange = useCallback(
        (index: number, event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                onSelectedFailureIndicesChange(selectedFailureIndices.add(index));
            } else {
                onSelectedFailureIndicesChange(selectedFailureIndices.delete(index));
            }
        },
        [onSelectedFailureIndicesChange, selectedFailureIndices],
    );

    const handleAllSelectedChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                onSelectedSuccessIndicesChange(
                    Immutable.Set(task.successes?.map((_, index) => index)),
                );
                onSelectedFailureIndicesChange(
                    Immutable.Set(task.failures?.map((_, index) => index)),
                );
            } else {
                onSelectedSuccessIndicesChange(Immutable.Set());
                onSelectedFailureIndicesChange(Immutable.Set());
            }
        },
        [
            onSelectedSuccessIndicesChange,
            onSelectedFailureIndicesChange,
            task.successes,
            task.failures,
        ],
    );

    return (
        <TableContainer>
            <Table
                stickyHeader
                size="small"
                sx={{ width: "auto", "& .MuiTableCell-root": { whiteSpace: "nowrap" } }}
            >
                <TableHead>
                    <TableRow>
                        {enableSelection && (
                            <TableCell sx={{ padding: 0 }}>
                                <Checkbox
                                    size="small"
                                    checked={allTasksSelected}
                                    onChange={handleAllSelectedChange}
                                />
                            </TableCell>
                        )}
                        {task.type === "action" && <TableCell>Status</TableCell>}
                        {columns.map(([key, name]) => (
                            <TableCell key={key}>{name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {task.failures?.map((row, index) => (
                        // biome-ignore lint/suspicious/noArrayIndexKey:
                        <TableRow key={index}>
                            {enableSelection && (
                                <TableCell sx={{ padding: 0 }}>
                                    <Checkbox
                                        size="small"
                                        checked={selectedFailureIndices.has(index)}
                                        onChange={(event) =>
                                            handleFailureSelectedChange(index, event)
                                        }
                                    />
                                </TableCell>
                            )}
                            {task.type === "action" && (
                                <TableCell>
                                    <Chip size="small" color="error" label="FAILURE" />
                                </TableCell>
                            )}
                            {columns.map(([key]) => (
                                <TableCell key={key}>{row[key]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                <TableBody>
                    {task.successes?.map((row, index) => (
                        // biome-ignore lint/suspicious/noArrayIndexKey:
                        <TableRow key={index}>
                            {enableSelection && (
                                <TableCell sx={{ padding: 0 }}>
                                    <Checkbox
                                        size="small"
                                        checked={selectedSuccessIndices.has(index)}
                                        onChange={(event) =>
                                            handleSuccessSelectedChange(index, event)
                                        }
                                    />
                                </TableCell>
                            )}
                            {task.type === "action" && (
                                <TableCell>
                                    <Chip size="small" color="primary" label="SUCCESS" />
                                </TableCell>
                            )}
                            {columns.map(([key]) => (
                                <TableCell key={key}>{stringify(row[key])}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function taskHasResults(task: Task): boolean {
    return task.success_count > 0 || task.failure_count > 0;
}

function stringify(value: unknown): string {
    if (typeof value === "string") {
        return value;
    }

    return JSON.stringify(value);
}
