/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as NoAuthImport } from './routes/_no-auth'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as NoAuthResetPasswordImport } from './routes/_no-auth/reset-password'
import { Route as NoAuthPasswordResetSentImport } from './routes/_no-auth/password-reset-sent'
import { Route as NoAuthLogInImport } from './routes/_no-auth/log-in'
import { Route as NoAuthForgotPasswordImport } from './routes/_no-auth/forgot-password'
import { Route as AuthShopifyImport } from './routes/_auth/shopify'
import { Route as AuthMetaImport } from './routes/_auth/meta'
import { Route as AuthAmazonImport } from './routes/_auth/amazon'
import { Route as AuthAdminImport } from './routes/_auth/_admin'
import { Route as AuthShopifyIndexImport } from './routes/_auth/shopify/index'
import { Route as AuthMetaIndexImport } from './routes/_auth/meta/index'
import { Route as AuthAmazonIndexImport } from './routes/_auth/amazon/index'
import { Route as AuthShopifyTasksImport } from './routes/_auth/shopify/tasks'
import { Route as AuthShopifyPricingImport } from './routes/_auth/shopify/pricing'
import { Route as AuthMetaTasksImport } from './routes/_auth/meta/tasks'
import { Route as AuthMetaAdUpdatesImport } from './routes/_auth/meta/ad-updates'
import { Route as AuthAmazonTasksImport } from './routes/_auth/amazon/tasks'
import { Route as AuthAmazonSponsoredVideoCreativeImport } from './routes/_auth/amazon/sponsored-video-creative'
import { Route as AuthAmazonSponsoredProductsCreativeImport } from './routes/_auth/amazon/sponsored-products-creative'
import { Route as AuthAmazonSponsoredProductsBiddingImport } from './routes/_auth/amazon/sponsored-products-bidding'
import { Route as AuthAmazonSponsoredBrandsCreativeImport } from './routes/_auth/amazon/sponsored-brands-creative'
import { Route as AuthAmazonSponsoredBrandsBiddingImport } from './routes/_auth/amazon/sponsored-brands-bidding'
import { Route as AuthAmazonReportsImport } from './routes/_auth/amazon/reports'
import { Route as AuthAmazonCampaignsImport } from './routes/_auth/amazon/campaigns'
import { Route as AuthAmazonBulkCreativeUpdateImport } from './routes/_auth/amazon/bulk-creative-update'
import { Route as AuthAmazonBiddingUpdateImport } from './routes/_auth/amazon/bidding-update'
import { Route as AuthAmazonBannerVideoCreativeImport } from './routes/_auth/amazon/banner-video-creative'
import { Route as AuthShopifyPricingIndexImport } from './routes/_auth/shopify/pricing/index'
import { Route as AuthMetaAdUpdatesIndexImport } from './routes/_auth/meta/ad-updates/index'
import { Route as AuthShopifyPricingHeatmapImport } from './routes/_auth/shopify/pricing/heatmap'
import { Route as AuthShopifyPricingCreateImport } from './routes/_auth/shopify/pricing/create'
import { Route as AuthMetaAdUpdatesCreateImport } from './routes/_auth/meta/ad-updates/create'
import { Route as AuthShopifyPricingUpdateIdEditImport } from './routes/_auth/shopify/pricing/$updateId.edit'
import { Route as AuthMetaAdUpdatesUpdateIdEditImport } from './routes/_auth/meta/ad-updates/$updateId.edit'

// Create/Update Routes

const NoAuthRoute = NoAuthImport.update({
  id: '/_no-auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const NoAuthResetPasswordRoute = NoAuthResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => NoAuthRoute,
} as any)

const NoAuthPasswordResetSentRoute = NoAuthPasswordResetSentImport.update({
  id: '/password-reset-sent',
  path: '/password-reset-sent',
  getParentRoute: () => NoAuthRoute,
} as any)

const NoAuthLogInRoute = NoAuthLogInImport.update({
  id: '/log-in',
  path: '/log-in',
  getParentRoute: () => NoAuthRoute,
} as any)

const NoAuthForgotPasswordRoute = NoAuthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => NoAuthRoute,
} as any)

const AuthShopifyRoute = AuthShopifyImport.update({
  id: '/shopify',
  path: '/shopify',
  getParentRoute: () => AuthRoute,
} as any)

const AuthMetaRoute = AuthMetaImport.update({
  id: '/meta',
  path: '/meta',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAmazonRoute = AuthAmazonImport.update({
  id: '/amazon',
  path: '/amazon',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAdminRoute = AuthAdminImport.update({
  id: '/_admin',
  getParentRoute: () => AuthRoute,
} as any)

const AuthShopifyIndexRoute = AuthShopifyIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthShopifyRoute,
} as any)

const AuthMetaIndexRoute = AuthMetaIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthMetaRoute,
} as any)

const AuthAmazonIndexRoute = AuthAmazonIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthAmazonRoute,
} as any)

const AuthShopifyTasksRoute = AuthShopifyTasksImport.update({
  id: '/tasks',
  path: '/tasks',
  getParentRoute: () => AuthShopifyRoute,
} as any)

const AuthShopifyPricingRoute = AuthShopifyPricingImport.update({
  id: '/pricing',
  path: '/pricing',
  getParentRoute: () => AuthShopifyRoute,
} as any)

const AuthMetaTasksRoute = AuthMetaTasksImport.update({
  id: '/tasks',
  path: '/tasks',
  getParentRoute: () => AuthMetaRoute,
} as any)

const AuthMetaAdUpdatesRoute = AuthMetaAdUpdatesImport.update({
  id: '/ad-updates',
  path: '/ad-updates',
  getParentRoute: () => AuthMetaRoute,
} as any)

const AuthAmazonTasksRoute = AuthAmazonTasksImport.update({
  id: '/tasks',
  path: '/tasks',
  getParentRoute: () => AuthAmazonRoute,
} as any)

const AuthAmazonSponsoredVideoCreativeRoute =
  AuthAmazonSponsoredVideoCreativeImport.update({
    id: '/sponsored-video-creative',
    path: '/sponsored-video-creative',
    getParentRoute: () => AuthAmazonRoute,
  } as any)

const AuthAmazonSponsoredProductsCreativeRoute =
  AuthAmazonSponsoredProductsCreativeImport.update({
    id: '/sponsored-products-creative',
    path: '/sponsored-products-creative',
    getParentRoute: () => AuthAmazonRoute,
  } as any)

const AuthAmazonSponsoredProductsBiddingRoute =
  AuthAmazonSponsoredProductsBiddingImport.update({
    id: '/sponsored-products-bidding',
    path: '/sponsored-products-bidding',
    getParentRoute: () => AuthAmazonRoute,
  } as any)

const AuthAmazonSponsoredBrandsCreativeRoute =
  AuthAmazonSponsoredBrandsCreativeImport.update({
    id: '/sponsored-brands-creative',
    path: '/sponsored-brands-creative',
    getParentRoute: () => AuthAmazonRoute,
  } as any)

const AuthAmazonSponsoredBrandsBiddingRoute =
  AuthAmazonSponsoredBrandsBiddingImport.update({
    id: '/sponsored-brands-bidding',
    path: '/sponsored-brands-bidding',
    getParentRoute: () => AuthAmazonRoute,
  } as any)

const AuthAmazonReportsRoute = AuthAmazonReportsImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => AuthAmazonRoute,
} as any)

const AuthAmazonCampaignsRoute = AuthAmazonCampaignsImport.update({
  id: '/campaigns',
  path: '/campaigns',
  getParentRoute: () => AuthAmazonRoute,
} as any)

const AuthAmazonBulkCreativeUpdateRoute =
  AuthAmazonBulkCreativeUpdateImport.update({
    id: '/bulk-creative-update',
    path: '/bulk-creative-update',
    getParentRoute: () => AuthAmazonRoute,
  } as any)

const AuthAmazonBiddingUpdateRoute = AuthAmazonBiddingUpdateImport.update({
  id: '/bidding-update',
  path: '/bidding-update',
  getParentRoute: () => AuthAmazonRoute,
} as any)

const AuthAmazonBannerVideoCreativeRoute =
  AuthAmazonBannerVideoCreativeImport.update({
    id: '/banner-video-creative',
    path: '/banner-video-creative',
    getParentRoute: () => AuthAmazonRoute,
  } as any)

const AuthShopifyPricingIndexRoute = AuthShopifyPricingIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthShopifyPricingRoute,
} as any)

const AuthMetaAdUpdatesIndexRoute = AuthMetaAdUpdatesIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthMetaAdUpdatesRoute,
} as any)

const AuthShopifyPricingHeatmapRoute = AuthShopifyPricingHeatmapImport.update({
  id: '/heatmap',
  path: '/heatmap',
  getParentRoute: () => AuthShopifyPricingRoute,
} as any)

const AuthShopifyPricingCreateRoute = AuthShopifyPricingCreateImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AuthShopifyPricingRoute,
} as any)

const AuthMetaAdUpdatesCreateRoute = AuthMetaAdUpdatesCreateImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AuthMetaAdUpdatesRoute,
} as any)

const AuthShopifyPricingUpdateIdEditRoute =
  AuthShopifyPricingUpdateIdEditImport.update({
    id: '/$updateId/edit',
    path: '/$updateId/edit',
    getParentRoute: () => AuthShopifyPricingRoute,
  } as any)

const AuthMetaAdUpdatesUpdateIdEditRoute =
  AuthMetaAdUpdatesUpdateIdEditImport.update({
    id: '/$updateId/edit',
    path: '/$updateId/edit',
    getParentRoute: () => AuthMetaAdUpdatesRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_no-auth': {
      id: '/_no-auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof NoAuthImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_admin': {
      id: '/_auth/_admin'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthAdminImport
      parentRoute: typeof AuthImport
    }
    '/_auth/amazon': {
      id: '/_auth/amazon'
      path: '/amazon'
      fullPath: '/amazon'
      preLoaderRoute: typeof AuthAmazonImport
      parentRoute: typeof AuthImport
    }
    '/_auth/meta': {
      id: '/_auth/meta'
      path: '/meta'
      fullPath: '/meta'
      preLoaderRoute: typeof AuthMetaImport
      parentRoute: typeof AuthImport
    }
    '/_auth/shopify': {
      id: '/_auth/shopify'
      path: '/shopify'
      fullPath: '/shopify'
      preLoaderRoute: typeof AuthShopifyImport
      parentRoute: typeof AuthImport
    }
    '/_no-auth/forgot-password': {
      id: '/_no-auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof NoAuthForgotPasswordImport
      parentRoute: typeof NoAuthImport
    }
    '/_no-auth/log-in': {
      id: '/_no-auth/log-in'
      path: '/log-in'
      fullPath: '/log-in'
      preLoaderRoute: typeof NoAuthLogInImport
      parentRoute: typeof NoAuthImport
    }
    '/_no-auth/password-reset-sent': {
      id: '/_no-auth/password-reset-sent'
      path: '/password-reset-sent'
      fullPath: '/password-reset-sent'
      preLoaderRoute: typeof NoAuthPasswordResetSentImport
      parentRoute: typeof NoAuthImport
    }
    '/_no-auth/reset-password': {
      id: '/_no-auth/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof NoAuthResetPasswordImport
      parentRoute: typeof NoAuthImport
    }
    '/_auth/amazon/banner-video-creative': {
      id: '/_auth/amazon/banner-video-creative'
      path: '/banner-video-creative'
      fullPath: '/amazon/banner-video-creative'
      preLoaderRoute: typeof AuthAmazonBannerVideoCreativeImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/bidding-update': {
      id: '/_auth/amazon/bidding-update'
      path: '/bidding-update'
      fullPath: '/amazon/bidding-update'
      preLoaderRoute: typeof AuthAmazonBiddingUpdateImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/bulk-creative-update': {
      id: '/_auth/amazon/bulk-creative-update'
      path: '/bulk-creative-update'
      fullPath: '/amazon/bulk-creative-update'
      preLoaderRoute: typeof AuthAmazonBulkCreativeUpdateImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/campaigns': {
      id: '/_auth/amazon/campaigns'
      path: '/campaigns'
      fullPath: '/amazon/campaigns'
      preLoaderRoute: typeof AuthAmazonCampaignsImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/reports': {
      id: '/_auth/amazon/reports'
      path: '/reports'
      fullPath: '/amazon/reports'
      preLoaderRoute: typeof AuthAmazonReportsImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/sponsored-brands-bidding': {
      id: '/_auth/amazon/sponsored-brands-bidding'
      path: '/sponsored-brands-bidding'
      fullPath: '/amazon/sponsored-brands-bidding'
      preLoaderRoute: typeof AuthAmazonSponsoredBrandsBiddingImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/sponsored-brands-creative': {
      id: '/_auth/amazon/sponsored-brands-creative'
      path: '/sponsored-brands-creative'
      fullPath: '/amazon/sponsored-brands-creative'
      preLoaderRoute: typeof AuthAmazonSponsoredBrandsCreativeImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/sponsored-products-bidding': {
      id: '/_auth/amazon/sponsored-products-bidding'
      path: '/sponsored-products-bidding'
      fullPath: '/amazon/sponsored-products-bidding'
      preLoaderRoute: typeof AuthAmazonSponsoredProductsBiddingImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/sponsored-products-creative': {
      id: '/_auth/amazon/sponsored-products-creative'
      path: '/sponsored-products-creative'
      fullPath: '/amazon/sponsored-products-creative'
      preLoaderRoute: typeof AuthAmazonSponsoredProductsCreativeImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/sponsored-video-creative': {
      id: '/_auth/amazon/sponsored-video-creative'
      path: '/sponsored-video-creative'
      fullPath: '/amazon/sponsored-video-creative'
      preLoaderRoute: typeof AuthAmazonSponsoredVideoCreativeImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/amazon/tasks': {
      id: '/_auth/amazon/tasks'
      path: '/tasks'
      fullPath: '/amazon/tasks'
      preLoaderRoute: typeof AuthAmazonTasksImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/meta/ad-updates': {
      id: '/_auth/meta/ad-updates'
      path: '/ad-updates'
      fullPath: '/meta/ad-updates'
      preLoaderRoute: typeof AuthMetaAdUpdatesImport
      parentRoute: typeof AuthMetaImport
    }
    '/_auth/meta/tasks': {
      id: '/_auth/meta/tasks'
      path: '/tasks'
      fullPath: '/meta/tasks'
      preLoaderRoute: typeof AuthMetaTasksImport
      parentRoute: typeof AuthMetaImport
    }
    '/_auth/shopify/pricing': {
      id: '/_auth/shopify/pricing'
      path: '/pricing'
      fullPath: '/shopify/pricing'
      preLoaderRoute: typeof AuthShopifyPricingImport
      parentRoute: typeof AuthShopifyImport
    }
    '/_auth/shopify/tasks': {
      id: '/_auth/shopify/tasks'
      path: '/tasks'
      fullPath: '/shopify/tasks'
      preLoaderRoute: typeof AuthShopifyTasksImport
      parentRoute: typeof AuthShopifyImport
    }
    '/_auth/amazon/': {
      id: '/_auth/amazon/'
      path: '/'
      fullPath: '/amazon/'
      preLoaderRoute: typeof AuthAmazonIndexImport
      parentRoute: typeof AuthAmazonImport
    }
    '/_auth/meta/': {
      id: '/_auth/meta/'
      path: '/'
      fullPath: '/meta/'
      preLoaderRoute: typeof AuthMetaIndexImport
      parentRoute: typeof AuthMetaImport
    }
    '/_auth/shopify/': {
      id: '/_auth/shopify/'
      path: '/'
      fullPath: '/shopify/'
      preLoaderRoute: typeof AuthShopifyIndexImport
      parentRoute: typeof AuthShopifyImport
    }
    '/_auth/meta/ad-updates/create': {
      id: '/_auth/meta/ad-updates/create'
      path: '/create'
      fullPath: '/meta/ad-updates/create'
      preLoaderRoute: typeof AuthMetaAdUpdatesCreateImport
      parentRoute: typeof AuthMetaAdUpdatesImport
    }
    '/_auth/shopify/pricing/create': {
      id: '/_auth/shopify/pricing/create'
      path: '/create'
      fullPath: '/shopify/pricing/create'
      preLoaderRoute: typeof AuthShopifyPricingCreateImport
      parentRoute: typeof AuthShopifyPricingImport
    }
    '/_auth/shopify/pricing/heatmap': {
      id: '/_auth/shopify/pricing/heatmap'
      path: '/heatmap'
      fullPath: '/shopify/pricing/heatmap'
      preLoaderRoute: typeof AuthShopifyPricingHeatmapImport
      parentRoute: typeof AuthShopifyPricingImport
    }
    '/_auth/meta/ad-updates/': {
      id: '/_auth/meta/ad-updates/'
      path: '/'
      fullPath: '/meta/ad-updates/'
      preLoaderRoute: typeof AuthMetaAdUpdatesIndexImport
      parentRoute: typeof AuthMetaAdUpdatesImport
    }
    '/_auth/shopify/pricing/': {
      id: '/_auth/shopify/pricing/'
      path: '/'
      fullPath: '/shopify/pricing/'
      preLoaderRoute: typeof AuthShopifyPricingIndexImport
      parentRoute: typeof AuthShopifyPricingImport
    }
    '/_auth/meta/ad-updates/$updateId/edit': {
      id: '/_auth/meta/ad-updates/$updateId/edit'
      path: '/$updateId/edit'
      fullPath: '/meta/ad-updates/$updateId/edit'
      preLoaderRoute: typeof AuthMetaAdUpdatesUpdateIdEditImport
      parentRoute: typeof AuthMetaAdUpdatesImport
    }
    '/_auth/shopify/pricing/$updateId/edit': {
      id: '/_auth/shopify/pricing/$updateId/edit'
      path: '/$updateId/edit'
      fullPath: '/shopify/pricing/$updateId/edit'
      preLoaderRoute: typeof AuthShopifyPricingUpdateIdEditImport
      parentRoute: typeof AuthShopifyPricingImport
    }
  }
}

// Create and export the route tree

interface AuthAmazonRouteChildren {
  AuthAmazonBannerVideoCreativeRoute: typeof AuthAmazonBannerVideoCreativeRoute
  AuthAmazonBiddingUpdateRoute: typeof AuthAmazonBiddingUpdateRoute
  AuthAmazonBulkCreativeUpdateRoute: typeof AuthAmazonBulkCreativeUpdateRoute
  AuthAmazonCampaignsRoute: typeof AuthAmazonCampaignsRoute
  AuthAmazonReportsRoute: typeof AuthAmazonReportsRoute
  AuthAmazonSponsoredBrandsBiddingRoute: typeof AuthAmazonSponsoredBrandsBiddingRoute
  AuthAmazonSponsoredBrandsCreativeRoute: typeof AuthAmazonSponsoredBrandsCreativeRoute
  AuthAmazonSponsoredProductsBiddingRoute: typeof AuthAmazonSponsoredProductsBiddingRoute
  AuthAmazonSponsoredProductsCreativeRoute: typeof AuthAmazonSponsoredProductsCreativeRoute
  AuthAmazonSponsoredVideoCreativeRoute: typeof AuthAmazonSponsoredVideoCreativeRoute
  AuthAmazonTasksRoute: typeof AuthAmazonTasksRoute
  AuthAmazonIndexRoute: typeof AuthAmazonIndexRoute
}

const AuthAmazonRouteChildren: AuthAmazonRouteChildren = {
  AuthAmazonBannerVideoCreativeRoute: AuthAmazonBannerVideoCreativeRoute,
  AuthAmazonBiddingUpdateRoute: AuthAmazonBiddingUpdateRoute,
  AuthAmazonBulkCreativeUpdateRoute: AuthAmazonBulkCreativeUpdateRoute,
  AuthAmazonCampaignsRoute: AuthAmazonCampaignsRoute,
  AuthAmazonReportsRoute: AuthAmazonReportsRoute,
  AuthAmazonSponsoredBrandsBiddingRoute: AuthAmazonSponsoredBrandsBiddingRoute,
  AuthAmazonSponsoredBrandsCreativeRoute:
    AuthAmazonSponsoredBrandsCreativeRoute,
  AuthAmazonSponsoredProductsBiddingRoute:
    AuthAmazonSponsoredProductsBiddingRoute,
  AuthAmazonSponsoredProductsCreativeRoute:
    AuthAmazonSponsoredProductsCreativeRoute,
  AuthAmazonSponsoredVideoCreativeRoute: AuthAmazonSponsoredVideoCreativeRoute,
  AuthAmazonTasksRoute: AuthAmazonTasksRoute,
  AuthAmazonIndexRoute: AuthAmazonIndexRoute,
}

const AuthAmazonRouteWithChildren = AuthAmazonRoute._addFileChildren(
  AuthAmazonRouteChildren,
)

interface AuthMetaAdUpdatesRouteChildren {
  AuthMetaAdUpdatesCreateRoute: typeof AuthMetaAdUpdatesCreateRoute
  AuthMetaAdUpdatesIndexRoute: typeof AuthMetaAdUpdatesIndexRoute
  AuthMetaAdUpdatesUpdateIdEditRoute: typeof AuthMetaAdUpdatesUpdateIdEditRoute
}

const AuthMetaAdUpdatesRouteChildren: AuthMetaAdUpdatesRouteChildren = {
  AuthMetaAdUpdatesCreateRoute: AuthMetaAdUpdatesCreateRoute,
  AuthMetaAdUpdatesIndexRoute: AuthMetaAdUpdatesIndexRoute,
  AuthMetaAdUpdatesUpdateIdEditRoute: AuthMetaAdUpdatesUpdateIdEditRoute,
}

const AuthMetaAdUpdatesRouteWithChildren =
  AuthMetaAdUpdatesRoute._addFileChildren(AuthMetaAdUpdatesRouteChildren)

interface AuthMetaRouteChildren {
  AuthMetaAdUpdatesRoute: typeof AuthMetaAdUpdatesRouteWithChildren
  AuthMetaTasksRoute: typeof AuthMetaTasksRoute
  AuthMetaIndexRoute: typeof AuthMetaIndexRoute
}

const AuthMetaRouteChildren: AuthMetaRouteChildren = {
  AuthMetaAdUpdatesRoute: AuthMetaAdUpdatesRouteWithChildren,
  AuthMetaTasksRoute: AuthMetaTasksRoute,
  AuthMetaIndexRoute: AuthMetaIndexRoute,
}

const AuthMetaRouteWithChildren = AuthMetaRoute._addFileChildren(
  AuthMetaRouteChildren,
)

interface AuthShopifyPricingRouteChildren {
  AuthShopifyPricingCreateRoute: typeof AuthShopifyPricingCreateRoute
  AuthShopifyPricingHeatmapRoute: typeof AuthShopifyPricingHeatmapRoute
  AuthShopifyPricingIndexRoute: typeof AuthShopifyPricingIndexRoute
  AuthShopifyPricingUpdateIdEditRoute: typeof AuthShopifyPricingUpdateIdEditRoute
}

const AuthShopifyPricingRouteChildren: AuthShopifyPricingRouteChildren = {
  AuthShopifyPricingCreateRoute: AuthShopifyPricingCreateRoute,
  AuthShopifyPricingHeatmapRoute: AuthShopifyPricingHeatmapRoute,
  AuthShopifyPricingIndexRoute: AuthShopifyPricingIndexRoute,
  AuthShopifyPricingUpdateIdEditRoute: AuthShopifyPricingUpdateIdEditRoute,
}

const AuthShopifyPricingRouteWithChildren =
  AuthShopifyPricingRoute._addFileChildren(AuthShopifyPricingRouteChildren)

interface AuthShopifyRouteChildren {
  AuthShopifyPricingRoute: typeof AuthShopifyPricingRouteWithChildren
  AuthShopifyTasksRoute: typeof AuthShopifyTasksRoute
  AuthShopifyIndexRoute: typeof AuthShopifyIndexRoute
}

const AuthShopifyRouteChildren: AuthShopifyRouteChildren = {
  AuthShopifyPricingRoute: AuthShopifyPricingRouteWithChildren,
  AuthShopifyTasksRoute: AuthShopifyTasksRoute,
  AuthShopifyIndexRoute: AuthShopifyIndexRoute,
}

const AuthShopifyRouteWithChildren = AuthShopifyRoute._addFileChildren(
  AuthShopifyRouteChildren,
)

interface AuthRouteChildren {
  AuthAdminRoute: typeof AuthAdminRoute
  AuthAmazonRoute: typeof AuthAmazonRouteWithChildren
  AuthMetaRoute: typeof AuthMetaRouteWithChildren
  AuthShopifyRoute: typeof AuthShopifyRouteWithChildren
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAdminRoute: AuthAdminRoute,
  AuthAmazonRoute: AuthAmazonRouteWithChildren,
  AuthMetaRoute: AuthMetaRouteWithChildren,
  AuthShopifyRoute: AuthShopifyRouteWithChildren,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface NoAuthRouteChildren {
  NoAuthForgotPasswordRoute: typeof NoAuthForgotPasswordRoute
  NoAuthLogInRoute: typeof NoAuthLogInRoute
  NoAuthPasswordResetSentRoute: typeof NoAuthPasswordResetSentRoute
  NoAuthResetPasswordRoute: typeof NoAuthResetPasswordRoute
}

const NoAuthRouteChildren: NoAuthRouteChildren = {
  NoAuthForgotPasswordRoute: NoAuthForgotPasswordRoute,
  NoAuthLogInRoute: NoAuthLogInRoute,
  NoAuthPasswordResetSentRoute: NoAuthPasswordResetSentRoute,
  NoAuthResetPasswordRoute: NoAuthResetPasswordRoute,
}

const NoAuthRouteWithChildren =
  NoAuthRoute._addFileChildren(NoAuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthAdminRoute
  '/amazon': typeof AuthAmazonRouteWithChildren
  '/meta': typeof AuthMetaRouteWithChildren
  '/shopify': typeof AuthShopifyRouteWithChildren
  '/forgot-password': typeof NoAuthForgotPasswordRoute
  '/log-in': typeof NoAuthLogInRoute
  '/password-reset-sent': typeof NoAuthPasswordResetSentRoute
  '/reset-password': typeof NoAuthResetPasswordRoute
  '/amazon/banner-video-creative': typeof AuthAmazonBannerVideoCreativeRoute
  '/amazon/bidding-update': typeof AuthAmazonBiddingUpdateRoute
  '/amazon/bulk-creative-update': typeof AuthAmazonBulkCreativeUpdateRoute
  '/amazon/campaigns': typeof AuthAmazonCampaignsRoute
  '/amazon/reports': typeof AuthAmazonReportsRoute
  '/amazon/sponsored-brands-bidding': typeof AuthAmazonSponsoredBrandsBiddingRoute
  '/amazon/sponsored-brands-creative': typeof AuthAmazonSponsoredBrandsCreativeRoute
  '/amazon/sponsored-products-bidding': typeof AuthAmazonSponsoredProductsBiddingRoute
  '/amazon/sponsored-products-creative': typeof AuthAmazonSponsoredProductsCreativeRoute
  '/amazon/sponsored-video-creative': typeof AuthAmazonSponsoredVideoCreativeRoute
  '/amazon/tasks': typeof AuthAmazonTasksRoute
  '/meta/ad-updates': typeof AuthMetaAdUpdatesRouteWithChildren
  '/meta/tasks': typeof AuthMetaTasksRoute
  '/shopify/pricing': typeof AuthShopifyPricingRouteWithChildren
  '/shopify/tasks': typeof AuthShopifyTasksRoute
  '/amazon/': typeof AuthAmazonIndexRoute
  '/meta/': typeof AuthMetaIndexRoute
  '/shopify/': typeof AuthShopifyIndexRoute
  '/meta/ad-updates/create': typeof AuthMetaAdUpdatesCreateRoute
  '/shopify/pricing/create': typeof AuthShopifyPricingCreateRoute
  '/shopify/pricing/heatmap': typeof AuthShopifyPricingHeatmapRoute
  '/meta/ad-updates/': typeof AuthMetaAdUpdatesIndexRoute
  '/shopify/pricing/': typeof AuthShopifyPricingIndexRoute
  '/meta/ad-updates/$updateId/edit': typeof AuthMetaAdUpdatesUpdateIdEditRoute
  '/shopify/pricing/$updateId/edit': typeof AuthShopifyPricingUpdateIdEditRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthAdminRoute
  '/forgot-password': typeof NoAuthForgotPasswordRoute
  '/log-in': typeof NoAuthLogInRoute
  '/password-reset-sent': typeof NoAuthPasswordResetSentRoute
  '/reset-password': typeof NoAuthResetPasswordRoute
  '/amazon/banner-video-creative': typeof AuthAmazonBannerVideoCreativeRoute
  '/amazon/bidding-update': typeof AuthAmazonBiddingUpdateRoute
  '/amazon/bulk-creative-update': typeof AuthAmazonBulkCreativeUpdateRoute
  '/amazon/campaigns': typeof AuthAmazonCampaignsRoute
  '/amazon/reports': typeof AuthAmazonReportsRoute
  '/amazon/sponsored-brands-bidding': typeof AuthAmazonSponsoredBrandsBiddingRoute
  '/amazon/sponsored-brands-creative': typeof AuthAmazonSponsoredBrandsCreativeRoute
  '/amazon/sponsored-products-bidding': typeof AuthAmazonSponsoredProductsBiddingRoute
  '/amazon/sponsored-products-creative': typeof AuthAmazonSponsoredProductsCreativeRoute
  '/amazon/sponsored-video-creative': typeof AuthAmazonSponsoredVideoCreativeRoute
  '/amazon/tasks': typeof AuthAmazonTasksRoute
  '/meta/tasks': typeof AuthMetaTasksRoute
  '/shopify/tasks': typeof AuthShopifyTasksRoute
  '/amazon': typeof AuthAmazonIndexRoute
  '/meta': typeof AuthMetaIndexRoute
  '/shopify': typeof AuthShopifyIndexRoute
  '/meta/ad-updates/create': typeof AuthMetaAdUpdatesCreateRoute
  '/shopify/pricing/create': typeof AuthShopifyPricingCreateRoute
  '/shopify/pricing/heatmap': typeof AuthShopifyPricingHeatmapRoute
  '/meta/ad-updates': typeof AuthMetaAdUpdatesIndexRoute
  '/shopify/pricing': typeof AuthShopifyPricingIndexRoute
  '/meta/ad-updates/$updateId/edit': typeof AuthMetaAdUpdatesUpdateIdEditRoute
  '/shopify/pricing/$updateId/edit': typeof AuthShopifyPricingUpdateIdEditRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_no-auth': typeof NoAuthRouteWithChildren
  '/_auth/_admin': typeof AuthAdminRoute
  '/_auth/amazon': typeof AuthAmazonRouteWithChildren
  '/_auth/meta': typeof AuthMetaRouteWithChildren
  '/_auth/shopify': typeof AuthShopifyRouteWithChildren
  '/_no-auth/forgot-password': typeof NoAuthForgotPasswordRoute
  '/_no-auth/log-in': typeof NoAuthLogInRoute
  '/_no-auth/password-reset-sent': typeof NoAuthPasswordResetSentRoute
  '/_no-auth/reset-password': typeof NoAuthResetPasswordRoute
  '/_auth/amazon/banner-video-creative': typeof AuthAmazonBannerVideoCreativeRoute
  '/_auth/amazon/bidding-update': typeof AuthAmazonBiddingUpdateRoute
  '/_auth/amazon/bulk-creative-update': typeof AuthAmazonBulkCreativeUpdateRoute
  '/_auth/amazon/campaigns': typeof AuthAmazonCampaignsRoute
  '/_auth/amazon/reports': typeof AuthAmazonReportsRoute
  '/_auth/amazon/sponsored-brands-bidding': typeof AuthAmazonSponsoredBrandsBiddingRoute
  '/_auth/amazon/sponsored-brands-creative': typeof AuthAmazonSponsoredBrandsCreativeRoute
  '/_auth/amazon/sponsored-products-bidding': typeof AuthAmazonSponsoredProductsBiddingRoute
  '/_auth/amazon/sponsored-products-creative': typeof AuthAmazonSponsoredProductsCreativeRoute
  '/_auth/amazon/sponsored-video-creative': typeof AuthAmazonSponsoredVideoCreativeRoute
  '/_auth/amazon/tasks': typeof AuthAmazonTasksRoute
  '/_auth/meta/ad-updates': typeof AuthMetaAdUpdatesRouteWithChildren
  '/_auth/meta/tasks': typeof AuthMetaTasksRoute
  '/_auth/shopify/pricing': typeof AuthShopifyPricingRouteWithChildren
  '/_auth/shopify/tasks': typeof AuthShopifyTasksRoute
  '/_auth/amazon/': typeof AuthAmazonIndexRoute
  '/_auth/meta/': typeof AuthMetaIndexRoute
  '/_auth/shopify/': typeof AuthShopifyIndexRoute
  '/_auth/meta/ad-updates/create': typeof AuthMetaAdUpdatesCreateRoute
  '/_auth/shopify/pricing/create': typeof AuthShopifyPricingCreateRoute
  '/_auth/shopify/pricing/heatmap': typeof AuthShopifyPricingHeatmapRoute
  '/_auth/meta/ad-updates/': typeof AuthMetaAdUpdatesIndexRoute
  '/_auth/shopify/pricing/': typeof AuthShopifyPricingIndexRoute
  '/_auth/meta/ad-updates/$updateId/edit': typeof AuthMetaAdUpdatesUpdateIdEditRoute
  '/_auth/shopify/pricing/$updateId/edit': typeof AuthShopifyPricingUpdateIdEditRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/amazon'
    | '/meta'
    | '/shopify'
    | '/forgot-password'
    | '/log-in'
    | '/password-reset-sent'
    | '/reset-password'
    | '/amazon/banner-video-creative'
    | '/amazon/bidding-update'
    | '/amazon/bulk-creative-update'
    | '/amazon/campaigns'
    | '/amazon/reports'
    | '/amazon/sponsored-brands-bidding'
    | '/amazon/sponsored-brands-creative'
    | '/amazon/sponsored-products-bidding'
    | '/amazon/sponsored-products-creative'
    | '/amazon/sponsored-video-creative'
    | '/amazon/tasks'
    | '/meta/ad-updates'
    | '/meta/tasks'
    | '/shopify/pricing'
    | '/shopify/tasks'
    | '/amazon/'
    | '/meta/'
    | '/shopify/'
    | '/meta/ad-updates/create'
    | '/shopify/pricing/create'
    | '/shopify/pricing/heatmap'
    | '/meta/ad-updates/'
    | '/shopify/pricing/'
    | '/meta/ad-updates/$updateId/edit'
    | '/shopify/pricing/$updateId/edit'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/forgot-password'
    | '/log-in'
    | '/password-reset-sent'
    | '/reset-password'
    | '/amazon/banner-video-creative'
    | '/amazon/bidding-update'
    | '/amazon/bulk-creative-update'
    | '/amazon/campaigns'
    | '/amazon/reports'
    | '/amazon/sponsored-brands-bidding'
    | '/amazon/sponsored-brands-creative'
    | '/amazon/sponsored-products-bidding'
    | '/amazon/sponsored-products-creative'
    | '/amazon/sponsored-video-creative'
    | '/amazon/tasks'
    | '/meta/tasks'
    | '/shopify/tasks'
    | '/amazon'
    | '/meta'
    | '/shopify'
    | '/meta/ad-updates/create'
    | '/shopify/pricing/create'
    | '/shopify/pricing/heatmap'
    | '/meta/ad-updates'
    | '/shopify/pricing'
    | '/meta/ad-updates/$updateId/edit'
    | '/shopify/pricing/$updateId/edit'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/_no-auth'
    | '/_auth/_admin'
    | '/_auth/amazon'
    | '/_auth/meta'
    | '/_auth/shopify'
    | '/_no-auth/forgot-password'
    | '/_no-auth/log-in'
    | '/_no-auth/password-reset-sent'
    | '/_no-auth/reset-password'
    | '/_auth/amazon/banner-video-creative'
    | '/_auth/amazon/bidding-update'
    | '/_auth/amazon/bulk-creative-update'
    | '/_auth/amazon/campaigns'
    | '/_auth/amazon/reports'
    | '/_auth/amazon/sponsored-brands-bidding'
    | '/_auth/amazon/sponsored-brands-creative'
    | '/_auth/amazon/sponsored-products-bidding'
    | '/_auth/amazon/sponsored-products-creative'
    | '/_auth/amazon/sponsored-video-creative'
    | '/_auth/amazon/tasks'
    | '/_auth/meta/ad-updates'
    | '/_auth/meta/tasks'
    | '/_auth/shopify/pricing'
    | '/_auth/shopify/tasks'
    | '/_auth/amazon/'
    | '/_auth/meta/'
    | '/_auth/shopify/'
    | '/_auth/meta/ad-updates/create'
    | '/_auth/shopify/pricing/create'
    | '/_auth/shopify/pricing/heatmap'
    | '/_auth/meta/ad-updates/'
    | '/_auth/shopify/pricing/'
    | '/_auth/meta/ad-updates/$updateId/edit'
    | '/_auth/shopify/pricing/$updateId/edit'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  NoAuthRoute: typeof NoAuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  NoAuthRoute: NoAuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/_no-auth"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/_admin",
        "/_auth/amazon",
        "/_auth/meta",
        "/_auth/shopify"
      ]
    },
    "/_no-auth": {
      "filePath": "_no-auth.tsx",
      "children": [
        "/_no-auth/forgot-password",
        "/_no-auth/log-in",
        "/_no-auth/password-reset-sent",
        "/_no-auth/reset-password"
      ]
    },
    "/_auth/_admin": {
      "filePath": "_auth/_admin.tsx",
      "parent": "/_auth"
    },
    "/_auth/amazon": {
      "filePath": "_auth/amazon.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/amazon/banner-video-creative",
        "/_auth/amazon/bidding-update",
        "/_auth/amazon/bulk-creative-update",
        "/_auth/amazon/campaigns",
        "/_auth/amazon/reports",
        "/_auth/amazon/sponsored-brands-bidding",
        "/_auth/amazon/sponsored-brands-creative",
        "/_auth/amazon/sponsored-products-bidding",
        "/_auth/amazon/sponsored-products-creative",
        "/_auth/amazon/sponsored-video-creative",
        "/_auth/amazon/tasks",
        "/_auth/amazon/"
      ]
    },
    "/_auth/meta": {
      "filePath": "_auth/meta.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/meta/ad-updates",
        "/_auth/meta/tasks",
        "/_auth/meta/"
      ]
    },
    "/_auth/shopify": {
      "filePath": "_auth/shopify.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/shopify/pricing",
        "/_auth/shopify/tasks",
        "/_auth/shopify/"
      ]
    },
    "/_no-auth/forgot-password": {
      "filePath": "_no-auth/forgot-password.tsx",
      "parent": "/_no-auth"
    },
    "/_no-auth/log-in": {
      "filePath": "_no-auth/log-in.tsx",
      "parent": "/_no-auth"
    },
    "/_no-auth/password-reset-sent": {
      "filePath": "_no-auth/password-reset-sent.tsx",
      "parent": "/_no-auth"
    },
    "/_no-auth/reset-password": {
      "filePath": "_no-auth/reset-password.tsx",
      "parent": "/_no-auth"
    },
    "/_auth/amazon/banner-video-creative": {
      "filePath": "_auth/amazon/banner-video-creative.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/bidding-update": {
      "filePath": "_auth/amazon/bidding-update.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/bulk-creative-update": {
      "filePath": "_auth/amazon/bulk-creative-update.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/campaigns": {
      "filePath": "_auth/amazon/campaigns.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/reports": {
      "filePath": "_auth/amazon/reports.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/sponsored-brands-bidding": {
      "filePath": "_auth/amazon/sponsored-brands-bidding.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/sponsored-brands-creative": {
      "filePath": "_auth/amazon/sponsored-brands-creative.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/sponsored-products-bidding": {
      "filePath": "_auth/amazon/sponsored-products-bidding.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/sponsored-products-creative": {
      "filePath": "_auth/amazon/sponsored-products-creative.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/sponsored-video-creative": {
      "filePath": "_auth/amazon/sponsored-video-creative.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/amazon/tasks": {
      "filePath": "_auth/amazon/tasks.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/meta/ad-updates": {
      "filePath": "_auth/meta/ad-updates.tsx",
      "parent": "/_auth/meta",
      "children": [
        "/_auth/meta/ad-updates/create",
        "/_auth/meta/ad-updates/",
        "/_auth/meta/ad-updates/$updateId/edit"
      ]
    },
    "/_auth/meta/tasks": {
      "filePath": "_auth/meta/tasks.tsx",
      "parent": "/_auth/meta"
    },
    "/_auth/shopify/pricing": {
      "filePath": "_auth/shopify/pricing.tsx",
      "parent": "/_auth/shopify",
      "children": [
        "/_auth/shopify/pricing/create",
        "/_auth/shopify/pricing/heatmap",
        "/_auth/shopify/pricing/",
        "/_auth/shopify/pricing/$updateId/edit"
      ]
    },
    "/_auth/shopify/tasks": {
      "filePath": "_auth/shopify/tasks.tsx",
      "parent": "/_auth/shopify"
    },
    "/_auth/amazon/": {
      "filePath": "_auth/amazon/index.tsx",
      "parent": "/_auth/amazon"
    },
    "/_auth/meta/": {
      "filePath": "_auth/meta/index.tsx",
      "parent": "/_auth/meta"
    },
    "/_auth/shopify/": {
      "filePath": "_auth/shopify/index.tsx",
      "parent": "/_auth/shopify"
    },
    "/_auth/meta/ad-updates/create": {
      "filePath": "_auth/meta/ad-updates/create.tsx",
      "parent": "/_auth/meta/ad-updates"
    },
    "/_auth/shopify/pricing/create": {
      "filePath": "_auth/shopify/pricing/create.tsx",
      "parent": "/_auth/shopify/pricing"
    },
    "/_auth/shopify/pricing/heatmap": {
      "filePath": "_auth/shopify/pricing/heatmap.tsx",
      "parent": "/_auth/shopify/pricing"
    },
    "/_auth/meta/ad-updates/": {
      "filePath": "_auth/meta/ad-updates/index.tsx",
      "parent": "/_auth/meta/ad-updates"
    },
    "/_auth/shopify/pricing/": {
      "filePath": "_auth/shopify/pricing/index.tsx",
      "parent": "/_auth/shopify/pricing"
    },
    "/_auth/meta/ad-updates/$updateId/edit": {
      "filePath": "_auth/meta/ad-updates/$updateId.edit.tsx",
      "parent": "/_auth/meta/ad-updates"
    },
    "/_auth/shopify/pricing/$updateId/edit": {
      "filePath": "_auth/shopify/pricing/$updateId.edit.tsx",
      "parent": "/_auth/shopify/pricing"
    }
  }
}
ROUTE_MANIFEST_END */
