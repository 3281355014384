import { redirect } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/shopify/")({
    loader: () => {
        throw redirect({
            to: "/shopify/pricing",
        });
    },
});
