import { Box } from "@mui/material";
import type { PropsWithChildren } from "react";

// biome-ignore lint/complexity/noBannedTypes:
export type ErrorMessageProps = PropsWithChildren<{
    // ...
}>;

export function ErrorMessage({ children }: ErrorMessageProps) {
    return <Box sx={{ color: "error.main" }}>{children}</Box>;
}
