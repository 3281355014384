import { Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Box,
    Breadcrumbs,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Toolbar,
    Typography,
} from "@mui/material";
import {
    Outlet,
    Link as RouterLink,
    createFileRoute,
    redirect,
    useRouter,
} from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";

import glamoriseLogo from "../assets/glamorise-logo.png";
import { useAuth } from "../auth";
import { useBreadcrumbs } from "../hooks";

export const Route = createFileRoute("/_auth")({
    component: Auth,
    beforeLoad: ({ location, context }) => {
        if (context.auth.state === "loggedOut") {
            throw redirect({
                to: "/log-in",
                search: { redirect: location.href },
            });
        }
    },
});

function Auth() {
    const auth = useAuth();
    const router = useRouter();
    const breadcrumbs = useBreadcrumbs();
    // const navigate = Route.useNavigate();

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const openDrawer = useCallback(() => {
        setDrawerIsOpen(true);
    }, []);

    const closeDrawer = useCallback(() => {
        setDrawerIsOpen(false);
    }, []);

    useEffect(() => {
        return router.subscribe("onLoad", () => {
            closeDrawer();
        });
    }, [router, closeDrawer]);

    const logOut = useCallback(async () => {
        if (auth.state === "loggedIn") {
            await auth.logOut();
            await router.invalidate();

            // not sure why this doesn't work
            // await navigate({ to: "/" });

            window.location.reload();
        }
    }, [auth, router]);

    // TODO: extract to <AuthPage> component
    return (
        <>
            {/* TODO: extract to <NavBar> component */}
            <AppBar
                position="static"
                sx={{
                    backgroundColor: "#fff",
                }}
            >
                <Toolbar sx={{ display: "flex", gap: 1 }}>
                    <IconButton edge="start" aria-label="menu" onClick={openDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Link
                            component={RouterLink}
                            to="/"
                            style={{ display: "block", height: "30px" }}
                        >
                            <img
                                src={glamoriseLogo}
                                alt="Glamorise Logo"
                                style={{ height: "100%" }}
                            />
                        </Link>
                        <Breadcrumbs
                            sx={{
                                fontSize: "1.2em",
                                fontWeight: 500,
                                transform: "translateY(8px)",
                            }}
                        >
                            {breadcrumbs.map((breadcrumb) => (
                                <Link
                                    component={RouterLink}
                                    to={breadcrumb.url}
                                    key={breadcrumb.url}
                                >
                                    {breadcrumb.name}
                                </Link>
                            ))}
                        </Breadcrumbs>
                    </Box>
                    <Drawer open={drawerIsOpen} onClose={closeDrawer}>
                        <List
                            sx={{ minWidth: 300, "& .MuiListItemButton-root": { paddingLeft: 3 } }}
                            component="nav"
                            subheader={
                                <ListSubheader sx={{ fontSize: "1rem" }} component="div">
                                    Amazon
                                </ListSubheader>
                            }
                        >
                            <ListItemButton
                                component={RouterLink}
                                to="/amazon/bulk-creative-update"
                            >
                                <Typography fontWeight={500}>Bulk Creative Update</Typography>
                            </ListItemButton>
                            <ListItemButton component={RouterLink} to="/amazon/bidding-update">
                                <Typography fontWeight={500}>Bidding Update</Typography>
                            </ListItemButton>
                            <ListItemButton component={RouterLink} to="/amazon/campaigns">
                                <Typography fontWeight={500}>Campaign History</Typography>
                            </ListItemButton>
                            <ListItemButton component={RouterLink} to="/amazon/reports">
                                <Typography fontWeight={500}>Reports</Typography>
                            </ListItemButton>
                            <ListItemButton component={RouterLink} to="/amazon/tasks">
                                <Typography fontWeight={500}>Tasks</Typography>
                            </ListItemButton>
                        </List>
                        <Divider />
                        <List
                            sx={{ minWidth: 300, "& .MuiListItemButton-root": { paddingLeft: 3 } }}
                            subheader={
                                <ListSubheader sx={{ fontSize: "1rem" }} component="div">
                                    Shopify
                                </ListSubheader>
                            }
                        >
                            <ListItemButton component={RouterLink} to="/shopify/pricing">
                                <Typography fontWeight={500}>Pricing Updates</Typography>
                            </ListItemButton>
                            <ListItemButton component={RouterLink} to="/shopify/pricing/heatmap">
                                <Typography fontWeight={500}>Pricing Heatmap</Typography>
                            </ListItemButton>
                            <ListItemButton component={RouterLink} to="/shopify/tasks">
                                <Typography fontWeight={500}>Tasks</Typography>
                            </ListItemButton>
                        </List>
                        <Divider />
                        <List
                            sx={{ minWidth: 300, "& .MuiListItemButton-root": { paddingLeft: 3 } }}
                            subheader={
                                <ListSubheader sx={{ fontSize: "1rem" }} component="div">
                                    Meta
                                </ListSubheader>
                            }
                        >
                            <ListItemButton component={RouterLink} to="/meta/ad-updates">
                                <Typography fontWeight={500}>Ad Updates</Typography>
                            </ListItemButton>
                            <ListItemButton component={RouterLink} to="/meta/tasks">
                                <Typography fontWeight={500}>Tasks</Typography>
                            </ListItemButton>
                        </List>
                        <Divider />
                        <List sx={{ minWidth: 300 }}>
                            {auth.state === "loggedIn" && (
                                <ListItemButton sx={{ paddingTop: 2 }}>
                                    <Typography fontSize={14} color="text.secondary">
                                        {auth.user.email}
                                    </Typography>
                                </ListItemButton>
                            )}
                            <ListItemButton onClick={logOut}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <Typography fontWeight={500}>Log Out</Typography>
                            </ListItemButton>
                        </List>
                    </Drawer>
                </Toolbar>
            </AppBar>
            {/* TODO: use margins on containers instead of padding here */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    paddingTop: 3,
                    paddingBottom: 3,
                }}
            >
                <Outlet />
            </Box>
        </>
    );
}
