import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { useApiClient } from "../../../../api";
import { PricingForm } from "../../../../components";

export const Route = createFileRoute("/_auth/shopify/pricing/create")({
    component: CreatePricingUpdate,
    beforeLoad: () => ({
        getTitle: () => "Create",
    }),
});

function CreatePricingUpdate() {
    const apiClient = useApiClient();
    const navigate = Route.useNavigate();

    const mutation = useMutation({
        mutationFn: apiClient.shopifyCreatePricingUpdate,
        onSuccess: async () => {
            await navigate({ to: "/shopify/pricing" });
        },
    });

    // @ts-expect-error I dunno man
    return <PricingForm mutation={mutation} />;
}
