import { z } from "zod";

const floatStringSchema = z
    .string()
    .refine(stringIsValidFloat, { message: "Value must be a number" });

const percentageStringSchema = floatStringSchema.refine((s) => stringIsInRange(s, 0, 100), {
    message: "Value must be between 0 and 100",
});

export const ENVIRONMENTS = ["production", "staging"] as const;
export const environmentSchema = z.enum(ENVIRONMENTS);
export type Environment = z.infer<typeof environmentSchema>;

export const ENVIRONMENT_LABELS: Record<Environment, string> = {
    production: "Production",
    staging: "Staging",
};

export const COUNTRY_CODES = ["AU", "CA", "DE", "UK", "US"] as const;
export const countryCodeSchema = z.enum(COUNTRY_CODES);
export type CountryCode = z.infer<typeof countryCodeSchema>;

export const ACTIVE_COUNTRY_CODES: CountryCode[] = ["US", "CA", "UK", "DE"];

export const pricingUpdatePriceTypeSchema = z.enum(["discount", "fixed", "msrp"]);
export type PricingUpdatePriceType = z.infer<typeof pricingUpdatePriceTypeSchema>;

export const pricingUpdateDiscountPriceSchema = z.object({
    type: z.literal("discount"),
    percentage: percentageStringSchema,
});
export type PricingUpdateDiscountPrice = z.infer<typeof pricingUpdateDiscountPriceSchema>;

export const pricingUpdateFixedPriceSchema = z.object({
    type: z.literal("fixed"),
    price: floatStringSchema,
});
export type PricingUpdateFixedPrice = z.infer<typeof pricingUpdateFixedPriceSchema>;

export const pricingUpdateMsrpSchema = z.object({
    type: z.literal("msrp"),
});
export type PricingUpdateMsrp = z.infer<typeof pricingUpdateMsrpSchema>;

export const pricingUpdatePriceSchema = z.union([
    pricingUpdateDiscountPriceSchema,
    pricingUpdateFixedPriceSchema,
    pricingUpdateMsrpSchema,
]);
export type PricingUpdatePrice = z.infer<typeof pricingUpdatePriceSchema>;

export const productPricesSchema = z.object({
    style: z.string(),
    color_code: z.string(),
    msrps: z.record(countryCodeSchema, z.string()),
    prices: z.record(countryCodeSchema, z.string()),
});
export type ProductPrices = z.infer<typeof productPricesSchema>;

export const pricesHeatmapDataSchema = z.object({
    prices: z.array(productPricesSchema),
    last_ingested: z.string().datetime({ offset: true }),
});
export type PricesHeatmapData = z.infer<typeof pricesHeatmapDataSchema>;

export const pricingUpdateTypeSchema = z.enum(["style", "style_color"]);
export type PricingUpdateType = z.infer<typeof pricingUpdateTypeSchema>;

export const pricingUpdateStyleRowSchema = z.object({
    type: z.literal("style"),
    countries: z.array(countryCodeSchema).min(1),
    styles: z.array(z.string()).min(1),
    colors: z.array(z.string()).length(0),
    price: pricingUpdatePriceSchema,
});
export type PricingUpdateStyleRow = z.infer<typeof pricingUpdateStyleRowSchema>;

export const pricingUpdateStyleColorRowSchema = z.object({
    type: z.literal("style_color"),
    countries: z.array(countryCodeSchema).min(1),
    styles: z.array(z.string()).min(1),
    colors: z.array(z.string()).min(1),
    price: pricingUpdatePriceSchema,
});

export const pricingUpdateRowSchema = z.union([
    pricingUpdateStyleRowSchema,
    pricingUpdateStyleColorRowSchema,
]);
export type PricingUpdateRow = z.infer<typeof pricingUpdateRowSchema>;

export const pricingUpdateStatusSchema = z.enum([
    "draft",
    "pending",
    "succeeded",
    "failed",
    "persistent",
]);
export type PricingUpdateStatus = z.infer<typeof pricingUpdateStatusSchema>;

export const refreshPricesRequestSchema = z.object({
    environment: environmentSchema,
});
export type RefreshPricesRequest = z.infer<typeof refreshPricesRequestSchema>;

export const newPricingUpdateSchema = z.object({
    name: z.string().nullish(),
    scheduled_at: z.string().datetime({ offset: true }).nullish(),
    status: pricingUpdateStatusSchema,
    theme_id: z.string().nullish(),
    environments: z.array(environmentSchema).min(1),
    rows: z.array(pricingUpdateRowSchema).min(1),
    persistent_rows: z.array(pricingUpdateRowSchema).nullish(),
});
export type NewPricingUpdate = z.infer<typeof newPricingUpdateSchema>;

export const pricingUpdateSchema = z.object({
    id: z.number().int().nullable(),
    name: z.string().nullable(),
    scheduled_at: z.string().datetime({ offset: true }).nullable(),
    status: pricingUpdateStatusSchema,
    task_id: z.string().nullable(),
    theme_id: z.string().nullable(),
    environments: z.array(environmentSchema).min(1),
    rows: z.array(pricingUpdateRowSchema).min(1),
    persistent_rows: z.array(pricingUpdateRowSchema).nullish(),
    // TODO: define these elsewhere and use on more schemas
    created_at: z.string().datetime({ offset: true }).nullable(),
    updated_at: z.string().datetime({ offset: true }).nullable(),
});
export type PricingUpdate = z.infer<typeof pricingUpdateSchema>;

export const themeSchema = z.object({
    id: z.string(),
    name: z.string(),
    role: z.string(),
});
export type Theme = z.infer<typeof themeSchema>;

function stringIsValidFloat(s: string) {
    return s.length > 0 && !Number.isNaN(Number.parseFloat(s));
}

function stringIsInRange(s: string, min: number, max: number) {
    const n = Number.parseFloat(s);
    return n >= min && n <= max;
}
