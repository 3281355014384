import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { useApiClient } from "../../../../api";
import { Loading, PricingForm } from "../../../../components";
import type { NewPricingUpdate } from "../../../../shopify/types";

export const Route = createFileRoute("/_auth/shopify/pricing/$updateId/edit")({
    component: UpdatePricingUpdate,
    beforeLoad: () => ({
        getTitle: () => "Edit",
    }),
});

function UpdatePricingUpdate() {
    const apiClient = useApiClient();
    const queryClient = useQueryClient();
    const { updateId } = Route.useParams();
    const navigate = Route.useNavigate();

    const updateQuery = useQuery({
        queryKey: ["pricing", updateId],
        queryFn: () => apiClient.shopifyGetPricingUpdate({ params: { updateId } }),
    });

    const mutation = useMutation({
        mutationFn: async (data: Partial<NewPricingUpdate>) =>
            apiClient.shopifyUpdatePricingUpdate(data, { params: { updateId } }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["pricing"] });
            await navigate({ to: "/shopify/pricing" });
        },
    });

    return updateQuery.isLoading ? (
        <Loading />
    ) : (
        <PricingForm initialData={updateQuery.data} mutation={mutation} />
    );
}
