export interface LookerStudioReportProps {
    reportId: string;
    page: string;
}

const SANDBOX_TOKENS = [
    "allow-storage-access-by-user-activation",
    "allow-scripts",
    "allow-same-origin",
    "allow-popups",
    "allow-popups-to-escape-sandbox",
];
const SANDBOX = SANDBOX_TOKENS.join(" ");

export function LookerStudioReport({ reportId, page }: LookerStudioReportProps) {
    const src = `https://lookerstudio.google.com/embed/reporting/${reportId}/page/${page}`;

    return (
        <iframe
            width="100%"
            src={src}
            style={{ border: 0, flex: 1 }}
            allowFullScreen
            sandbox={SANDBOX}
        />
    );
}
