import * as Immutable from "immutable";
import { type PropsWithChildren, useCallback, useState } from "react";

import { type Alert, AlertsContext } from "../alerts";

export type AlertsProviderProps = PropsWithChildren<{
    maxAlerts: number;
}>;

export function AlertsProvider({ children, maxAlerts }: AlertsProviderProps) {
    const [alerts, setAlerts] = useState<Immutable.List<Alert>>(Immutable.List());

    const addAlert = useCallback(
        (alert: Alert) => {
            setAlerts((alerts) => alerts.push(alert).takeLast(maxAlerts));
        },
        [maxAlerts],
    );

    const removeAlert = useCallback((index: number) => {
        setAlerts((alerts) => alerts.delete(index));
    }, []);

    const clearAlerts = useCallback(() => {
        setAlerts(Immutable.List());
    }, []);

    const handle = { alerts, addAlert, removeAlert, clearAlerts };
    return <AlertsContext.Provider value={handle}>{children}</AlertsContext.Provider>;
}
