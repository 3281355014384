import { Box, Container } from "@mui/material";
import { Outlet, createFileRoute } from "@tanstack/react-router";

import glamoriseLogo from "../assets/glamorise-logo.png";

export const Route = createFileRoute("/_no-auth")({
    component: NoAuth,
});

function NoAuth() {
    // TODO: extract to <NoAuthPage> component
    return (
        <Container maxWidth="xs" sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Box
                sx={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img src={glamoriseLogo} alt="Glamorise Logo" style={{ height: "40px" }} />
            </Box>
            <Outlet />
        </Container>
    );
}
