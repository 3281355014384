import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
    adSchema,
    adUpdateSchema,
    adUpdateStatusSchema,
    campaignSchema,
    newAdUpdateSchema,
} from "../meta/types";

export const getAdsBodySchema = z.object({
    campaign_ids: z.array(z.string()),
});

export const api = makeApi([
    {
        method: "get",
        path: "/campaigns",
        alias: "metaGetCampaigns",
        description: "Get campaigns",
        parameters: [],
        response: z.array(campaignSchema),
    },
    {
        method: "post",
        path: "/ads",
        alias: "metaGetAds",
        description: "Get ads",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: getAdsBodySchema,
            },
        ],
        response: z.array(adSchema),
    },
    {
        method: "get",
        path: "/ad-updates",
        alias: "metaGetAdUpdates",
        description: "Get ad updates",
        parameters: [
            {
                name: "statuses",
                type: "Query",
                schema: z.array(adUpdateStatusSchema).optional(),
            },
        ],
        response: z.array(adUpdateSchema),
    },
    {
        method: "get",
        path: "/ad-updates/:updateId",
        alias: "metaGetAdUpdate",
        description: "Get ad update",
        parameters: [],
        response: adUpdateSchema,
    },
    {
        method: "post",
        path: "/ad-updates",
        alias: "metaCreateAdUpdate",
        description: "Create ad update",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: newAdUpdateSchema,
            },
        ],
        response: adUpdateSchema,
    },
    {
        method: "put",
        path: "/ad-updates/:updateId",
        alias: "metaUpdateAdUpdate",
        description: "Update ad update",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: newAdUpdateSchema.partial(),
            },
        ],
        response: adUpdateSchema,
    },
    {
        method: "delete",
        path: "/ad-updates/:updateId",
        alias: "metaDeleteAdUpdate",
        description: "Delete ad update",
        response: z.unknown(),
    },
]);
