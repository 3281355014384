export const AD_THUMBNAIL_STYLE = {
    width: "100%",
    maxWidth: "600px",
    maxHeight: "600px",
    padding: "12px",
    border: "1px solid #ccc",
    borderRadius: "4px",
};

export const CARD_STYLE = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    padding: 2,
};

export const ACTIVE_CARD_STYLE = {
    ...CARD_STYLE,
    border: "3px solid",
    borderColor: "primary.main",
};
