import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
    campaignTypeSchema,
    catalogItemSchema,
    mediaAssetSchema,
    productSchema,
    salesSchema,
    styleOptionsSchema,
} from "../amazon/types";
import {
    sponsoredBrandsBiddingSchema,
    sponsoredBrandsBiddingUpdateSchema,
    sponsoredBrandsCreativeSchema,
    sponsoredBrandsCreativeUpdateSchema,
    sponsoredProductsBiddingSchema,
    sponsoredProductsBiddingUpdateSchema,
    sponsoredProductsCreativeSchema,
    sponsoredProductsCreativeUpdateSchema,
    sponsoredVideoProductDetailCreativeSchema,
    sponsoredVideoProductDetailCreativeUpdateSchema,
    sponsoredVideoStoreCreativeSchema,
    sponsoredVideoStoreCreativeUpdateSchema,
} from "./types";

export const getCampaignNamesBodySchema = z.object({
    profile_id: z.number().int(),
    campaign_type: campaignTypeSchema,
    campaign_ids: z.array(z.string()),
});

export const getSponsoredBrandsCreativeBodySchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const getSponsoredProductsCreativeBodySchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const getSponsoredVideoStoreCreativeBodySchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const getSponsoredVideoProductDetailCreativeBodySchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const getSponsoredBrandsBiddingBodySchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const getSponsoredProductsBiddingBodySchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const api = makeApi([
    {
        method: "post",
        path: "/campaigns/names",
        alias: "getCampaignNames",
        description: "Get campaign names",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: getCampaignNamesBodySchema,
            },
        ],
        response: z.record(z.string(), z.string()),
    },
    {
        method: "get",
        path: "/campaigns/search",
        alias: "searchCampaigns",
        description: "Search campaigns",
        parameters: [
            {
                name: "profile_id",
                type: "Query",
                schema: z.number().int(),
            },
            {
                name: "campaign_type",
                type: "Query",
                schema: campaignTypeSchema,
            },
            {
                name: "include_paused",
                type: "Query",
                schema: z.boolean().optional(),
            },
            {
                name: "after_date",
                type: "Query",
                schema: z.string().date().optional(),
            },
            {
                name: "search_text",
                type: "Query",
                schema: z.string().optional(),
            },
        ],
        response: z.record(z.string(), z.string()),
    },
    {
        method: "get",
        path: "/headlines",
        alias: "getHeadlines",
        description: "Get headlines",
        parameters: [
            {
                name: "profile_id",
                type: "Query",
                schema: z.number().int(),
            },
        ],
        response: z.array(z.string()),
    },
    {
        method: "get",
        path: "/logos",
        alias: "getLogos",
        description: "Get logos",
        parameters: [
            {
                name: "profile_id",
                type: "Query",
                schema: z.number().int(),
            },
        ],
        response: z.array(mediaAssetSchema),
    },
    {
        method: "get",
        path: "/backgrounds",
        alias: "getBackgrounds",
        description: "Get backgrounds",
        parameters: [
            {
                name: "profile_id",
                type: "Query",
                schema: z.number().int(),
            },
        ],
        response: z.array(mediaAssetSchema),
    },
    {
        method: "get",
        path: "/videos",
        alias: "getVideos",
        description: "Get videos",
        parameters: [
            {
                name: "profile_id",
                type: "Query",
                schema: z.number().int(),
            },
        ],
        response: z.array(mediaAssetSchema),
    },
    {
        method: "get",
        path: "/styles",
        alias: "getStyles",
        description: "Get styles",
        parameters: [],
        response: z.array(z.number().int()),
    },
    {
        method: "get",
        path: "/styles/:style/options",
        alias: "getStyleOptions",
        description: "Get style options",
        parameters: [
            {
                name: "style",
                type: "Path",
                schema: z.number().int(),
            },
        ],
        response: styleOptionsSchema,
    },
    {
        method: "get",
        path: "/products/by-style/:style",
        alias: "getProductsByStyle",
        description: "Get products by style",
        parameters: [
            {
                name: "style",
                type: "Path",
                schema: z.number().int(),
            },
        ],
        response: z.array(productSchema),
    },
    {
        method: "get",
        path: "/products/variants/:style",
        alias: "getProductVariants",
        description: "Get product variants",
        parameters: [
            {
                name: "style",
                type: "Path",
                schema: z.number().int(),
            },
        ],
        response: z.array(productSchema),
    },
    {
        method: "get",
        path: "/catalog_items/:asin",
        alias: "getCatalogItem",
        description: "Get catalog item",
        parameters: [
            {
                name: "asin",
                type: "Path",
                schema: z.string(),
            },
        ],
        response: catalogItemSchema,
    },
    {
        method: "get",
        path: "/products/search",
        alias: "searchProducts",
        description: "Search products",
        parameters: [
            {
                name: "style",
                type: "Query",
                schema: z.number().int(),
            },
            {
                name: "color",
                type: "Query",
                schema: z.string().min(1),
            },
            {
                name: "band",
                type: "Query",
                schema: z.string().min(1),
            },
            {
                name: "bust",
                type: "Query",
                schema: z.string().min(1),
            },
        ],
        response: z.array(productSchema),
    },
    {
        method: "get",
        path: "/sales",
        alias: "getSales",
        description: "Get sales",
        parameters: [
            {
                name: "profile_id",
                type: "Query",
                schema: z.number().int(),
            },
            {
                name: "start_date",
                type: "Query",
                schema: z.string().date(),
            },
            {
                name: "end_date",
                type: "Query",
                schema: z.string().date(),
            },
            {
                name: "styles",
                type: "Query",
                schema: z.array(z.number().int()),
            },
        ],
        response: z.array(salesSchema),
    },
    {
        method: "post",
        path: "/sponsored-brands/creative/list",
        alias: "getSponsoredBrandsCreative",
        description: "Get sponsored brands creative",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: getSponsoredBrandsCreativeBodySchema,
            },
        ],
        response: sponsoredBrandsCreativeSchema,
    },
    {
        method: "post",
        path: "/sponsored-brands/creative",
        alias: "updateSponsoredBrandsCreative",
        description: "Update sponsored brands creative",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: sponsoredBrandsCreativeUpdateSchema,
            },
        ],
        response: z.unknown(),
    },
    {
        method: "post",
        path: "/sponsored-products/creative/list",
        alias: "getSponsoredProductsCreative",
        description: "Get sponsored products creative",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: getSponsoredProductsCreativeBodySchema,
            },
        ],
        response: sponsoredProductsCreativeSchema,
    },
    {
        method: "post",
        path: "/sponsored-products/creative",
        alias: "updateSponsoredProductsCreative",
        description: "Update sponsored products creative",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: sponsoredProductsCreativeUpdateSchema,
            },
        ],
        response: z.unknown(),
    },
    {
        method: "post",
        path: "/sponsored-video-store/creative/list",
        alias: "getSponsoredVideoStoreCreative",
        description: "Get sponsored video store creative",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: getSponsoredVideoStoreCreativeBodySchema,
            },
        ],
        response: sponsoredVideoStoreCreativeSchema,
    },
    {
        method: "post",
        path: "/sponsored-video-store/creative",
        alias: "updateSponsoredVideoStoreCreative",
        description: "Update sponsored video store creative",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: sponsoredVideoStoreCreativeUpdateSchema,
            },
        ],
        response: z.unknown(),
    },
    {
        method: "post",
        path: "/sponsored-video-product-detail/creative/list",
        alias: "getSponsoredVideoProductDetailCreative",
        description: "Get sponsored video product detail creative",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: getSponsoredVideoProductDetailCreativeBodySchema,
            },
        ],
        response: sponsoredVideoProductDetailCreativeSchema,
    },
    {
        method: "post",
        path: "/sponsored-video-product-detail/creative",
        alias: "updateSponsoredVideoProductDetailCreative",
        description: "Update sponsored video product detail creative",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: sponsoredVideoProductDetailCreativeUpdateSchema,
            },
        ],
        response: z.unknown(),
    },
    {
        method: "post",
        path: "/sponsored-brands/bidding/get",
        alias: "getSponsoredBrandsBidding",
        description: "Get sponsored brands bidding",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: getSponsoredBrandsBiddingBodySchema,
            },
        ],
        response: sponsoredBrandsBiddingSchema,
    },
    {
        method: "post",
        path: "/sponsored-brands/bidding",
        alias: "updateSponsoredBrandsBidding",
        description: "Update sponsored brands bidding",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: sponsoredBrandsBiddingUpdateSchema,
            },
        ],
        response: z.unknown(),
    },
    {
        method: "post",
        path: "/sponsored-products/bidding/get",
        alias: "getSponsoredProductsBidding",
        description: "Get sponsored products bidding",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: getSponsoredProductsBiddingBodySchema,
            },
        ],
        response: sponsoredProductsBiddingSchema,
    },
    {
        method: "post",
        path: "/sponsored-products/bidding",
        alias: "updateSponsoredProductsBidding",
        description: "Update sponsored products bidding",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: sponsoredProductsBiddingUpdateSchema,
            },
        ],
        response: z.unknown(),
    },
]);
