import { z } from "zod";

import { campaignTypeSchema, mediaAssetSchema, productSchema } from "../amazon/types";

export const sponsoredBrandsCreativeSchema = z.object({
    logo: mediaAssetSchema.nullable(),
    background: mediaAssetSchema.nullable(),
    headline: z.string().nullable(),
    products: z.array(productSchema),
});
export type SponsoredBrandsCreative = z.infer<typeof sponsoredBrandsCreativeSchema>;

export const sponsoredBrandsCreativeUpdateSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
    headline: z.string().nullable(),
    logo_id: z.string().nullable(),
    background_id: z.string().nullable(),
    asins: z.array(z.string().nullable()).min(1),
});
export type SponsoredBrandsCreativeUpdate = z.infer<typeof sponsoredBrandsCreativeUpdateSchema>;

export const sponsoredProductsCreativeSchema = z.object({
    product: productSchema,
});
export type SponsoredProductsCreative = z.infer<typeof sponsoredProductsCreativeSchema>;

export const sponsoredProductsCreativeUpdateSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
    asin: z.string().nullable(),
});
export type SponsoredProductsCreativeUpdate = z.infer<typeof sponsoredProductsCreativeUpdateSchema>;

export const sponsoredVideoStoreCreativeSchema = z.object({
    logo: mediaAssetSchema.nullable(),
    video: mediaAssetSchema.nullable(),
    headline: z.string().nullable(),
    products: z.array(productSchema),
});
export type SponsoredVideoStoreCreative = z.infer<typeof sponsoredVideoStoreCreativeSchema>;

export const sponsoredVideoStoreCreativeUpdateSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
    campaign_type: campaignTypeSchema,
    headline: z.string().nullable(),
    logo_id: z.string().nullable(),
    video_id: z.string().nullable(),
    asins: z.array(z.string().nullable()).min(1),
});
export type SponsoredVideoStoreCreativeUpdate = z.infer<
    typeof sponsoredVideoStoreCreativeUpdateSchema
>;

export const sponsoredVideoProductDetailCreativeSchema = z.object({
    video: mediaAssetSchema.nullable(),
    product: productSchema,
});
export type SponsoredVideoProductDetailCreative = z.infer<
    typeof sponsoredVideoProductDetailCreativeSchema
>;

export const sponsoredVideoProductDetailCreativeUpdateSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
    video_id: z.string().nullable(),
    asin: z.string().nullable(),
});
export type SponsoredVideoProductDetailCreativeUpdate = z.infer<
    typeof sponsoredVideoProductDetailCreativeUpdateSchema
>;

export const sponsoredBrandsBiddingSchema = z.object({
    bid_adjustment: z.number().int(),
    keyword_bid: z.number(),
});
export type SponsoredBrandsBidding = z.infer<typeof sponsoredBrandsBiddingSchema>;

export const sponsoredBrandsBiddingUpdateSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
    bid_adjustment: z.number().int().nullable(),
    keyword_bid: z.number().nullable(),
});
export type SponsoredBrandsBiddingUpdate = z.infer<typeof sponsoredBrandsBiddingUpdateSchema>;

export const SPONSORED_PRODUCTS_BID_PLACEMENTS = [
    "PLACEMENT_TOP",
    "PLACEMENT_REST_OF_SEARCH",
    "PLACEMENT_PRODUCT_PAGE",
    "SITE_AMAZON_BUSINESS",
] as const;
export const SponsoredProductsBidPlacementSchema = z.enum(SPONSORED_PRODUCTS_BID_PLACEMENTS);
export type SponsoredProductsBidPlacement = z.infer<typeof SponsoredProductsBidPlacementSchema>;

export const SPONSORED_PRODUCTS_BID_PLACEMENTS_LABELS = {
    PLACEMENT_TOP: "Top of search",
    PLACEMENT_REST_OF_SEARCH: "Rest of search",
    PLACEMENT_PRODUCT_PAGE: "Product pages",
    SITE_AMAZON_BUSINESS: "Amazon Business",
} as const;

export const sponsoredProductsBidAdjustmentsSchema = z.record(
    SponsoredProductsBidPlacementSchema,
    z.number().int(),
);
export type SponsoredProductsBidAdjustments = z.infer<typeof sponsoredProductsBidAdjustmentsSchema>;

export const sponsoredProductsBiddingSchema = z.object({
    bid_adjustments: sponsoredProductsBidAdjustmentsSchema,
    keyword_bid: z.number(),
});
export type SponsoredProductsBidding = z.infer<typeof sponsoredProductsBiddingSchema>;

export const sponsoredProductsBiddingUpdateSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
    bid_adjustments: sponsoredProductsBidAdjustmentsSchema.nullable(),
    keyword_bid: z.number().nullable(),
});
export type SponsoredProductsBiddingUpdate = z.infer<typeof sponsoredProductsBiddingUpdateSchema>;
