import { Box, Container, Paper, Tab, Tabs } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";
import { type SyntheticEvent, useCallback, useState } from "react";
import { z } from "zod";

import { PageTitle } from "../../../components";
import { VideoProductDetailForm } from "../../../components/VideoProductDetailForm";
import { VideoStoreForm } from "../../../components/VideoStoreForm";

export type SponsoredVideoFormType = "product-detail" | "store";

const searchSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const Route = createFileRoute("/_auth/amazon/sponsored-video-creative")({
    component: SponsoredVideoCreative,
    validateSearch: (search) => searchSchema.parse(search),
    beforeLoad: () => ({
        getTitle: () => "Sponsored Video (Creative)",
    }),
    onError: (error: { routerCode: string }) => {
        if (error.routerCode === "VALIDATE_SEARCH") {
            throw redirect({
                to: "/amazon/bulk-creative-update",
                search: { campaignType: "sponsored-video" },
            });
        }
    },
});

function SponsoredVideoCreative() {
    const { profile_id, campaign_ids } = Route.useSearch();
    const [formType, setFormType] = useState<SponsoredVideoFormType>("product-detail");

    const handleChange = useCallback((event: SyntheticEvent, formType: SponsoredVideoFormType) => {
        setFormType(formType);
    }, []);

    return (
        <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Paper
                sx={{
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <Box>
                    <PageTitle />
                </Box>
                <Tabs sx={{ marginBottom: -1 }} value={formType} onChange={handleChange}>
                    <Tab value="product-detail" label="Product Detail" />
                    <Tab value="store" label="Store" />
                </Tabs>
            </Paper>
            {formType === "product-detail" && (
                <VideoProductDetailForm profile_id={profile_id} campaign_ids={campaign_ids} />
            )}
            {formType === "store" && (
                <VideoStoreForm
                    profile_id={profile_id}
                    campaign_ids={campaign_ids}
                    campaign_type="sponsored-video"
                />
            )}
        </Container>
    );
}
