import { createRouter } from "@tanstack/react-router";

import type { UseAlertsHandle } from "./alerts";
import type { UseAuthHandle } from "./auth";
import { routeTree } from "./routeTree.gen";

export interface RouterContext {
    alerts: UseAlertsHandle;
    auth: UseAuthHandle;
}

// @ts-expect-error: context values are defined at runtime
export const router = createRouter({ routeTree, context: { alerts: undefined, auth: undefined } });

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
