import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { Alert, Box, CssBaseline, Snackbar, ThemeProvider } from "@mui/material";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
// import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { useMemo } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { useAlerts } from "../alerts";
import { helmetContext } from "../helmet";
import { useRouteTitle } from "../hooks";
import type { RouterContext } from "../router";
import { theme } from "../theme";

export const Route = createRootRouteWithContext<RouterContext>()({
    component: Root,
});

function Root() {
    const routeTitle = useRouteTitle();
    const pageTitle = useMemo(() => {
        const appTitle = import.meta.env.APP_TITLE;
        return routeTitle ? `${routeTitle} | ${appTitle}` : appTitle;
    }, [routeTitle]);

    // TODO: extract to component
    const { alerts, removeAlert } = useAlerts();
    const snackbars = useMemo(
        () =>
            alerts.map((alert, index) => {
                const handleClose = () => {
                    removeAlert(index);
                };

                return (
                    <Snackbar
                        // biome-ignore lint/suspicious/noArrayIndexKey:
                        key={index}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        onClose={handleClose}
                    >
                        <Alert severity={alert.type} onClose={handleClose}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                );
            }),
        [alerts, removeAlert],
    );

    return (
        <HelmetProvider context={helmetContext}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                {/* TODO: move structural elements to <Page> component */}
                <Box
                    sx={{
                        minHeight: "100vh",
                        backgroundColor: "grey.100",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Outlet />
                    {/* <TanStackRouterDevtools /> */}
                    {/* <ReactQueryDevtools /> */}
                </Box>
                {snackbars}
            </ThemeProvider>
        </HelmetProvider>
    );
}
