import { useRouterState } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";

export interface Breadcrumb {
    name: string;
    url: string;
}

export function matchHasTitle<T extends object>(
    match: T,
): match is T & { context: { getTitle: () => string } } {
    return (
        "context" in match &&
        typeof match.context === "object" &&
        match.context !== null &&
        "getTitle" in match.context &&
        typeof match.context.getTitle === "function"
    );
}

export function useBreadcrumbs(): Breadcrumb[] {
    const matches = useRouterState({ select: (s) => s.matches });
    return useMemo(
        () =>
            matches.filter(matchHasTitle).map((match) => ({
                name: match.context.getTitle(),
                url: match.pathname,
            })),
        [matches],
    );
}

export function useRouteTitle(): string | undefined {
    const breadcrumbs = useBreadcrumbs();
    if (breadcrumbs.length === 0) {
        return undefined;
    }

    return breadcrumbs[breadcrumbs.length - 1].name;
}

type CleanupFunction = () => void;

export function useAsyncEffect(
    effect: () => Promise<CleanupFunction | undefined>,
    deps?: unknown[],
) {
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        let cleanup: CleanupFunction | undefined = undefined;

        (async () => {
            cleanup = await effect();
        })();

        return () => {
            if (cleanup) {
                cleanup();
            }
        };
    }, deps);
}
