import type { CountryCode, PricingUpdateRow } from "./types";

export const CURRENCY_SYMBOLS_BY_COUNTRY: Record<CountryCode, string> = {
    US: "$",
    CA: "$",
    UK: "£",
    DE: "€",
    AU: "$",
};

export const CURRENCY_CODES_BY_COUNTRY: Record<CountryCode, string> = {
    US: "USD",
    CA: "CAD",
    UK: "GBP",
    DE: "EUR",
    AU: "AUD",
};

export function currencySymbolForRow(row: PricingUpdateRow): string {
    const countryCode = row.countries[0]!;
    return CURRENCY_SYMBOLS_BY_COUNTRY[countryCode];
}
