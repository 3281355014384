import { Box, CircularProgress } from "@mui/material";

export interface LoadingProps {
    size?: number;
}

export function Loading({ size = 60 }: LoadingProps) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress size={size} />
        </Box>
    );
}
