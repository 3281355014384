import { z } from "zod";

export type Mutator<T> = (value: T) => T;

export const userSchema = z.object({
    id: z.number().int(),
    email: z.string().email(),
    name: z.string(),
    is_admin: z.boolean(),
});
export type User = z.infer<typeof userSchema>;

export const credentialsSchema = z.object({
    access_token: z.string(),
    refresh_token: z.string(),
});
export type Credentials = z.infer<typeof credentialsSchema>;

export const loginResponseSchema = z.intersection(
    z.object({
        user: userSchema,
    }),
    credentialsSchema,
);
export type LoginResponse = z.infer<typeof loginResponseSchema>;

export const authenticatedUserSchema = z.intersection(userSchema, credentialsSchema);
export type AuthenticatedUser = z.infer<typeof authenticatedUserSchema>;

export const taskTypeSchema = z.enum(["action", "report"]);
export type TaskType = z.infer<typeof taskTypeSchema>;

export const taskSchema = z.object({
    id: z.string(),
    type: taskTypeSchema,
    name: z.string(),
    display_name: z.string(),
    user: z.string().nullable(),
    started_at: z.string().datetime({ offset: true }),
    finished_at: z.string().datetime({ offset: true }).nullable(),
    columns: z.record(z.string(), z.string()),
    success_count: z.number().int(),
    failure_count: z.number().int(),
    successes: z.array(z.record(z.string(), z.any())).nullable(),
    failures: z.array(z.record(z.string(), z.any())).nullable(),
});
export type Task = z.infer<typeof taskSchema>;
