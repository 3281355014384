import {
    Add as AddIcon,
    Delete as DeleteIcon,
    ExpandMore as ExpandMoreIcon,
    Link as LinkIcon,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    Container,
    Grid,
    IconButton,
    Paper,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import * as Immutable from "immutable";
import { DateTime } from "luxon";
import { type SyntheticEvent, useCallback, useMemo, useState } from "react";

import { useApiClient } from "../../../../api";
import { ExpandMoreIconButton, Loading, PageTitle } from "../../../../components";
import { MetaAdTable } from "../../../../components/MetaAdTable";
import { categorizeAds, filterAds, getParameterValueSets } from "../../../../meta/ad";
import type { Ad, AdUpdate, AdUpdateStatus, Campaign } from "../../../../meta/types";
import { CARD_STYLE } from "../../../../styles";
import { formatDateTime } from "../../../../utils";

export const Route = createFileRoute("/_auth/meta/ad-updates/")({
    component: AdUpdateIndex,
    beforeLoad: () => ({
        getTitle: undefined,
    }),
});

function updateIsEditable(update: AdUpdate) {
    return update.status === "draft" || update.status === "pending";
}

function AdUpdateIndex() {
    const apiClient = useApiClient();
    const queryClient = useQueryClient();

    const [statusFilter, setStatusFilter] = useState<AdUpdateStatus>("draft");
    const [expandedDraftId, setExpandedDraftId] = useState<number | null>(null);

    const updatesQuery = useQuery({
        queryKey: ["metaAdUpdates", statusFilter],
        queryFn: () => apiClient.metaGetAdUpdates({ queries: { statuses: [statusFilter] } }),
    });
    const drafts = useMemo(() => updatesQuery.data ?? [], [updatesQuery.data]);

    const campaignsQuery = useQuery({
        queryKey: ["metaCampaigns"],
        queryFn: () => apiClient.metaGetCampaigns(),
    });

    const campaigns = useMemo(
        () => Immutable.List(campaignsQuery.data ?? []).sortBy((campaign) => campaign.name),
        [campaignsQuery.data],
    );
    const campaignsById = useMemo(
        () => Immutable.Map(campaigns.map((campaign) => [campaign.id, campaign])),
        [campaigns],
    );
    const campaignIds = useMemo(() => campaignsById.keySeq().toArray(), [campaignsById]);

    const adsQuery = useQuery({
        enabled: !!campaignIds,
        queryKey: ["metaAds", campaignIds],
        queryFn: () => apiClient.metaGetAds({ campaign_ids: campaignIds }),
    });

    const rawAds = useMemo(
        () => Immutable.List(adsQuery.data ?? []).sortBy((ad) => ad.name),
        [adsQuery.data],
    );

    const deleteMutation = useMutation({
        mutationFn: (updateId: number) =>
            apiClient.metaDeleteAdUpdate(undefined, { params: { updateId } }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["metaAdUpdates"] });
        },
    });

    const onStatusFilterChange = useCallback((event: SyntheticEvent, status: AdUpdateStatus) => {
        setStatusFilter(status);
    }, []);

    const onDelete = useCallback(
        (updateId: number) => {
            const message = "Are you sure you want to delete this draft?";
            const confirmed = window.confirm(message);
            if (confirmed) {
                deleteMutation.mutate(updateId);
            }
        },
        [deleteMutation],
    );

    const gridItems = useMemo(
        () =>
            drafts.map((update) => (
                <Grid item xs={12} key={update.id}>
                    <Card>
                        <CardHeader
                            title={
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    {update.name || <em>Untitled</em>}
                                    {update.status === "draft" && <Chip label="Draft" />}
                                    {update.status === "pending" && (
                                        <Chip label="Pending" color="info" />
                                    )}
                                    {update.status === "succeeded" && (
                                        <Link
                                            to="/meta/tasks"
                                            search={{ id: update.task_id ?? undefined }}
                                        >
                                            <Chip label="Succeeded" color="success" />
                                        </Link>
                                    )}
                                    {update.status === "failed" && (
                                        <Link
                                            to="/meta/tasks"
                                            search={{ id: update.task_id ?? undefined }}
                                        >
                                            <Chip label="Failed" color="error" />
                                        </Link>
                                    )}
                                </Box>
                            }
                            subheader={
                                updateIsEditable(update)
                                    ? update.scheduled_at &&
                                      `Scheduled for ${formatDateTime(DateTime.fromISO(update.scheduled_at))}`
                                    : `Submitted at ${formatDateTime(DateTime.fromISO(update.updated_at!))}`
                            }
                            action={
                                updateIsEditable(update) && (
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            onDelete(update.id!);
                                        }}
                                        disabled={deleteMutation.isPending}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )
                            }
                        />
                        {update.primary_text && (
                            <CardContent sx={{ paddingTop: 0, paddingBottom: 1 }}>
                                <Typography>
                                    <strong>Primary Text:</strong> {update.primary_text}
                                </Typography>
                            </CardContent>
                        )}
                        {update.headline && (
                            <CardContent sx={{ paddingTop: 0, paddingBottom: 1 }}>
                                <Typography>
                                    <strong>Headline:</strong> {update.headline}
                                </Typography>
                            </CardContent>
                        )}
                        {update.description && (
                            <CardContent sx={{ paddingTop: 0, paddingBottom: 1 }}>
                                <Typography>
                                    <strong>Description:</strong> {update.description}
                                </Typography>
                            </CardContent>
                        )}
                        {update.url_query_string && (
                            <CardContent sx={{ paddingTop: 0, paddingBottom: 1 }}>
                                <Typography>
                                    <strong>URL Parameters:</strong> {update.url_query_string}
                                </Typography>
                            </CardContent>
                        )}
                        <CardActions disableSpacing>
                            {updateIsEditable(update) && (
                                <Button
                                    variant="text"
                                    component={Link}
                                    to={`/meta/ad-updates/${update.id}/edit`}
                                >
                                    Edit
                                </Button>
                            )}
                            <ExpandMoreIconButton
                                expand={expandedDraftId === update.id}
                                onClick={() => {
                                    setExpandedDraftId((id) =>
                                        id === update.id ? null : update.id,
                                    );
                                }}
                            >
                                <ExpandMoreIcon />
                            </ExpandMoreIconButton>
                        </CardActions>
                        <Collapse
                            in={expandedDraftId === update.id}
                            timeout="auto"
                            unmountOnExit
                            sx={{
                                "& .MuiCardContent-root:last-child": {
                                    paddingBottom: 2,
                                },
                            }}
                        >
                            <CardContent sx={{ paddingTop: 0, marginTop: -2 }}>
                                <ExpandedAdTable
                                    update={update}
                                    rawAds={rawAds}
                                    campaignsById={campaignsById}
                                />
                            </CardContent>
                        </Collapse>
                    </Card>
                </Grid>
            )),
        [drafts, expandedDraftId, onDelete, deleteMutation, rawAds, campaignsById],
    );

    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{ ...CARD_STYLE, flexDirection: "row", alignItems: "center" }}>
                        <Box>
                            <PageTitle />
                        </Box>
                        <Tabs
                            sx={{ marginBottom: -1 }}
                            value={statusFilter}
                            onChange={onStatusFilterChange}
                        >
                            <Tab value="draft" label="Drafts" />
                            <Tab value="pending" label="Pending" />
                            <Tab value="succeeded" label="Succeeded" />
                            <Tab value="failed" label="Failed" />
                        </Tabs>
                        <Box sx={{ flex: 1 }} />
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            component={Link}
                            to="/meta/ad-updates/create"
                        >
                            Create
                        </Button>
                    </Paper>
                </Grid>
                {gridItems}
                {updatesQuery.isLoading && (
                    <Grid item xs={12}>
                        <Loading />
                    </Grid>
                )}
                {!updatesQuery.isLoading && updatesQuery.data?.length === 0 && (
                    <Grid item xs={12}>
                        <Paper
                            sx={{ ...CARD_STYLE, alignItems: "center", justifyContent: "center" }}
                        >
                            <Typography>No updates found</Typography>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

interface ExpandedAdTableProps {
    update: AdUpdate;
    rawAds: Immutable.List<Ad>;
    campaignsById: Immutable.Map<string, Campaign>;
}

function ExpandedAdTable({ update, rawAds, campaignsById }: ExpandedAdTableProps) {
    const { ads, standardAds } = useMemo(() => categorizeAds(rawAds), [rawAds]);
    const parameterValueSets = useMemo(
        () => getParameterValueSets(standardAds.toArray()),
        [standardAds],
    );

    const selectedAdIds = useMemo(() => Immutable.Set(update.ads), [update]);
    const filteredAds = useMemo(
        () =>
            filterAds({
                adType: update.ad_type,
                adStatus: update.ad_status ?? undefined,
                adParameters: update.ad_parameters,
                selectedCampaignIds: Immutable.Set(update.campaigns),
                ads,
            }).filter((ad) => selectedAdIds.includes(ad.id)),
        [ads, update, selectedAdIds],
    );

    return (
        <MetaAdTable
            adType={update.ad_type}
            adParameters={update.ad_parameters}
            parameterValueSets={parameterValueSets}
            filteredAds={filteredAds}
            campaignsById={campaignsById}
        />
    );
}
