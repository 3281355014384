import * as Immutable from "immutable";

import { config } from "../config";
import {
    AD_PARAMETERS,
    type Ad,
    type AdParameter,
    type AdParameterValues,
    type AdStatus,
    type AdType,
} from "./types";

// TODO: consolidate with `client/src/components/MetaAdTable.tsx` (?)
const FAILED_AD_IDS = Immutable.Set(config.meta.failed_ad_ids);

const DYNAMIC_AD_PREFIX = "Advantage+";

export interface StandardAd extends Ad {
    type: "standard";
    style: string;
    pillar: string;
    category: string;
    assetType: string;
    copy: string;
    influencerCode: string;
    reserved: string;
    timestamp: string;
    iteration: string;
}

export interface DynamicAd extends Ad {
    type: "dynamic";
}

export interface UnknownAd extends Ad {
    type: "unknown";
}

export type CategorizedAd = StandardAd | DynamicAd | UnknownAd;

export function categorizeAd(ad: Ad): CategorizedAd {
    if (ad.name.startsWith(DYNAMIC_AD_PREFIX)) {
        return {
            ...ad,
            type: "dynamic",
        };
    }

    const values = ad.name.split(" - ");
    if (values.length !== AD_PARAMETERS.length) {
        return {
            ...ad,
            type: "unknown",
        };
    }

    const [
        style,
        pillar,
        category,
        assetType,
        copy,
        influencerCode,
        reserved,
        timestamp,
        iteration,
    ] = values;
    return {
        ...ad,
        type: "standard",
        style,
        pillar,
        category,
        assetType,
        copy,
        influencerCode,
        reserved,
        timestamp,
        iteration,
    };
}

export type ParameterValueSets = Immutable.Map<AdParameter, Immutable.Set<string>>;

export function getParameterValueSets(ads: StandardAd[]): ParameterValueSets {
    let parameterValueSets: ParameterValueSets = Immutable.Map();

    for (const ad of ads) {
        for (const parameter of AD_PARAMETERS) {
            const value = ad[parameter];
            let valueSet = parameterValueSets.get(parameter, Immutable.Set<string>());
            valueSet = valueSet.add(value);
            parameterValueSets = parameterValueSets.set(parameter, valueSet);
        }
    }

    return parameterValueSets.map((valueSet) => valueSet.sort());
}

export function filterParametersFromValueSets(
    parameterValueSets: Immutable.Map<AdParameter, Immutable.Set<string>>,
): Partial<AdParameterValues> {
    const filterParameters: Partial<AdParameterValues> = {};

    for (const parameter of AD_PARAMETERS) {
        const valueSet = parameterValueSets.get(parameter)!;
        if (valueSet.size === 1) {
            const value = valueSet.first()!;
            filterParameters[parameter] = value;
        }
    }

    return filterParameters;
}

function standardAdMatchesParameters(ad: StandardAd, adParameters: Partial<AdParameterValues>) {
    return AD_PARAMETERS.every((parameter) => {
        const filterValue = adParameters[parameter];
        const adValue = ad[parameter];
        return !filterValue || filterValue === adValue;
    });
}

export interface CategorizedAds {
    ads: Immutable.List<CategorizedAd>;
    standardAds: Immutable.List<StandardAd>;
    dynamicAds: Immutable.List<DynamicAd>;
}

export function categorizeAds(rawAds: Immutable.List<Ad>): CategorizedAds {
    const ads = rawAds.map(categorizeAd);
    const adsByCategory = ads.groupBy((ad) => ad.type);
    const standardAds = adsByCategory.get(
        "standard",
        Immutable.List(),
    ) as Immutable.List<StandardAd>;
    const dynamicAds = adsByCategory.get("dynamic", Immutable.List()) as Immutable.List<DynamicAd>;
    return { ads, standardAds, dynamicAds };
}

export interface FilterAdsParams {
    adType: AdType;
    adStatus?: AdStatus | "FAILED";
    adParameters: Partial<AdParameterValues>;
    selectedCampaignIds: Immutable.Set<string>;
    ads: Immutable.List<CategorizedAd>;
}

export function filterAds({
    adType,
    adStatus,
    adParameters,
    selectedCampaignIds,
    ads,
}: FilterAdsParams) {
    return ads.filter(
        (ad) =>
            ad.type === adType &&
            (ad.type !== "standard" || standardAdMatchesParameters(ad, adParameters)) &&
            selectedCampaignIds.includes(ad.campaign_id) &&
            selectedCampaignIds.includes(ad.campaign_id) &&
            (adStatus === undefined ||
                adStatus === ad.status ||
                (adStatus === "FAILED" && FAILED_AD_IDS.has(ad.id))),
    );
}

export interface AdCopy {
    primaryText: string;
    headline: string;
    description: string;
}

export function getAdCopy(ad: Ad): AdCopy | undefined {
    const assetFeedSpec = ad.creative.asset_feed_spec;
    const objectStorySpec = ad.creative.object_story_spec;

    const bodies = assetFeedSpec?.bodies;
    const titles = assetFeedSpec?.titles;
    const descriptions = assetFeedSpec?.descriptions;

    if (bodies && titles && descriptions) {
        return {
            primaryText: bodies[0].text,
            headline: titles[0].text,
            description: descriptions[0].text,
        };
    }

    if ("link_data" in objectStorySpec) {
        return {
            primaryText: objectStorySpec.link_data.message,
            headline: objectStorySpec.link_data.name,
            description: objectStorySpec.link_data.description,
        };
    }

    if ("video_data" in objectStorySpec) {
        return {
            primaryText: objectStorySpec.video_data.message,
            headline: objectStorySpec.video_data.title,
            description: objectStorySpec.video_data.link_description,
        };
    }

    if ("template_data" in objectStorySpec) {
        return {
            primaryText: objectStorySpec.template_data.message,
            headline: objectStorySpec.template_data.name,
            description: "",
        };
    }
}
