import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import type { PropsWithChildren } from "react";

export type UpdateConfirmationModalProps = PropsWithChildren<{
    open: boolean;
    busy: boolean;
    onClose: () => void;
    onConfirm: () => void;
}>;

export function UpdateConfirmationModal({
    open,
    busy,
    onClose,
    onConfirm,
    children,
}: UpdateConfirmationModalProps) {
    return (
        <Dialog maxWidth="md" scroll="paper" open={open} onClose={onClose}>
            <DialogTitle>Confirm Updates</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onConfirm} disabled={busy}>
                    {busy ? <CircularProgress /> : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
