import { redirect } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/amazon/")({
    loader: () => {
        throw redirect({
            to: "/amazon/bulk-creative-update",
            search: { campaignType: "sponsored-brands" },
        });
    },
});
