import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useApiClient } from "../../api";
import { ErrorMessage, PageTitle } from "../../components";

const searchSchema = z.object({
    reset_token: z.string(),
});

const formSchema = z
    .object({
        password: z.string().min(8),
        repeatedPassword: z.string(),
    })
    .refine(
        (values) => {
            return values.password === values.repeatedPassword;
        },
        {
            message: "Passwords must match",
            path: ["repeatedPassword"],
        },
    );

type FormValues = z.infer<typeof formSchema>;

export const Route = createFileRoute("/_no-auth/reset-password")({
    component: ResetPassword,
    beforeLoad: () => ({
        getTitle: () => "Reset Password",
    }),
    validateSearch: (search) => searchSchema.parse(search),
});

function ResetPassword() {
    const { reset_token } = Route.useSearch();
    const navigate = Route.useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);

    const toggleShowPassword = useCallback(() => {
        setShowPassword((value) => !value);
    }, []);

    const toggleShowRepeatedPassword = useCallback(() => {
        setShowRepeatedPassword((value) => !value);
    }, []);

    const apiClient = useApiClient();

    const mutation = useMutation({
        mutationFn: apiClient.resetPassword,
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(formSchema),
    });

    const onSubmit = useCallback(
        async ({ password }: FormValues) => {
            await mutation.mutateAsync({
                reset_token,
                new_password: password,
            });

            await navigate({ to: "/log-in" });
        },
        [mutation, reset_token, navigate],
    );

    const busy = mutation.isPending;

    return (
        <Paper sx={{ padding: 2 }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <PageTitle />
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    sx={{ marginTop: 2 }}
                >
                    <FormControl fullWidth>
                        <Controller
                            control={control}
                            name="password"
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    required
                                    autoFocus
                                    error={errors.password !== undefined}
                                    helperText={errors.password?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowPassword}
                                                    onMouseDown={toggleShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                        <Controller
                            control={control}
                            name="repeatedPassword"
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Confirm Password"
                                    type={showRepeatedPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    required
                                    error={errors.repeatedPassword !== undefined}
                                    helperText={errors.repeatedPassword?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowRepeatedPassword}
                                                    onMouseDown={toggleShowRepeatedPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        disabled={busy}
                    >
                        {busy ? <CircularProgress /> : <span>Reset Password</span>}
                    </Button>
                    {mutation.error && <ErrorMessage>{mutation.error.message}</ErrorMessage>}
                </Box>
            </Box>
        </Paper>
    );
}
