import { createFileRoute } from "@tanstack/react-router";
import { Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/meta/ad-updates")({
    component: AdUpdates,
    beforeLoad: () => ({
        getTitle: () => "Ad Updates",
    }),
});

function AdUpdates() {
    return <Outlet />;
}
