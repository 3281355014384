import { z } from "zod";

export const adUpdateStatusSchema = z.enum(["draft", "pending", "succeeded", "failed"]);
export type AdUpdateStatus = z.infer<typeof adUpdateStatusSchema>;

export const campaignSchema = z.object({
    id: z.string(),
    name: z.string(),
    status: z.string(),
});
export type Campaign = z.infer<typeof campaignSchema>;

export const adTypeSchema = z.enum(["standard", "dynamic", "unknown"]);
export type AdType = z.infer<typeof adTypeSchema>;

export const adStatusSchema = z.enum(["ACTIVE", "PAUSED", "DELETED", "ARCHIVED"]);
export type AdStatus = z.infer<typeof adStatusSchema>;

export const adCreativeLinkDataSchema = z.object({
    link: z.string(),
    message: z.string(),
    name: z.string(),
    description: z.string(),
});
export type AdCreativeLinkData = z.infer<typeof adCreativeLinkDataSchema>;

export const adCreativeVideoDataSchema = z.object({
    video_id: z.string(),
    title: z.string(),
    message: z.string(),
    link_description: z.string(),
});
export type AdCreativeVideoData = z.infer<typeof adCreativeVideoDataSchema>;

export const adCreativePhotoDataSchema = z.object({});
export type AdCreativePhotoData = z.infer<typeof adCreativePhotoDataSchema>;

export const adCreativeTextDataSchema = z.object({});
export type AdCreativeTextData = z.infer<typeof adCreativeTextDataSchema>;

export const adCreativeTemplateDataSchema = z.object({
    link: z.string(),
    message: z.string(),
    name: z.string(),
});
export type AdCreativeTemplateData = z.infer<typeof adCreativeTemplateDataSchema>;

export const adCreativeObjectStorySpecBaseSchema = z.object({
    page_id: z.string(),
    instagram_actor_id: z.string(),
});
export type AdCreativeObjectStorySpecBase = z.infer<typeof adCreativeObjectStorySpecBaseSchema>;

export const adCreativeObjectStorySpecWithLinkSchema = adCreativeObjectStorySpecBaseSchema.extend({
    link_data: adCreativeLinkDataSchema,
});
export type AdCreativeObjectStorySpecWithLink = z.infer<
    typeof adCreativeObjectStorySpecWithLinkSchema
>;

export const adCreativeObjectStorySpecWithVideoSchema = adCreativeObjectStorySpecBaseSchema.extend({
    video_data: adCreativeVideoDataSchema,
});
export type AdCreativeObjectStorySpecWithVideo = z.infer<
    typeof adCreativeObjectStorySpecWithVideoSchema
>;

export const adCreativeObjectStorySpecWithPhotoSchema = adCreativeObjectStorySpecBaseSchema.extend({
    photo_data: adCreativePhotoDataSchema,
});
export type AdCreativeObjectStorySpecWithPhoto = z.infer<
    typeof adCreativeObjectStorySpecWithPhotoSchema
>;

export const adCreativeObjectStorySpecWithTextSchema = adCreativeObjectStorySpecBaseSchema.extend({
    text_data: adCreativeTextDataSchema,
});
export type AdCreativeObjectStorySpecWithText = z.infer<
    typeof adCreativeObjectStorySpecWithTextSchema
>;

export const adCreativeObjectStorySpecWithTemplateSchema =
    adCreativeObjectStorySpecBaseSchema.extend({
        template_data: adCreativeTemplateDataSchema,
    });
export type AdCreativeObjectStorySpecWithTemplate = z.infer<
    typeof adCreativeObjectStorySpecWithTemplateSchema
>;

export const adCreativeObjectStorySpecEmptySchema = adCreativeObjectStorySpecBaseSchema;
export type AdCreativeObjectStorySpecEmpty = z.infer<typeof adCreativeObjectStorySpecEmptySchema>;

const textFieldSchema = z.object({
    text: z.string(),
});
export type TextField = z.infer<typeof textFieldSchema>;

const assetFeedSpecSchema = z.object({
    bodies: z.array(textFieldSchema).nullable(),
    titles: z.array(textFieldSchema).nullable(),
    descriptions: z.array(textFieldSchema).nullable(),
});
export type AssetFeedSpec = z.infer<typeof assetFeedSpecSchema>;

export const adCreativeSchema = z.object({
    id: z.string(),
    url_tags: z.string().nullable(),
    asset_feed_spec: assetFeedSpecSchema.nullable(),
    object_story_spec: z.union([
        adCreativeObjectStorySpecWithLinkSchema,
        adCreativeObjectStorySpecWithVideoSchema,
        adCreativeObjectStorySpecWithPhotoSchema,
        adCreativeObjectStorySpecWithTextSchema,
        adCreativeObjectStorySpecWithTemplateSchema,
        adCreativeObjectStorySpecEmptySchema,
    ]),
});
export type AdCreative = z.infer<typeof adCreativeSchema>;

export const adSchema = z.object({
    id: z.string(),
    name: z.string(),
    status: adStatusSchema,
    campaign_id: z.string(),
    creative: adCreativeSchema,
});
export type Ad = z.infer<typeof adSchema>;

export const AD_PARAMETERS = [
    "style",
    "pillar",
    "category",
    "assetType",
    "copy",
    "influencerCode",
    "reserved",
    "timestamp",
    "iteration",
] as const;

export const adParameterSchema = z.enum(AD_PARAMETERS);
export type AdParameter = z.infer<typeof adParameterSchema>;

export const adParameterValuesSchema = z.object({
    style: z.string(),
    pillar: z.string(),
    category: z.string(),
    assetType: z.string(),
    copy: z.string(),
    influencerCode: z.string(),
    reserved: z.string(),
    timestamp: z.string(),
    iteration: z.string(),
});
export type AdParameterValues = z.infer<typeof adParameterValuesSchema>;

export const adUpdateSchema = z.object({
    id: z.number().int().nullable(),
    name: z.string().nullish(),
    scheduled_at: z.string().datetime({ offset: true }).nullable(),
    status: adUpdateStatusSchema,
    task_id: z.string().uuid().nullable(),
    primary_text: z.string(),
    headline: z.string(),
    description: z.string(),
    url_query_string: z.string(),
    campaigns: z.array(z.string()),
    ads: z.array(z.string()),
    ad_status: adStatusSchema.nullable(),
    ad_type: adTypeSchema,
    ad_parameters: adParameterValuesSchema.partial(),
    // TODO: define these elsewhere and use on more schemas
    created_at: z.string().datetime({ offset: true }).nullable(),
    updated_at: z.string().datetime({ offset: true }).nullable(),
});
export type AdUpdate = z.infer<typeof adUpdateSchema>;

export const newAdUpdateSchema = z.object({
    name: z.string().nullish(),
    scheduled_at: z.string().datetime({ offset: true }).nullish(),
    status: adUpdateStatusSchema,
    primary_text: z.string(),
    headline: z.string(),
    description: z.string(),
    url_query_string: z.string(),
    campaigns: z.array(z.string()),
    ads: z.array(z.string()).min(1),
    ad_status: adStatusSchema.nullish(),
    ad_type: adTypeSchema,
    ad_parameters: adParameterValuesSchema.partial(),
});
export type NewAdUpdate = z.infer<typeof newAdUpdateSchema>;
