import { createFileRoute } from "@tanstack/react-router";
import { Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/shopify")({
    component: Shopify,
    beforeLoad: () => ({
        getTitle: () => "Shopify",
    }),
});

function Shopify() {
    return <Outlet />;
}
