import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            light: "#5dc9d6",
            main: "#286363",
            dark: "#132d33",
            contrastText: "#ffffff",
        },
        secondary: {
            light: "#e67992",
            main: "#b34a5f",
            dark: "#763c4f",
            contrastText: "#ffffff",
        },
    },
});
