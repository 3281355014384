import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { type UseMutationResult, useMutation, useQuery } from "@tanstack/react-query";
import * as Immutable from "immutable";
import { DateTime } from "luxon";
import { type FormEvent, useCallback, useEffect, useMemo, useState } from "react";

import { useApiClient } from "../api";
import {
    ENVIRONMENTS,
    ENVIRONMENT_LABELS,
    type Environment,
    type NewPricingUpdate,
    type PricingUpdate,
    type PricingUpdateRow,
    newPricingUpdateSchema,
} from "../shopify/types";
import { CARD_STYLE } from "../styles";
import { ensureArray, randomId } from "../utils";
import { ErrorMessage } from "./ErrorMessage";
import { PricingFormTable } from "./PricingFormTable";

const ACTIVE_THEME_ROLE = "MAIN";

export interface PricingFormProps {
    initialData?: PricingUpdate;
    mutation: UseMutationResult<PricingUpdate, Error, Partial<NewPricingUpdate>>;
}

export function PricingForm({ initialData, mutation }: PricingFormProps) {
    const apiClient = useApiClient();

    const [rows, setRows] = useState(() => rowMapFromUpdate(initialData));
    const [persistentRows, setPersistentRows] = useState(rowMapFromUpdate());
    const [persistentRowsExpanded, setPersistentRowsExpanded] = useState(true);

    const [scheduledAt, setScheduledAt] = useState<DateTime | null>(
        initialData?.scheduled_at ? DateTime.fromISO(initialData.scheduled_at) : null,
    );
    const [draft, setDraft] = useState(initialData ? initialData.status === "draft" : true);
    const [name, setName] = useState(initialData?.name ?? "");
    const [themeId, setThemeId] = useState<string | undefined>(initialData?.theme_id ?? undefined);
    const [environments, setEnvironments] = useState<Environment[]>(
        initialData?.environments ?? ["production"],
    );

    const styleColorsQuery = useQuery({
        queryKey: ["styleColors"],
        queryFn: () => apiClient.shopifyGetStyleColors(),
    });
    const themesQuery = useQuery({
        queryKey: ["themes"],
        queryFn: () => apiClient.shopifyGetThemes(),
    });
    const persistentUpdateQuery = useQuery({
        queryKey: ["persistentUpdate"],
        queryFn: () => apiClient.shopifyGetPersistentPricingUpdate(),
    });

    const persistentUpdateMutation = useMutation({
        mutationFn: (rows: PricingUpdateRow[]) =>
            apiClient.shopifyUpdatePersistentPricingUpdate({ rows }),
    });

    const styleColors = useMemo(() => styleColorsQuery.data ?? {}, [styleColorsQuery.data]);
    const themes = useMemo(() => themesQuery.data ?? [], [themesQuery.data]);
    const persistentUpdate = persistentUpdateQuery.data;

    useEffect(() => {
        if (persistentUpdate) {
            setPersistentRows(rowMapFromUpdate(persistentUpdate));
        }
    }, [persistentUpdate]);

    const updateResult = useMemo(
        () =>
            newPricingUpdateSchema.safeParse({
                name,
                scheduled_at: scheduledAt?.toISO(),
                status: draft ? "draft" : "pending",
                theme_id: themeId || undefined,
                environments,
                rows: rows.valueSeq().toArray(),
            }),
        [draft, name, scheduledAt, themeId, environments, rows],
    );
    const updateErrors = useMemo(() => updateResult.error?.format(), [updateResult]);

    const persistentUpdateResult = useMemo(
        () =>
            newPricingUpdateSchema.safeParse({
                name: "",
                scheduled_at: null,
                status: "pending",
                theme_id: undefined,
                environments: ["production"],
                rows: persistentRows.valueSeq().toArray(),
            }),
        [persistentRows],
    );
    const persistentUpdateErrors = useMemo(
        () => persistentUpdateResult.error?.format(),
        [persistentUpdateResult],
    );

    const valid = updateErrors === undefined;
    const persistentSectionIsValid = persistentUpdateErrors === undefined;
    const busy = styleColorsQuery.isLoading || mutation.isPending;

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (updateResult.success) {
                await persistentUpdateMutation.mutateAsync(persistentRows.valueSeq().toArray());
                await mutation.mutateAsync(updateResult.data);
            }
        },
        [updateResult, persistentUpdateMutation, persistentRows, mutation],
    );

    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <pre>{JSON.stringify(persistentUpdateQuery, null, 2)}</pre>
                </Grid> */}
                <Grid item xs={12}>
                    <Paper sx={CARD_STYLE}>
                        <PricingFormTable
                            styleColors={styleColors}
                            rows={rows}
                            errors={updateErrors?.rows}
                            onChange={setRows}
                            infoText="Rows are processed top to bottom, i.e. style/color rows take precedence over style rows."
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={CARD_STYLE}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <Typography variant="h6">Final Sale</Typography>
                            <Button
                                variant="text"
                                onClick={() => setPersistentRowsExpanded(!persistentRowsExpanded)}
                            >
                                {persistentRowsExpanded ? "Hide" : "Show"}
                            </Button>
                            <Box sx={{ flexGrow: 1 }} />
                            {persistentRowsExpanded && (
                                <Button
                                    variant="contained"
                                    disabled={
                                        persistentUpdateMutation.isPending ||
                                        !persistentSectionIsValid
                                    }
                                    onClick={() =>
                                        persistentUpdateMutation.mutateAsync(
                                            persistentRows.valueSeq().toArray(),
                                        )
                                    }
                                >
                                    Save
                                </Button>
                            )}
                        </Box>
                        {persistentRowsExpanded && (
                            <PricingFormTable
                                styleColors={styleColors}
                                rows={persistentRows}
                                errors={persistentUpdateErrors?.rows}
                                onChange={setPersistentRows}
                            />
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper component="form" onSubmit={onSubmit} sx={CARD_STYLE}>
                        <Box
                            sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 2 }}
                        >
                            <FormControl sx={{ minWidth: "260px" }}>
                                <TextField
                                    label="Name"
                                    value={name}
                                    onChange={(event) => {
                                        setName(event.target.value);
                                    }}
                                />
                            </FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={draft}
                                        onChange={(event) => {
                                            setDraft(event.target.checked);
                                        }}
                                    />
                                }
                                label="Draft"
                            />
                            <FormControl sx={{ minWidth: "260px" }}>
                                <DateTimePicker
                                    label="Scheduled at"
                                    value={scheduledAt}
                                    onChange={setScheduledAt}
                                    slotProps={{
                                        textField: {
                                            // @ts-expect-error: typings don't know about `clearable` (?)
                                            clearable: true,
                                        },
                                    }}
                                />
                            </FormControl>
                            <FormControl sx={{ minWidth: "200px" }}>
                                <Select
                                    fullWidth
                                    multiple
                                    value={environments}
                                    onChange={(event) => {
                                        const environments = ensureArray(
                                            event.target.value as Environment | Environment[],
                                        );
                                        setEnvironments(environments);
                                    }}
                                >
                                    {ENVIRONMENTS.map((environment) => (
                                        <MenuItem key={environment} value={environment}>
                                            {ENVIRONMENT_LABELS[environment]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: "350px" }}>
                                <InputLabel id="theme-select-label">Theme</InputLabel>
                                <Select
                                    label="Theme"
                                    labelId="theme-select-label"
                                    value={themeId ?? ""}
                                    onChange={(event) => {
                                        setThemeId(event.target.value);
                                    }}
                                >
                                    <MenuItem key={""} value={""}>
                                        <em>No change</em>
                                    </MenuItem>
                                    {themes.map((theme) => (
                                        <MenuItem
                                            key={theme.id}
                                            value={theme.id}
                                            sx={{
                                                fontWeight:
                                                    theme.role === ACTIVE_THEME_ROLE
                                                        ? "bold"
                                                        : "normal",
                                            }}
                                        >
                                            {theme.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {mutation.error && (
                                <ErrorMessage>{mutation.error.message}</ErrorMessage>
                            )}
                            <Box sx={{ flexGrow: 1 }} />
                            <Button type="submit" variant="contained" disabled={!valid || busy}>
                                Submit
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

function rowMapFromUpdate(update?: PricingUpdate): Immutable.OrderedMap<string, PricingUpdateRow> {
    return Immutable.OrderedMap((update?.rows ?? []).map((row) => [randomId(), row]));
}
