import { createFileRoute } from "@tanstack/react-router";

import { useCallback } from "react";
import { z } from "zod";
import { TaskListPage } from "../../../components";

const taskPageSearchSchema = z.object({
    id: z.string().optional(),
});

export const Route = createFileRoute("/_auth/shopify/tasks")({
    component: ShopifyTasks,
    beforeLoad: () => ({
        getTitle: () => "Tasks",
    }),
    validateSearch: (search) => taskPageSearchSchema.parse(search),
});

function ShopifyTasks() {
    const { id } = Route.useSearch();
    const navigate = Route.useNavigate();

    const setTaskId = useCallback(
        (id: string | undefined) => {
            navigate({
                search: {
                    id,
                },
            });
        },
        [navigate],
    );

    return <TaskListPage name="shopify" selectedTaskId={id} onSelectedTaskIdChange={setTaskId} />;
}
