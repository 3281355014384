import { Box, CircularProgress } from "@mui/material";
import { type PropsWithChildren, useCallback, useMemo, useState } from "react";

import { API_CLIENT_NO_AUTH } from "../api";
import {
    type Auth,
    AuthContext,
    type LogOutOptions,
    type UseAuthHandle,
    getStoredUser,
    setStoredUser,
} from "../auth";
import { useAsyncEffect } from "../hooks";
import { useLocalStorage } from "../storage";
import type { AuthenticatedUser, Credentials } from "../types";

// biome-ignore lint/complexity/noBannedTypes:
export type AuthProviderProps = PropsWithChildren<{
    // ...
}>;

export function AuthProvider({ children }: AuthProviderProps) {
    const storage = useLocalStorage();
    const [auth, setAuth] = useState<Auth>({ state: "loading" });

    const logOut = useCallback(
        async (options?: LogOutOptions) => {
            if (auth.state !== "loggedIn") {
                return;
            }

            const accessToken = auth.user.access_token;
            const refreshToken = auth.user.refresh_token;

            const callApi = options?.callApi ?? true;
            if (callApi) {
                try {
                    await API_CLIENT_NO_AUTH.logOut(
                        { refresh_token: refreshToken },
                        { headers: { Authorization: `Bearer ${accessToken}` } },
                    );
                } catch (error) {
                    console.error(error);
                }
            }

            await setStoredUser(storage, null);
            setAuth({ state: "loggedOut" });
        },
        [storage, auth],
    );

    const logIn = useCallback(
        async (user: AuthenticatedUser, remember: boolean) => {
            if (remember) {
                await setStoredUser(storage, user);
            }

            setAuth({ state: "loggedIn", user, remember });
        },
        [storage],
    );

    const refresh = useCallback(
        async (credentials: Credentials) => {
            if (auth.state !== "loggedIn") {
                return;
            }

            const { remember } = auth;
            const user = { ...auth.user, ...credentials };

            if (remember) {
                await setStoredUser(storage, user);
            }

            setAuth({
                state: "loggedIn",
                user,
                remember: auth.remember,
            });
        },
        [storage, auth],
    );

    useAsyncEffect(async () => {
        const user = await getStoredUser(storage);
        if (user) {
            setAuth({ state: "loggedIn", user, remember: true });
        } else {
            setAuth({ state: "loggedOut" });
        }

        return undefined;
    }, [storage]);

    const authWithCallbacks: UseAuthHandle = useMemo(() => {
        if (auth.state === "loggedIn") {
            return { ...auth, logOut, refresh };
        }

        if (auth.state === "loggedOut") {
            return { ...auth, logIn };
        }

        return auth;
    }, [auth, logIn, logOut, refresh]);

    if (auth.state === "loading") {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress size={60} />
            </Box>
        );
    }

    return <AuthContext.Provider value={authWithCallbacks}>{children}</AuthContext.Provider>;
}
