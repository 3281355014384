import { z } from "zod";

export const CAMPAIGN_TYPES = [
    "sponsored-brands",
    "sponsored-products",
    "sponsored-video",
    "banner-video",
    "sponsored-display",
] as const;
export const campaignTypeSchema = z.enum(CAMPAIGN_TYPES);
export type CampaignType = z.infer<typeof campaignTypeSchema>;

export const ACTIVE_CAMPAIGN_TYPES: CampaignType[] = [
    "sponsored-brands",
    "sponsored-products",
    "sponsored-video",
    "banner-video",
];

export const COUNTRY_CODES = ["US", "CA", "DE", "UK", "FR"] as const;
export const countryCodeSchema = z.enum(COUNTRY_CODES);
export type CountryCode = z.infer<typeof countryCodeSchema>;

export const profileSchema = z.object({
    id: z.number().int(),
    country_code: countryCodeSchema,
    timezone: z.string(),
});
export type Profile = z.infer<typeof profileSchema>;

export const mediaAssetSchema = z.object({
    id: z.string(),
    url: z.string(),
    name: z.string(),
});
export type MediaAsset = z.infer<typeof mediaAssetSchema>;

export const salesSchema = z.object({
    style: z.number().int(),
    style_color: z.string(),
    order_revenue: z.number().int(),
});
export type Sales = z.infer<typeof salesSchema>;

export const productSchema = z.object({
    asin: z.string(),
    sku: z.string(),
    style: z.number().int(),
    color: z.string(),
    band: z.string(),
    bust: z.string(),
});
export type Product = z.infer<typeof productSchema>;

export const styleOptionsSchema = z.object({
    colors: z.array(z.string()),
    bands: z.array(z.string()),
    busts: z.array(z.string()),
});
export type StyleOptions = z.infer<typeof styleOptionsSchema>;

export const itemImageSchema = z.object({
    variant: z.string(),
    link: z.string(),
    height: z.number().int(),
    width: z.number().int(),
});
export type ItemImage = z.infer<typeof itemImageSchema>;

export const itemImagesByMarketplaceSchema = z.object({
    marketplaceId: z.string(),
    images: z.array(itemImageSchema),
});
export type ItemImagesByMarketplace = z.infer<typeof itemImagesByMarketplaceSchema>;

export const catalogItemSchema = z.object({
    asin: z.string(),
    images: z.array(itemImagesByMarketplaceSchema).nullable(),
});
export type CatalogItem = z.infer<typeof catalogItemSchema>;

export interface SelectedProductLoading {
    state: "loading";
    style: number;
    color?: string;
    band?: string;
    bust?: string;
    options?: StyleOptions;
}

export type SelectedProductLoaded = {
    state: "loaded";
    options?: StyleOptions;
} & Product;

export type SelectedProductDynamic = SelectedProductLoading | SelectedProductLoaded;

export type KeywordBidType = "relative" | "absolute";
