import type Immutable from "immutable";
import { createContext, useContext } from "react";

export type AlertType = "success" | "info" | "warning" | "error";

export interface Alert {
    type: AlertType;
    message: string;
}

export interface UseAlertsParams {
    maxAlerts: number;
}

export interface UseAlertsHandle {
    alerts: Immutable.List<Alert>;
    addAlert: (alert: Alert) => void;
    removeAlert: (index: number) => void;
    clearAlerts: () => void;
}

export const AlertsContext = createContext<UseAlertsHandle | null>(null);

export function useAlerts(): UseAlertsHandle {
    const context = useContext(AlertsContext);
    if (!context) {
        throw new Error("useAlerts must be used within an AlertsProvider");
    }

    return context;
}
