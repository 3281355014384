import * as Immutable from "immutable";
import TrieSearch from "trie-search";

import type { Task } from "./types";

export const TASK_INDEX_FIELDS: (keyof Task)[] = ["display_name"];
export const RESULT_INDEX_FIELDS = ["campaign_name", "ad_group_name", "ad_name", "sku", "details"];

export function createTaskIndex(tasks: Iterable<Task>): TrieSearch<Task> {
    const trie = new TrieSearch<Task>(null, {
        indexField: "id",
    });

    const addKey = (key: unknown, task: Task) => {
        if (typeof key === "string" && key.length > 0) {
            trie.map(key, task);
        }
    };

    for (const task of tasks) {
        for (const field of TASK_INDEX_FIELDS) {
            const key = task[field];
            addKey(key, task);
        }

        const results = Immutable.Seq(task.successes ?? []).concat(task.failures ?? []);
        for (const result of results) {
            for (const field of RESULT_INDEX_FIELDS) {
                const key = result[field] as unknown;
                addKey(key, task);
            }
        }
    }

    return trie;
}
