import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { Settings as LuxonSettings } from "luxon";

import { useAlerts } from "./alerts";
import { useAuth } from "./auth";
import { AlertsProvider, AuthProvider } from "./components";
import { queryClient } from "./query";
import { router } from "./router";

const SENTRY_DSN = import.meta.env.SENTRY_DSN;

// TODO: define in config
const DEFAULT_TIME_ZONE = "America/New_York";

const MAX_ALERTS = 1;

LuxonSettings.defaultZone = DEFAULT_TIME_ZONE;
Sentry.init({
    dsn: SENTRY_DSN,
});

export function App() {
    return (
        <AuthProvider>
            <AlertsProvider maxAlerts={MAX_ALERTS}>
                <AppWithAuth />
            </AlertsProvider>
        </AuthProvider>
    );
}

function AppWithAuth() {
    const alerts = useAlerts();
    const auth = useAuth();

    return (
        <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <RouterProvider router={router} context={{ alerts, auth }} />
            </LocalizationProvider>
        </QueryClientProvider>
    );
}
