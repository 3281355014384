import { DateTime } from "luxon";

export async function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function formatDate(date: DateTime): string {
    return date.toLocaleString(DateTime.DATE_SHORT);
}

export function formatDateTime(date: DateTime): string {
    return date.toLocaleString(DateTime.DATETIME_SHORT);
}

export function pluralize(count: number, singular: string, plural?: string): string {
    if (count === 1) {
        return singular;
    }

    if (plural === undefined) {
        return `${singular}s`;
    }

    return plural;
}

export function randomId(): string {
    return Math.random().toString(36).substring(2);
}

export function truncateId(id: string, length = 8): string {
    return id.slice(0, length);
}

export function ensureArray<T>(value: T | T[]): T[] {
    return Array.isArray(value) ? value : [value];
}

export function ensureNumber(value: string): number | undefined {
    const parsed = Number.parseFloat(value);
    return Number.isNaN(parsed) ? undefined : parsed;
}

export function round(value: number, precision: number): number {
    const scale = 10 ** precision;
    return Math.round(value * scale) / scale;
}
