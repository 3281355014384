import type { CampaignType } from "./types";

export const CAMPAIGN_TYPE_LABELS: Record<CampaignType, string> = {
    "sponsored-brands": "Sponsored Brands (SB)",
    "sponsored-video": "Sponsored Video (SV)",
    "banner-video": "Banner Video (BV)",
    "sponsored-products": "Sponsored Products (SP)",
    "sponsored-display": "Sponsored Display (SD)",
};

export const CAMPAIGN_TYPE_CREATIVE_UPDATE_ROUTES: Record<CampaignType, string> = {
    "sponsored-brands": "/amazon/sponsored-brands-creative",
    "sponsored-video": "/amazon/sponsored-video-creative",
    "banner-video": "/amazon/banner-video-creative",
    "sponsored-products": "/amazon/sponsored-products-creative",
    "sponsored-display": "/amazon/sponsored-display-creative",
};

export const CAMPAIGN_TYPE_BIDDING_UPDATE_ROUTES: Record<CampaignType, string> = {
    "sponsored-brands": "/amazon/sponsored-brands-bidding",
    "sponsored-video": "/amazon/sponsored-brands-bidding",
    "banner-video": "/amazon/sponsored-brands-bidding",
    "sponsored-products": "/amazon/sponsored-products-bidding",
    "sponsored-display": "/amazon/sponsored-brands-bidding",
};

export const CAMPAIGN_TYPE_BY_PREFIX: Record<string, CampaignType> = {
    SB: "sponsored-brands",
    SP: "sponsored-products",
    SV: "sponsored-video",
    BV: "banner-video",
};

export const STYLE_COUNT_BY_PREFIX: Record<string, number> = {
    SB: 3,
    SP: 1,
    SV: 1,
    BV: 3,
};

export const getCampaignTypeFromCampaignName = (campaignName: string): CampaignType | undefined => {
    const [prefix] = campaignName.split(" ");
    return CAMPAIGN_TYPE_BY_PREFIX[prefix] as CampaignType | undefined;
};

export const getStylesFromCampaignName = (campaignName: string): number[] | undefined => {
    const [prefix, ...otherParts] = campaignName.split(" ");
    const styleCount = STYLE_COUNT_BY_PREFIX[prefix] as number | undefined;
    if (styleCount === undefined) {
        return undefined;
    }

    const styleStrings = otherParts.slice(0, styleCount);
    const styles = styleStrings.map((styleString) => Number.parseInt(styleString));
    if (styles.length === styleCount && styles.every((style) => !Number.isNaN(style))) {
        return styles;
    }

    return undefined;
};

export const STYLE_ANY = 0;
export const STYLE_NONE = -1;

export type StyleGroup = number[];

export function intersectStyles(styleA: number, styleB: number): number {
    if (styleA === STYLE_ANY) {
        return styleB;
    }

    if (styleB === STYLE_ANY) {
        return styleA;
    }

    return styleA === styleB ? styleA : STYLE_NONE;
}

export function getCommonStyles(styleGroups: StyleGroup[]): StyleGroup {
    const commonStyles: StyleGroup = [];

    for (const styleGroup of styleGroups) {
        for (let i = 0; i < styleGroup.length; i++) {
            const style = styleGroup[i];
            commonStyles[i] = intersectStyles(commonStyles[i] ?? STYLE_ANY, style);
        }
    }

    return commonStyles;
}

export function styleGroupsHaveOverlap(groupA: StyleGroup, groupB: StyleGroup): boolean {
    const commonStyles = getCommonStyles([groupA, groupB]);
    return commonStyles.some((style) => style !== STYLE_NONE);
}
