import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    type SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import * as Immutable from "immutable";
import { DateTime } from "luxon";
import { type ChangeEvent, useCallback, useMemo, useState } from "react";

import { CAMPAIGN_TYPE_LABELS } from "../amazon/campaign";
import { ACTIVE_CAMPAIGN_TYPES, CAMPAIGN_TYPES, type CampaignType } from "../amazon/types";
import { useApiClient } from "../api";
import { CARD_STYLE } from "../styles";
import { formatDateTime } from "../utils";

const PAGE_SIZE = 500;

export interface TaskResultsTablePageProps {
    name: string;
    columns: Record<string, string[]>;
}

export interface SuccessRow {
    taskId: string;
    taskName: string;
    originalIndex: number;
    campaignType: CampaignType | undefined;
    timestamp: DateTime;
    user: string | null;
    data: Record<string, string>;
}

// const DEFAULT_PROFILE_COUNTRY_CODE: CountryCode = "US";

const CAMPAIGN_TYPE_REGEX = /amazon\.bulk_update_(\w{2})/;

const CAMPAIGN_TYPE_MAP: Record<string, CampaignType> = {
    sb: "sponsored-brands",
    sp: "sponsored-products",
    sv: "sponsored-video",
    bv: "banner-video",
};

function getCampaignType(taskName: string): CampaignType | undefined {
    const match = taskName.match(CAMPAIGN_TYPE_REGEX);
    const shortName = match?.[1];
    return shortName ? CAMPAIGN_TYPE_MAP[shortName] : undefined;
}

export function TaskResultsTablePage({ name, columns }: TaskResultsTablePageProps) {
    const apiClient = useApiClient();

    // const [profileCountryCode, setProfileCountryCode] = useState(DEFAULT_PROFILE_COUNTRY_CODE);
    const [campaignType, setCampaignType] = useState<CampaignType | undefined>(undefined);
    const [afterDate, setAfterDate] = useState<DateTime | null>(null);
    const [searchText, setSearchText] = useState("");
    const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);

    // const profile = useMemo(() => PROFILES[profileCountryCode], [profileCountryCode]);
    // const profileId = profile.id;

    const tasksQuery = useQuery({
        queryKey: ["tasks", name],
        queryFn: () => apiClient.getTasks({ queries: { name } }),
    });

    const tasks = useMemo(() => Immutable.List(tasksQuery.data ?? []), [tasksQuery.data]);
    const resultsAllCampaigns = useMemo(
        () =>
            tasks
                .flatMap((task) =>
                    (task.successes ?? []).map((data, index) => ({
                        taskId: task.id,
                        taskName: task.name,
                        originalIndex: index,
                        campaignType: getCampaignType(task.name),
                        timestamp: DateTime.fromISO(task.started_at),
                        user: task.user,
                        data,
                    })),
                )
                .filter((row) => campaignType === undefined || row.campaignType === campaignType)
                .filter((row) => afterDate === null || row.timestamp >= afterDate)
                .filter(
                    (row) =>
                        searchText === "" ||
                        (typeof row.data.campaign_name === "string" &&
                            row.data.campaign_name.includes(searchText)),
                ),
        [tasks, campaignType, afterDate, searchText],
    );

    const campaigns = useMemo(
        () =>
            Immutable.Map(
                resultsAllCampaigns
                    .filter((row) => row.data.campaign_id && row.data.campaign_name)
                    .map((row) => [
                        row.data.campaign_id as string,
                        row.data.campaign_name as string,
                    ]),
            ).sortBy((name) => name),
        [resultsAllCampaigns],
    );

    const results = useMemo(
        () =>
            resultsAllCampaigns
                .filter(
                    (row) =>
                        selectedCampaigns.length === 0 ||
                        (typeof row.data.campaign_id === "string" &&
                            selectedCampaigns.includes(row.data.campaign_id)),
                )
                .sortBy((row) => row.timestamp.toMillis())
                .reverse()
                .take(PAGE_SIZE),
        [resultsAllCampaigns, selectedCampaigns],
    );

    // const handleProfileCountryCodeChange = useCallback((event: SelectChangeEvent) => {
    //     setProfileCountryCode(event.target.value as CountryCode);
    // }, []);

    const handleCampaignTypeChange = useCallback((event: SelectChangeEvent) => {
        setCampaignType(event.target.value as CampaignType);
    }, []);

    const handleSearchTextChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setSearchText(event.target.value);
        },
        [],
    );

    const handleSelectedCampaignsChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        const selectedCampaigns = Array.from(event.target.selectedOptions).map(
            (option) => option.value,
        );
        setSelectedCampaigns(selectedCampaigns);
    }, []);

    const tableRows = useMemo(
        () =>
            results.map((row: SuccessRow) => {
                const values = Object.values(columns).map((keys) => {
                    for (const key of keys) {
                        const value = row.data[key];
                        if (value) {
                            return value;
                        }
                    }
                });

                return (
                    <TableRow key={`${row.taskId}-${row.originalIndex}`}>
                        <TableCell>{formatDateTime(row.timestamp)}</TableCell>
                        <TableCell>{row.user}</TableCell>
                        {/* <TableCell>{row.taskId}</TableCell> */}
                        {/* <TableCell>{row.taskName}</TableCell> */}
                        {/* <TableCell>{row.campaignType}</TableCell> */}
                        {values.map((value, index) => (
                            // biome-ignore lint/suspicious/noArrayIndexKey:
                            <TableCell key={index}>{value}</TableCell>
                        ))}
                    </TableRow>
                );
            }),
        [results, columns],
    );

    return (
        <Container maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={CARD_STYLE}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    gap: 1,
                                }}
                            >
                                {/* <FormControl sx={{ minWidth: "120px" }}>
                                    <InputLabel id="country-profile-label">
                                        Country Profile
                                    </InputLabel>
                                    <Select
                                        labelId="country-profile-label"
                                        id="country-profile"
                                        label="Country Profile"
                                        value={profileCountryCode}
                                        onChange={handleProfileCountryCodeChange}
                                    >
                                        {COUNTRY_CODES.map((code) => (
                                            <MenuItem key={code} value={code}>
                                                {code}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}
                                <FormControl sx={{ minWidth: "240px" }}>
                                    <InputLabel id="campaign-type-label">Campaign Type</InputLabel>
                                    <Select
                                        labelId="campaign-type-label"
                                        id="campaign-type"
                                        label="Campaign Type"
                                        value={campaignType}
                                        onChange={handleCampaignTypeChange}
                                    >
                                        <MenuItem value={undefined}>All</MenuItem>
                                        {CAMPAIGN_TYPES.map((type) => (
                                            <MenuItem
                                                key={type}
                                                value={type}
                                                disabled={!ACTIVE_CAMPAIGN_TYPES.includes(type)}
                                            >
                                                {CAMPAIGN_TYPE_LABELS[type]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ width: "200px" }}>
                                    <DatePicker
                                        label="After Date"
                                        value={afterDate}
                                        onChange={setAfterDate}
                                        slotProps={{
                                            textField: {
                                                // @ts-expect-error: typings don't know about `clearable` (?)
                                                clearable: true,
                                            },
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ minWidth: "300px" }}>
                                    <TextField
                                        id="campaign-search"
                                        label="Search by name"
                                        value={searchText}
                                        onChange={handleSearchTextChange}
                                    />
                                </FormControl>
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    native
                                    multiple
                                    value={selectedCampaigns}
                                    // @ts-expect-error: typings don't know about native element
                                    onChange={handleSelectedCampaignsChange}
                                    inputProps={{
                                        id: "campaign-select",
                                        size: 10,
                                    }}
                                >
                                    {campaigns
                                        .map((name, id) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey:
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))
                                        .valueSeq()}
                                </Select>
                            </FormControl>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={CARD_STYLE}>
                        <TableContainer>
                            <Table
                                stickyHeader
                                size="small"
                                sx={{
                                    width: "auto",
                                    "& .MuiTableCell-root": {
                                        padding: "4px 8px",
                                        whiteSpace: "nowrap",
                                    },
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Timestamp</TableCell>
                                        <TableCell>User</TableCell>
                                        {/* <TableCell>Task ID</TableCell> */}
                                        {/* <TableCell>Task Name</TableCell> */}
                                        {/* <TableCell>Campaign Type</TableCell> */}
                                        {Object.keys(columns).map((column) => (
                                            <TableCell key={column}>{column}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{tableRows}</TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
