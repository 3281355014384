import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { useApiClient } from "../../../../api";
import { MetaAdForm } from "../../../../components";

export const Route = createFileRoute("/_auth/meta/ad-updates/create")({
    component: CreateAdUpdate,
    beforeLoad: () => ({
        getTitle: () => "Create",
    }),
});

function CreateAdUpdate() {
    const apiClient = useApiClient();
    const navigate = Route.useNavigate();

    const mutation = useMutation({
        mutationFn: apiClient.metaCreateAdUpdate,
        onSuccess: async () => {
            await navigate({ to: "/meta/ad-updates" });
        },
    });

    // @ts-expect-error I dunno man
    return <MetaAdForm mutation={mutation} />;
}
