import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    List,
    ListItem,
    MenuItem,
    Paper,
    Select,
    type SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";
import * as Immutable from "immutable";
import { type ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { useAlerts } from "../../../alerts";
import { STYLE_NONE, getCommonStyles, getStylesFromCampaignName } from "../../../amazon/campaign";
import type { SelectedProductDynamic } from "../../../amazon/types";
import { useApiClient } from "../../../api";
import { sponsoredBrandsCreativeUpdateSchema } from "../../../api/types";
import {
    AssetSelectModal,
    CampaignList,
    ErrorMessage,
    Loading,
    PageTitle,
    ProductSelect,
    SalesList,
    UpdateConfirmationModal,
} from "../../../components";
import { AD_THUMBNAIL_STYLE, CARD_STYLE } from "../../../styles";
import { pluralize } from "../../../utils";

const searchSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const Route = createFileRoute("/_auth/amazon/sponsored-brands-creative")({
    component: SponsoredBrandsCreative,
    validateSearch: (search) => searchSchema.parse(search),
    beforeLoad: () => ({
        getTitle: () => "Sponsored Brands (Creative)",
    }),
    onError: (error: { routerCode: string }) => {
        if (error.routerCode === "VALIDATE_SEARCH") {
            throw redirect({
                to: "/amazon/bulk-creative-update",
                search: { campaignType: "sponsored-brands" },
            });
        }
    },
});

function SponsoredBrandsCreative() {
    const { profile_id, campaign_ids } = Route.useSearch();
    const navigate = Route.useNavigate();

    const { addAlert } = useAlerts();

    const apiClient = useApiClient();
    const queryClient = useQueryClient();

    const campaignNamesQuery = useQuery({
        queryKey: ["campaignNames", profile_id, campaign_ids],
        queryFn: () =>
            apiClient.getCampaignNames({
                profile_id,
                campaign_ids,
                campaign_type: "sponsored-brands",
            }),
    });

    const logosQuery = useQuery({
        queryKey: ["logos", profile_id],
        queryFn: () => apiClient.getLogos({ queries: { profile_id } }),
    });

    const backgroundsQuery = useQuery({
        queryKey: ["backgrounds", profile_id],
        queryFn: () => apiClient.getBackgrounds({ queries: { profile_id } }),
    });

    const headlinesQuery = useQuery({
        queryKey: ["headlines", profile_id],
        queryFn: () => apiClient.getHeadlines({ queries: { profile_id } }),
    });

    const brandsQuery = useQuery({
        queryKey: ["sponsoredBrandsCreative", profile_id, campaign_ids],
        queryFn: () =>
            apiClient.getSponsoredBrandsCreative({
                profile_id,
                campaign_ids,
            }),
    });

    const mutation = useMutation({
        mutationFn: apiClient.updateSponsoredBrandsCreative,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["sponsoredBrandsCreative", profile_id, campaign_ids],
            });

            const campaignCount = campaign_ids.length;
            const updatesWord = pluralize(campaignCount, "update");
            addAlert({
                type: "success",
                message: `${campaignCount} ${updatesWord} queued`,
            });
            await navigate({
                to: "/amazon/bulk-creative-update",
                search: { campaignType: "sponsored-brands" },
            });
        },
    });

    const [logoModalOpen, setLogoModalOpen] = useState(false);
    const [usingCurrentLogo, setUsingCurrentLogo] = useState(false);
    const [newLogoId, setNewLogoId] = useState<string | null>(null);
    const [editingLogo, setEditingLogo] = useState(true);

    const [backgroundModalOpen, setBackgroundModalOpen] = useState(false);
    const [usingCurrentBackground, setUsingCurrentBackground] = useState(false);
    const [newBackgroundId, setNewBackgroundId] = useState<string | null>(null);
    const [editingBackground, setEditingBackground] = useState(true);

    const [selectingHeadline, setSelectingHeadline] = useState(false);
    const [selectedHeadline, setSelectedHeadline] = useState<string | null>(null);
    const [newHeadline, setNewHeadline] = useState("");
    const [editingHeadline, setEditingHeadline] = useState(true);

    const [selectedProducts, setSelectedProducts] = useState<
        Immutable.List<SelectedProductDynamic>
    >(Immutable.List());
    const [editingProducts, setEditingProducts] = useState(Immutable.List([true, true, true]));

    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const campaignNames = campaignNamesQuery.data;
    const logos = logosQuery.data;
    const backgrounds = backgroundsQuery.data;
    const headlines = headlinesQuery.data;
    const initialData = brandsQuery.data;

    const currentLogo = initialData?.logo;
    const newLogo = logos?.find((logo) => logo.id === newLogoId);
    const logo = usingCurrentLogo ? currentLogo : newLogo;
    const logoId = logo?.id;

    const currentBackground = initialData?.background;
    const newBackground = backgrounds?.find((background) => background.id === newBackgroundId);
    const background = usingCurrentBackground ? currentBackground : newBackground;
    const backgroundId = background?.id;

    const headline = selectingHeadline ? selectedHeadline : newHeadline;

    const commonStyles = useMemo(() => {
        const styleGroups = Object.values(campaignNames ?? {})
            .map((name) => getStylesFromCampaignName(name))
            .filter((styles) => styles !== undefined);
        return getCommonStyles(styleGroups);
    }, [campaignNames]);

    const enabledProducts = useMemo(
        () => commonStyles.map((style) => style !== STYLE_NONE),
        [commonStyles],
    );

    const validatedProducts = useMemo(
        () => selectedProducts.toArray().filter((product) => product.state === "loaded"),
        [selectedProducts],
    );

    const styles = useMemo(
        () => validatedProducts.map((product) => product.style),
        [validatedProducts],
    );

    const asins = useMemo(
        () =>
            validatedProducts.map((product, index) =>
                enabledProducts[index] && editingProducts.get(index) ? product.asin : null,
            ),
        [validatedProducts, enabledProducts, editingProducts],
    );

    const updateResult = useMemo(() => {
        return sponsoredBrandsCreativeUpdateSchema.safeParse({
            profile_id,
            campaign_ids,
            headline: editingHeadline ? headline : null,
            logo_id: editingLogo ? logoId : null,
            background_id: editingBackground ? backgroundId : null,
            asins,
        });
    }, [
        profile_id,
        campaign_ids,
        editingHeadline,
        headline,
        editingLogo,
        logoId,
        editingBackground,
        backgroundId,
        asins,
    ]);

    const updateErrors = useMemo(() => {
        return updateResult.error?.format();
    }, [updateResult]);

    const valid = updateErrors === undefined;
    const busy = headlinesQuery.isLoading || brandsQuery.isLoading || mutation.isPending;

    useEffect(() => {
        if (initialData) {
            const selectedProducts: SelectedProductDynamic[] = initialData.products.map(
                (product) => ({
                    state: "loaded",
                    ...product,
                }),
            );

            setSelectedProducts(Immutable.List(selectedProducts));
            setNewLogoId(initialData.logo?.id ?? null);
            setNewBackgroundId(initialData.background?.id ?? null);
            setUsingCurrentLogo(initialData.logo !== null);
            setUsingCurrentBackground(initialData.background !== null);
            setSelectingHeadline(initialData.headline !== null);
            setSelectedHeadline(initialData.headline);
            setNewHeadline(initialData.headline ?? "");
        }
    }, [initialData]);

    const handleUsingCurrentLogoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setUsingCurrentLogo(event.target.checked);
    }, []);

    const handleEditingLogoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingLogo(event.target.checked);
    }, []);

    const handleUsingCurrentBackgroundChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setUsingCurrentBackground(event.target.checked);
        },
        [],
    );

    const handleEditingBackgroundChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingBackground(event.target.checked);
    }, []);

    const handleSelectingHeadlineChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSelectingHeadline(event.target.checked);
    }, []);

    const handleSelectedHeadlineChange = useCallback((event: SelectChangeEvent<string | null>) => {
        setSelectedHeadline(event.target.value);
    }, []);

    const handleNewHeadlineChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setNewHeadline(event.target.value);
    }, []);

    const handleEditingHeadlineChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingHeadline(event.target.checked);
    }, []);

    const handleProductChange = useCallback((index: number, product: SelectedProductDynamic) => {
        setSelectedProducts((products) => products.set(index, product));
    }, []);

    const handleEditingProductChange = useCallback(
        (index: number, event: ChangeEvent<HTMLInputElement>) => {
            setEditingProducts((products) => products.set(index, event.target.checked));
        },
        [],
    );

    const showConfirmation = useCallback(() => {
        setConfirmationVisible(true);
    }, []);

    const hideConfirmation = useCallback(() => {
        setConfirmationVisible(false);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (updateResult.success) {
            await mutation.mutateAsync(updateResult.data);
        }
    }, [updateResult, mutation]);

    const productGridItems = useMemo(() => {
        return selectedProducts.map((product, index) => {
            const humanIndex = index + 1;
            return (
                // biome-ignore lint/suspicious/noArrayIndexKey:
                <Grid item xs={4} key={index}>
                    <Paper sx={CARD_STYLE}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={enabledProducts[index] && editingProducts.get(index)}
                                    onChange={(event) => handleEditingProductChange(index, event)}
                                />
                            }
                            label={
                                <Typography fontWeight={500}>Current ASIN {humanIndex}</Typography>
                            }
                            disabled={!enabledProducts[index]}
                        />
                        <ProductSelect
                            id={`product-${humanIndex}`}
                            product={product}
                            onChange={(product) => {
                                handleProductChange(index, product);
                            }}
                            disabled={!enabledProducts[index] || !editingProducts.get(index)}
                        />
                    </Paper>
                </Grid>
            );
        });
    }, [
        selectedProducts,
        editingProducts,
        handleEditingProductChange,
        handleProductChange,
        enabledProducts,
    ]);

    return (
        <Container maxWidth="lg">
            <AssetSelectModal
                type="image"
                title="Available Logos"
                options={logos ?? []}
                value={newLogoId}
                onChange={setNewLogoId}
                open={logoModalOpen}
                onClose={() => {
                    setLogoModalOpen(false);
                }}
            />
            <AssetSelectModal
                type="image"
                title="Available Backgrounds"
                options={backgrounds ?? []}
                value={newBackgroundId}
                onChange={setNewBackgroundId}
                open={backgroundModalOpen}
                onClose={() => {
                    setBackgroundModalOpen(false);
                }}
            />
            <UpdateConfirmationModal
                open={confirmationVisible}
                busy={busy}
                onClose={hideConfirmation}
                onConfirm={handleSubmit}
            >
                <List disablePadding>
                    {editingLogo && (
                        <>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>Logo:</Typography>&nbsp;
                                {logo?.id}
                            </ListItem>
                            {logo && (
                                <ListItem>
                                    <img style={AD_THUMBNAIL_STYLE} src={logo.url} />
                                </ListItem>
                            )}
                        </>
                    )}
                    {editingBackground && (
                        <>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>Background:</Typography>&nbsp;
                                {background?.id}
                            </ListItem>
                            {background && (
                                <ListItem>
                                    <img style={AD_THUMBNAIL_STYLE} src={background.url} />
                                </ListItem>
                            )}
                        </>
                    )}
                    {editingHeadline && (
                        <ListItem disablePadding>
                            <Typography fontWeight={500}>Headline:</Typography>&nbsp;
                            {headline}
                        </ListItem>
                    )}
                    {validatedProducts.map(
                        (product, index) =>
                            enabledProducts[index] &&
                            editingProducts.get(index) && (
                                // biome-ignore lint/suspicious/noArrayIndexKey:
                                <Fragment key={index}>
                                    <ListItem disablePadding>
                                        <Typography fontWeight={500}>SKU {index + 1}:</Typography>
                                        &nbsp;
                                        {product.sku}
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <Typography fontWeight={500}>ASIN {index + 1}:</Typography>
                                        &nbsp;
                                        {product.asin}
                                    </ListItem>
                                </Fragment>
                            ),
                    )}
                </List>
            </UpdateConfirmationModal>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <PageTitle />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Sales by Style/Color</Typography>
                        <SalesList profileId={profile_id} styles={styles} />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Selected Campaigns</Typography>
                        <CampaignList
                            profileId={profile_id}
                            campaignIds={campaign_ids}
                            campaignType="sponsored-brands"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={editingLogo}
                                    onChange={handleEditingLogoChange}
                                />
                            }
                            label={<Typography fontWeight={500}>Logo</Typography>}
                        />
                        <Box sx={{ opacity: editingLogo ? 1 : 0.5 }}>
                            <Typography>Current logo:</Typography>
                            {logo ? (
                                <img src={logo.url} style={AD_THUMBNAIL_STYLE} />
                            ) : (
                                <Typography fontStyle="italic">None</Typography>
                            )}
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usingCurrentLogo}
                                    onChange={handleUsingCurrentLogoChange}
                                />
                            }
                            label="Use current logo"
                            disabled={!editingLogo}
                        />
                        {!usingCurrentLogo && (
                            <FormControl
                                fullWidth
                                error={updateErrors?.logo_id !== undefined}
                                disabled={!editingLogo}
                            >
                                <Button
                                    onClick={() => {
                                        setLogoModalOpen(true);
                                    }}
                                >
                                    Select Logo
                                </Button>
                                {updateErrors?.logo_id && (
                                    <FormHelperText>
                                        {updateErrors.logo_id._errors[0]}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={editingBackground}
                                    onChange={handleEditingBackgroundChange}
                                />
                            }
                            label={<Typography fontWeight={500}>Background</Typography>}
                        />
                        <Box sx={{ opacity: editingBackground ? 1 : 0.5 }}>
                            <Typography>Current background:</Typography>
                            {background ? (
                                <img src={background.url} style={AD_THUMBNAIL_STYLE} />
                            ) : (
                                <Typography fontStyle="italic">None</Typography>
                            )}
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usingCurrentBackground}
                                    onChange={handleUsingCurrentBackgroundChange}
                                />
                            }
                            label="Use current background"
                            disabled={!editingBackground}
                        />
                        {!usingCurrentBackground && (
                            <FormControl
                                fullWidth
                                error={updateErrors?.background_id !== undefined}
                                disabled={!editingBackground}
                            >
                                <Button
                                    onClick={() => {
                                        setBackgroundModalOpen(true);
                                    }}
                                >
                                    Select Background
                                </Button>
                                {updateErrors?.background_id && (
                                    <FormHelperText>
                                        {updateErrors.background_id._errors[0]}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={editingHeadline}
                                    onChange={handleEditingHeadlineChange}
                                />
                            }
                            label={<Typography fontWeight={500}>Headline</Typography>}
                        />
                        <Box sx={{ opacity: editingHeadline ? 1 : 0.5 }}>
                            <Typography>Current headline:</Typography>
                            <Typography fontWeight={500}>
                                {headline ? headline : <span>&nbsp;</span>}
                            </Typography>
                        </Box>
                        <FormControl
                            fullWidth
                            disabled={!selectingHeadline || !editingHeadline || !headlines}
                        >
                            <Select
                                value={selectedHeadline ?? ""}
                                onChange={handleSelectedHeadlineChange}
                                error={updateErrors?.headline !== undefined}
                            >
                                {headlines?.map((headline) => (
                                    <MenuItem key={headline} value={headline}>
                                        {headline}
                                    </MenuItem>
                                ))}
                            </Select>
                            {selectingHeadline && updateErrors?.headline && (
                                <FormHelperText>{updateErrors.headline._errors[0]}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectingHeadline}
                                    onChange={handleSelectingHeadlineChange}
                                />
                            }
                            label="Select an existing headline"
                            disabled={!editingHeadline}
                        />
                        {!selectingHeadline && (
                            <FormControl fullWidth>
                                <TextField
                                    id="new-headline"
                                    label="New headline"
                                    value={newHeadline}
                                    onChange={handleNewHeadlineChange}
                                    error={updateErrors?.headline !== undefined}
                                    helperText={updateErrors?.headline?._errors[0]}
                                    disabled={!editingHeadline}
                                />
                            </FormControl>
                        )}
                    </Paper>
                </Grid>
                {productGridItems}
                {brandsQuery.isLoading && (
                    <Grid item xs={12}>
                        <Loading />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {mutation.error && <ErrorMessage>{mutation.error.message}</ErrorMessage>}
                    <Button
                        variant="contained"
                        onClick={showConfirmation}
                        disabled={!valid || busy}
                    >
                        Submit Ad
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}
