import { Container, Paper } from "@mui/material";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

import { PageTitle } from "../../../components";
import { VideoStoreForm } from "../../../components/VideoStoreForm";

const searchSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const Route = createFileRoute("/_auth/amazon/banner-video-creative")({
    component: BannerVideoCreative,
    validateSearch: (search) => searchSchema.parse(search),
    beforeLoad: () => ({
        getTitle: () => "Banner Video (Creative)",
    }),
    onError: (error: { routerCode: string }) => {
        if (error.routerCode === "VALIDATE_SEARCH") {
            throw redirect({
                to: "/amazon/bulk-creative-update",
                search: { campaignType: "banner-video" },
            });
        }
    },
});

function BannerVideoCreative() {
    const { profile_id, campaign_ids } = Route.useSearch();

    return (
        <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Paper
                sx={{
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <PageTitle />
            </Paper>
            <VideoStoreForm
                profile_id={profile_id}
                campaign_ids={campaign_ids}
                campaign_type="banner-video"
            />
        </Container>
    );
}
