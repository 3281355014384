import { makeApi } from "@zodios/core";
import { z } from "zod";

import { taskSchema } from "../types";

export const api = makeApi([
    {
        method: "get",
        path: "/:id",
        alias: "getTask",
        description: "Get task",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: z.string(),
            },
        ],
        response: taskSchema,
    },
    {
        method: "get",
        path: "/",
        alias: "getTasks",
        description: "Get tasks",
        parameters: [
            {
                name: "name",
                type: "Query",
                schema: z.string().optional(),
            },
        ],
        response: z.array(taskSchema),
    },
]);
