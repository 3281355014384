import { CloseOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import type Immutable from "immutable";
import { type SetStateAction, useCallback, useMemo } from "react";
import type { ZodFormattedError } from "zod";

import { currencySymbolForRow } from "../shopify/pricing";
import {
    ACTIVE_COUNTRY_CODES,
    type CountryCode,
    type PricingUpdateDiscountPrice,
    type PricingUpdateFixedPrice,
    type PricingUpdateMsrp,
    type PricingUpdatePrice,
    type PricingUpdatePriceType,
    type PricingUpdateRow,
} from "../shopify/types";
import type { Mutator } from "../types";
import { ensureArray, randomId } from "../utils";

const DEFAULT_FIXED_PRICE: PricingUpdateFixedPrice = {
    type: "fixed",
    price: "0",
};

const DEFAULT_DISCOUNT_PRICE: PricingUpdateDiscountPrice = {
    type: "discount",
    percentage: "0",
};

const DEFAULT_MSRP_PRICE: PricingUpdateMsrp = {
    type: "msrp",
};

const DEFAULT_PRICES_BY_TYPE: Record<PricingUpdatePriceType, PricingUpdatePrice> = {
    fixed: DEFAULT_FIXED_PRICE,
    discount: DEFAULT_DISCOUNT_PRICE,
    msrp: DEFAULT_MSRP_PRICE,
};

export interface PricingFormTableProps {
    styleColors: Record<string, string[]>;
    rows: Immutable.OrderedMap<string, PricingUpdateRow>;
    errors?: ZodFormattedError<PricingUpdateRow[], string>;
    onChange: (rows: SetStateAction<Immutable.OrderedMap<string, PricingUpdateRow>>) => void;
    infoText?: string;
}

export function PricingFormTable({
    styleColors,
    rows,
    errors,
    onChange,
    infoText,
}: PricingFormTableProps) {
    const styles = useMemo(() => Object.keys(styleColors), [styleColors]);

    const insertRow = useCallback(
        (row: PricingUpdateRow) =>
            onChange((rows) =>
                rows.set(randomId(), row).sortBy((row) => row.type === "style_color"),
            ),
        [onChange],
    );

    const createStyleRow = useCallback(() => {
        insertRow({
            type: "style",
            countries: ["US"],
            styles: [],
            colors: [],
            price: {
                type: "discount",
                percentage: "0",
            },
        });
    }, [insertRow]);

    const createStyleColorRow = useCallback(() => {
        insertRow({
            type: "style_color",
            countries: ["US"],
            styles: [],
            colors: [],
            price: {
                type: "discount",
                percentage: "0",
            },
        });
    }, [insertRow]);

    const updateRow = useCallback(
        (id: string, mutate: Mutator<PricingUpdateRow>) => {
            onChange((rows) => rows.update(id, (value) => mutate(value!)));
        },
        [onChange],
    );

    const removeRow = useCallback(
        (id: string) => {
            onChange((rows) => rows.delete(id));
        },
        [onChange],
    );

    const setCountries = useCallback(
        (id: string, countries: CountryCode[]) => {
            updateRow(id, (row) => ({
                ...row,
                countries,
            }));
        },
        [updateRow],
    );

    const setStyles = useCallback(
        (id: string, styles: string[]) => {
            updateRow(id, (row) => ({
                ...row,
                styles,
                colors: [],
            }));
        },
        [updateRow],
    );

    const setColors = useCallback(
        (id: string, colors: string[]) => {
            updateRow(id, (row) => ({
                ...row,
                colors,
            }));
        },
        [updateRow],
    );

    const setPrice = useCallback(
        (id: string, price: PricingUpdatePrice) => {
            updateRow(id, (row) => ({
                ...row,
                price,
            }));
        },
        [updateRow],
    );

    const renderedRows =
        styles.length > 0
            ? rows
                  .entrySeq()
                  .map(([id, row], index) => {
                      const primaryStyle = row.styles[0];
                      const colors = styleColors[primaryStyle];
                      const allStylesSelected = row.styles.length === styles.length;
                      const error = errors?.[index];
                      return (
                          <TableRow key={id}>
                              <TableCell>
                                  <Select
                                      size="small"
                                      fullWidth
                                      multiple
                                      value={row.countries}
                                      onChange={(event) => {
                                          setCountries(
                                              id,
                                              ensureArray(
                                                  event.target.value as CountryCode | CountryCode[],
                                              ),
                                          );
                                      }}
                                  >
                                      {ACTIVE_COUNTRY_CODES.map((country) => (
                                          <MenuItem key={country} value={country}>
                                              {country}
                                          </MenuItem>
                                      ))}
                                  </Select>
                              </TableCell>
                              <TableCell>
                                  <Box sx={{ display: "flex", gap: 0.5 }}>
                                      <Select
                                          fullWidth={row.type === "style"}
                                          sx={{ minWidth: "85px" }}
                                          size="small"
                                          value={allStylesSelected ? [] : row.styles}
                                          disabled={allStylesSelected}
                                          multiple={row.type === "style"}
                                          onChange={(event) => {
                                              setStyles(id, ensureArray(event.target.value));
                                          }}
                                          error={error?.styles !== undefined}
                                      >
                                          {!allStylesSelected &&
                                              styles.map((style) => (
                                                  <MenuItem key={style} value={style}>
                                                      {style}
                                                  </MenuItem>
                                              ))}
                                      </Select>
                                      {row.type === "style_color" ? (
                                          <Select
                                              fullWidth
                                              size="small"
                                              value={row.colors}
                                              multiple
                                              onChange={(event) => {
                                                  setColors(id, ensureArray(event.target.value));
                                              }}
                                              error={error?.colors !== undefined}
                                              disabled={colors === undefined}
                                          >
                                              {colors?.map((color) => (
                                                  <MenuItem key={color} value={color}>
                                                      {color}
                                                  </MenuItem>
                                              ))}
                                          </Select>
                                      ) : (
                                          <FormControlLabel
                                              control={
                                                  <Checkbox
                                                      checked={allStylesSelected}
                                                      onChange={(event) => {
                                                          setStyles(
                                                              id,
                                                              event.target.checked
                                                                  ? styles
                                                                  : styles.slice(0, 1),
                                                          );
                                                      }}
                                                  />
                                              }
                                              label="Select all"
                                              sx={{
                                                  whiteSpace: "nowrap",
                                                  paddingX: 1,
                                              }}
                                          />
                                      )}
                                  </Box>
                              </TableCell>
                              <TableCell>
                                  <ToggleButtonGroup
                                      color="primary"
                                      size="small"
                                      exclusive
                                      value={row.price.type}
                                      onChange={(event, value: PricingUpdatePriceType | null) => {
                                          if (value === null) {
                                              return;
                                          }

                                          const price = DEFAULT_PRICES_BY_TYPE[value];
                                          setPrice(id, price);
                                      }}
                                  >
                                      <ToggleButton value="fixed">Fixed</ToggleButton>
                                      <ToggleButton value="discount">Discount</ToggleButton>
                                      <ToggleButton value="msrp">MSRP</ToggleButton>
                                  </ToggleButtonGroup>
                              </TableCell>
                              <TableCell>
                                  {row.price.type === "discount" && (
                                      <OutlinedInput
                                          size="small"
                                          value={row.price.percentage}
                                          onChange={(event) => {
                                              setPrice(id, {
                                                  type: "discount",
                                                  percentage: event.target.value,
                                              });
                                          }}
                                          error={error?.price !== undefined}
                                          endAdornment={
                                              <InputAdornment position="end">%</InputAdornment>
                                          }
                                          inputProps={{
                                              style: {
                                                  textAlign: "right",
                                              },
                                          }}
                                      />
                                  )}
                                  {row.price.type === "fixed" && (
                                      <OutlinedInput
                                          size="small"
                                          value={row.price.price}
                                          onChange={(event) => {
                                              setPrice(id, {
                                                  type: "fixed",
                                                  price: event.target.value,
                                              });
                                          }}
                                          error={error?.price !== undefined}
                                          startAdornment={
                                              <InputAdornment position="start">
                                                  {currencySymbolForRow(row)}
                                              </InputAdornment>
                                          }
                                          inputProps={{
                                              style: {
                                                  textAlign: "right",
                                              },
                                          }}
                                      />
                                  )}
                              </TableCell>
                              <TableCell align="right">
                                  <Button
                                      variant="text"
                                      color="error"
                                      onClick={() => {
                                          removeRow(id);
                                      }}
                                      sx={{ minWidth: "auto", padding: "6px" }}
                                  >
                                      <CloseOutlined />
                                  </Button>
                              </TableCell>
                          </TableRow>
                      );
                  })
                  .valueSeq()
            : null;

    return (
        <>
            <TableContainer>
                <Table
                    size="small"
                    sx={{
                        tableLayout: "fixed",
                        "& .MuiTableCell-root": { padding: "2px" },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "150px" }}>Countries</TableCell>
                            <TableCell sx={{ width: "300px" }}>Style/Color</TableCell>
                            <TableCell sx={{ width: "150px" }}>Type</TableCell>
                            <TableCell sx={{ width: "100px" }}>Price</TableCell>
                            <TableCell sx={{ width: "50px" }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderedRows}</TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                {infoText && <Typography variant="caption">{infoText}</Typography>}
                <Box sx={{ flex: 1 }} />
                <Button variant="text" onClick={createStyleRow}>
                    Add Style
                </Button>
                <Button variant="text" onClick={createStyleColorRow}>
                    Add Style/Color
                </Button>
            </Box>
        </>
    );
}
