import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    List,
    ListItem,
    Paper,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { useAlerts } from "../../../alerts";
import { calculateKeywordBid } from "../../../amazon/bids";
import type { KeywordBidType } from "../../../amazon/types";
import { useApiClient } from "../../../api";
import { sponsoredBrandsBiddingUpdateSchema } from "../../../api/types";
import {
    CampaignList,
    ErrorMessage,
    Loading,
    PageTitle,
    UpdateConfirmationModal,
} from "../../../components";
import { CARD_STYLE } from "../../../styles";
import { ensureNumber, pluralize } from "../../../utils";

const searchSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const Route = createFileRoute("/_auth/amazon/sponsored-brands-bidding")({
    component: SponsoredBrandsBidding,
    validateSearch: (search) => searchSchema.parse(search),
    beforeLoad: () => ({
        getTitle: () => "Sponsored Brands (Bidding)",
    }),
    onError: (error: { routerCode: string }) => {
        if (error.routerCode === "VALIDATE_SEARCH") {
            throw redirect({
                to: "/amazon/bidding-update",
                search: { campaignType: "sponsored-brands" },
            });
        }
    },
});

function SponsoredBrandsBidding() {
    const { profile_id, campaign_ids } = Route.useSearch();
    const navigate = Route.useNavigate();

    const { addAlert } = useAlerts();

    const apiClient = useApiClient();
    const queryClient = useQueryClient();

    const biddingQuery = useQuery({
        queryKey: ["sponsoredBrandsBidding", profile_id, campaign_ids],
        queryFn: () =>
            apiClient.getSponsoredBrandsBidding({
                profile_id,
                campaign_ids,
            }),
    });

    const mutation = useMutation({
        mutationFn: apiClient.updateSponsoredBrandsBidding,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["sponsoredBrandsBidding", profile_id, campaign_ids],
            });

            const campaignCount = campaign_ids.length;
            const updatesWord = pluralize(campaignCount, "update");
            addAlert({
                type: "success",
                message: `${campaignCount} ${updatesWord} queued`,
            });
            await navigate({
                to: "/amazon/bidding-update",
                search: { campaignType: "sponsored-brands" },
            });
        },
    });

    const [editingBidAdjustment, setEditingBidAdjustment] = useState(false);
    const [bidAdjustment, setBidAdjustment] = useState(0);

    const [editingKeywordBid, setEditingKeywordBid] = useState(false);
    const [keywordBidRelative, setKeywordBidRelative] = useState(0);
    const [keywordBidAbsolute, setKeywordBidAbsolute] = useState(0);
    const [keywordBidType, setKeywordBidType] = useState<KeywordBidType>("relative");

    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const initialData = biddingQuery.data;

    const keywordBid = calculateKeywordBid(
        keywordBidType,
        keywordBidRelative,
        keywordBidAbsolute,
        initialData?.keyword_bid ?? 0,
    );

    const updateResult = useMemo(
        () =>
            sponsoredBrandsBiddingUpdateSchema.safeParse({
                profile_id,
                campaign_ids,
                bid_adjustment: editingBidAdjustment ? bidAdjustment : null,
                keyword_bid: editingKeywordBid ? keywordBid : null,
            }),
        [
            profile_id,
            campaign_ids,
            editingBidAdjustment,
            bidAdjustment,
            editingKeywordBid,
            keywordBid,
        ],
    );

    const updateErrors = useMemo(() => updateResult.error?.format(), [updateResult]);

    const valid = updateErrors === undefined;
    const busy = biddingQuery.isLoading || mutation.isPending;

    useEffect(() => {
        if (initialData) {
            setBidAdjustment(initialData.bid_adjustment ?? 0);
            setKeywordBidAbsolute(initialData.keyword_bid ?? 0);
        }
    }, [initialData]);

    const handleEditingBidAdjustmentChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingBidAdjustment(event.target.checked);
    }, []);

    const handleEditingKeywordBidChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingKeywordBid(event.target.checked);
    }, []);

    const handleBidAdjustmentChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = ensureNumber(event.target.value) ?? 0;
        setBidAdjustment(value);
    }, []);

    const handleKeywordBidAbsoluteChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = ensureNumber(event.target.value) ?? 0;
        setKeywordBidAbsolute(value);
    }, []);

    const handleKeywordBidRelativeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = ensureNumber(event.target.value) ?? 0;
        setKeywordBidRelative(value);
    }, []);

    const showConfirmation = useCallback(() => {
        setConfirmationVisible(true);
    }, []);

    const hideConfirmation = useCallback(() => {
        setConfirmationVisible(false);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (updateResult.success) {
            await mutation.mutateAsync(updateResult.data);
        }
    }, [updateResult, mutation]);

    return (
        <Container maxWidth="lg">
            <UpdateConfirmationModal
                open={confirmationVisible}
                busy={busy}
                onClose={hideConfirmation}
                onConfirm={handleSubmit}
            >
                <List disablePadding>
                    {editingBidAdjustment && (
                        <ListItem disablePadding>
                            <Typography fontWeight={500}>Rest of search:</Typography>&nbsp;
                            {bidAdjustment}%
                        </ListItem>
                    )}
                    {editingKeywordBid && (
                        <ListItem disablePadding>
                            <Typography fontWeight={500}>Keywords:</Typography>&nbsp; $
                            {keywordBid.toFixed(2)}
                        </ListItem>
                    )}
                </List>
            </UpdateConfirmationModal>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <PageTitle />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Selected Campaigns</Typography>
                        <CampaignList
                            profileId={profile_id}
                            campaignIds={campaign_ids}
                            campaignType="sponsored-brands"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Bid Adjustments</Typography>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={editingBidAdjustment}
                                        onChange={handleEditingBidAdjustmentChange}
                                    />
                                }
                                label="Rest of search"
                            />
                            <FormControl>
                                <TextField
                                    size="small"
                                    sx={{ width: "100px" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                        ),
                                    }}
                                    type="number"
                                    value={bidAdjustment}
                                    onChange={handleBidAdjustmentChange}
                                    error={updateErrors?.bid_adjustment !== undefined}
                                    helperText={updateErrors?.bid_adjustment?._errors[0]}
                                    disabled={!editingBidAdjustment}
                                />
                            </FormControl>
                        </Box>
                        <Divider />
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={editingKeywordBid}
                                        onChange={handleEditingKeywordBidChange}
                                    />
                                }
                                label="Keywords"
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    alignItems: "center",
                                }}
                            >
                                <ToggleButtonGroup
                                    color="primary"
                                    size="small"
                                    exclusive
                                    value={keywordBidType}
                                    onChange={(event, value: KeywordBidType | null) => {
                                        if (value === null) {
                                            return;
                                        }

                                        setKeywordBidType(value);
                                    }}
                                    disabled={!editingKeywordBid}
                                >
                                    <ToggleButton value="relative">&nbsp;%&nbsp;</ToggleButton>
                                    <ToggleButton value="absolute">&nbsp;$&nbsp;</ToggleButton>
                                </ToggleButtonGroup>
                                {keywordBidType === "relative" && (
                                    <>
                                        <FormControl>
                                            <TextField
                                                size="small"
                                                sx={{ width: "100px" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type="number"
                                                value={keywordBidRelative}
                                                onChange={handleKeywordBidRelativeChange}
                                                error={updateErrors?.keyword_bid !== undefined}
                                                helperText={updateErrors?.keyword_bid?._errors[0]}
                                                disabled={!editingKeywordBid}
                                            />
                                        </FormControl>
                                        <Typography>=</Typography>
                                        <Typography fontWeight={500}>
                                            ${keywordBid.toFixed(2)}
                                        </Typography>
                                    </>
                                )}
                                {keywordBidType === "absolute" && (
                                    <FormControl>
                                        <TextField
                                            size="small"
                                            sx={{ width: "100px" }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="number"
                                            value={keywordBidAbsolute}
                                            onChange={handleKeywordBidAbsoluteChange}
                                            error={updateErrors?.keyword_bid !== undefined}
                                            helperText={updateErrors?.keyword_bid?._errors[0]}
                                            disabled={!editingKeywordBid}
                                        />
                                    </FormControl>
                                )}
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                {biddingQuery.isLoading && (
                    <Grid item xs={12}>
                        <Loading />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {mutation.error && <ErrorMessage>{mutation.error.message}</ErrorMessage>}
                    <Button
                        variant="contained"
                        onClick={showConfirmation}
                        disabled={!valid || busy}
                    >
                        Submit Ad
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}
