import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    type SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import * as Immutable from "immutable";
import type { DateTime } from "luxon";
import { type ChangeEvent, type FormEvent, useCallback, useMemo, useState } from "react";
import { z } from "zod";

import {
    CAMPAIGN_TYPE_CREATIVE_UPDATE_ROUTES,
    CAMPAIGN_TYPE_LABELS,
} from "../../../amazon/campaign";
import { PROFILES_BY_COUNTRY_CODE } from "../../../amazon/profiles";
import {
    ACTIVE_CAMPAIGN_TYPES,
    CAMPAIGN_TYPES,
    COUNTRY_CODES,
    type CampaignType,
    type CountryCode,
} from "../../../amazon/types";
import { useApiClient } from "../../../api";
import { ErrorMessage, PageTitle } from "../../../components";
import { CARD_STYLE } from "../../../styles";

const DEFAULT_PROFILE_COUNTRY_CODE: CountryCode = "US";
const DEFAULT_PROFILE_CAMPAIGN_TYPE: CampaignType = "sponsored-brands";

// either Zod or TanStack Router does not understand that fields with default values are optional
const searchSchema = z.object({
    profileCountryCode: z.enum(COUNTRY_CODES).default(DEFAULT_PROFILE_COUNTRY_CODE),
    campaignType: z.enum(CAMPAIGN_TYPES).default(DEFAULT_PROFILE_CAMPAIGN_TYPE),
});

export const Route = createFileRoute("/_auth/amazon/bulk-creative-update")({
    component: BulkCreativeUpdate,
    beforeLoad: () => ({
        getTitle: () => "Bulk Creative Update",
    }),
    validateSearch: (search) => searchSchema.parse(search) as z.input<typeof searchSchema>,
});

function BulkCreativeUpdate() {
    const navigate = useNavigate();
    const { campaignType, profileCountryCode } = Route.useSearch() as z.output<typeof searchSchema>;

    const [includePaused, setIncludePaused] = useState(false);
    const [includeTest, setIncludeTest] = useState(false);
    const [afterDate, setAfterDate] = useState<DateTime | null>(null);
    const [searchText, setSearchText] = useState("");
    const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);

    const setCampaignType = useCallback(
        async (campaignType: CampaignType) => {
            await navigate({
                to: Route.fullPath,
                search: { profileCountryCode, campaignType },
            });
        },
        [navigate, profileCountryCode],
    );

    const setProfileCountryCode = useCallback(
        async (profileCountryCode: CountryCode) => {
            await navigate({
                to: Route.fullPath,
                search: { profileCountryCode, campaignType },
            });
        },
        [navigate, campaignType],
    );

    const profile = useMemo(
        () => PROFILES_BY_COUNTRY_CODE[profileCountryCode],
        [profileCountryCode],
    );
    const profileId = profile.id;

    const apiClient = useApiClient();

    const campaignsQuery = useQuery({
        enabled: false,
        queryKey: ["searchCampaigns", profileId, campaignType, includePaused, afterDate],
        queryFn: () =>
            apiClient.searchCampaigns({
                queries: {
                    profile_id: profileId,
                    campaign_type: campaignType,
                    include_paused: includePaused,
                    after_date: afterDate?.toISODate() ?? undefined,
                    search_text: "",
                },
            }),
    });

    const campaigns = campaignsQuery.data;

    const handleProfileCountryCodeChange = useCallback(
        (event: SelectChangeEvent) => {
            setProfileCountryCode(event.target.value as CountryCode);
        },
        [setProfileCountryCode],
    );

    const handleCampaignTypeChange = useCallback(
        (event: SelectChangeEvent) => {
            setCampaignType(event.target.value as CampaignType);
        },
        [setCampaignType],
    );

    const handleIncludePausedChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIncludePaused(event.target.checked);
    }, []);

    const handleIncludeTestChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIncludeTest(event.target.checked);
    }, []);

    const handleSearchTextChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setSearchText(event.target.value);
        },
        [],
    );

    const handleSelectedCampaignsChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        const selectedCampaigns = Array.from(event.target.selectedOptions).map(
            (option) => option.value,
        );
        setSelectedCampaigns(selectedCampaigns);
    }, []);

    const handleSearch = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await campaignsQuery.refetch();
        },
        [campaignsQuery],
    );

    const handleEdit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await navigate({
                to: CAMPAIGN_TYPE_CREATIVE_UPDATE_ROUTES[campaignType],
                search: {
                    profile_id: profileId,
                    campaign_ids: selectedCampaigns,
                },
            });
        },
        [navigate, campaignType, profileId, selectedCampaigns],
    );

    const filteredCampaigns = useMemo(() => {
        return Immutable.OrderedMap(
            Object.entries(campaigns ?? {}).filter(([, name]) => {
                const nameLower = name.toLowerCase();
                const searchTextLower = searchText.toLowerCase();
                return (
                    nameLower.includes(searchTextLower) &&
                    (includeTest || searchTextLower.includes("test") || !nameLower.includes("test"))
                );
            }),
        );
    }, [campaigns, searchText, includeTest]);

    const valid = selectedCampaigns.length > 0;

    return (
        <Container maxWidth="md">
            <Paper sx={CARD_STYLE}>
                <PageTitle />
                <Box
                    component="form"
                    onSubmit={handleSearch}
                    noValidate
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1 }}>
                        <FormControl sx={{ minWidth: "120px" }}>
                            <InputLabel id="country-profile-label">Country Profile</InputLabel>
                            <Select
                                labelId="country-profile-label"
                                id="country-profile"
                                label="Country Profile"
                                value={profileCountryCode}
                                onChange={handleProfileCountryCodeChange}
                            >
                                {COUNTRY_CODES.map((code) => (
                                    <MenuItem key={code} value={code}>
                                        {code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: "240px" }}>
                            <InputLabel id="campaign-type-label">Campaign Type</InputLabel>
                            <Select
                                labelId="campaign-type-label"
                                id="campaign-type"
                                label="Campaign Type"
                                value={campaignType}
                                onChange={handleCampaignTypeChange}
                            >
                                {CAMPAIGN_TYPES.map((type) => (
                                    <MenuItem
                                        key={type}
                                        value={type}
                                        disabled={!ACTIVE_CAMPAIGN_TYPES.includes(type)}
                                    >
                                        {CAMPAIGN_TYPE_LABELS[type]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ marginLeft: 1, marginRight: -1 }}>
                            <FormControlLabel
                                label="Include Paused"
                                control={
                                    <Checkbox
                                        checked={includePaused}
                                        onChange={handleIncludePausedChange}
                                    />
                                }
                            />
                        </FormControl>
                        <FormControl sx={{ marginLeft: 1, marginRight: -1 }}>
                            <FormControlLabel
                                label='Include "test"'
                                control={
                                    <Checkbox
                                        checked={includeTest}
                                        onChange={handleIncludeTestChange}
                                    />
                                }
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1 }}>
                        <FormControl sx={{ width: "200px" }}>
                            <DatePicker
                                label="After Date"
                                value={afterDate}
                                onChange={setAfterDate}
                                slotProps={{
                                    textField: {
                                        // @ts-expect-error: typings don't know about `clearable` (?)
                                        clearable: true,
                                    },
                                }}
                            />
                        </FormControl>
                        <FormControl sx={{ minWidth: "300px" }}>
                            <TextField
                                id="campaign-search"
                                label="Search by name"
                                value={searchText}
                                onChange={handleSearchTextChange}
                            />
                        </FormControl>
                        <Box sx={{ flex: 1 }} />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ justifySelf: "flex-end" }}
                            startIcon={
                                campaignsQuery.isLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : (
                                    <SearchIcon />
                                )
                            }
                        >
                            Search
                        </Button>
                    </Box>
                </Box>
                {campaignsQuery.isError && (
                    <ErrorMessage>{campaignsQuery.error.message}</ErrorMessage>
                )}
                {campaignsQuery.isSuccess &&
                    (filteredCampaigns.size > 0 ? (
                        <Box component="form" onSubmit={handleEdit} noValidate>
                            {/* <InputLabel> inside <FormControl> doesn't work great with <Select native> */}
                            <InputLabel htmlFor="campaign-select">Campaigns</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    native
                                    multiple
                                    value={selectedCampaigns}
                                    // @ts-expect-error: typings don't know about native element
                                    onChange={handleSelectedCampaignsChange}
                                    inputProps={{
                                        id: "campaign-select",
                                        size: 20,
                                    }}
                                >
                                    {filteredCampaigns
                                        .map((name, id) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey:
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))
                                        .valueSeq()}
                                </Select>
                            </FormControl>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: 2,
                                }}
                            >
                                <Button type="submit" variant="contained" disabled={!valid}>
                                    Edit Campaigns
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Typography fontWeight={500}>No campaigns found</Typography>
                    ))}
            </Paper>
        </Container>
    );
}
