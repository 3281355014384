import { Typography } from "@mui/material";

import { useRouteTitle } from "../hooks";

export function PageTitle() {
    const pageTitle = useRouteTitle();

    return (
        <Typography component="h1" variant="h5" fontWeight={500}>
            {pageTitle}
        </Typography>
    );
}
