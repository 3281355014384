import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    List,
    ListItem,
    Paper,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { isAxiosError } from "axios";
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
    AssetSelectModal,
    CampaignList,
    ErrorMessage,
    Loading,
    ProductSelect,
    SalesList,
    UpdateConfirmationModal,
} from ".";
import { useAlerts } from "../alerts";
import type { SelectedProductDynamic } from "../amazon/types";
import { useApiClient } from "../api";
import { sponsoredVideoProductDetailCreativeUpdateSchema } from "../api/types";
import { AD_THUMBNAIL_STYLE, CARD_STYLE } from "../styles";
import { pluralize } from "../utils";

export interface VideoProductDetailFormProps {
    profile_id: number;
    campaign_ids: string[];
}

export function VideoProductDetailForm({ profile_id, campaign_ids }: VideoProductDetailFormProps) {
    const navigate = useNavigate();

    const { addAlert } = useAlerts();

    const apiClient = useApiClient();
    const queryClient = useQueryClient();

    const videosQuery = useQuery({
        queryKey: ["videos", profile_id],
        queryFn: () => apiClient.getVideos({ queries: { profile_id } }),
    });

    const videoProductDetailQuery = useQuery({
        queryKey: ["videoProductDetailCreative", profile_id, campaign_ids],
        queryFn: () =>
            apiClient.getSponsoredVideoProductDetailCreative({ profile_id, campaign_ids }),
        retry: false,
    });

    const mutation = useMutation({
        mutationFn: apiClient.updateSponsoredVideoProductDetailCreative,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["videoProductDetailCreative", profile_id, campaign_ids],
            });

            const campaignCount = campaign_ids.length;
            const updatesWord = pluralize(campaignCount, "update");
            addAlert({ type: "success", message: `${campaignCount} ${updatesWord} queued` });
            await navigate({
                to: "/amazon/bulk-creative-update",
                search: { campaignType: "sponsored-video" },
            });
        },
    });

    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [usingCurrentVideo, setUsingCurrentVideo] = useState(false);
    const [newVideoId, setNewVideoId] = useState<string | null>(null);
    const [editingVideo, setEditingVideo] = useState(true);

    const [product, setProduct] = useState<SelectedProductDynamic | null>(null);
    const [editingProduct, setEditingProduct] = useState(true);

    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const videos = videosQuery.data;
    const initialData = videoProductDetailQuery.data;

    const currentVideo = initialData?.video;
    const newVideo = videos?.find((video) => video.id === newVideoId);
    const video = usingCurrentVideo ? currentVideo : newVideo;
    const videoId = video?.id;

    const asin = product?.state === "loaded" ? product.asin : null;
    const sku = product?.state === "loaded" ? product.sku : null;
    const style = product?.style;
    const styles = useMemo(() => (style !== undefined ? [style] : []), [style]);

    const updateResult = useMemo(() => {
        return sponsoredVideoProductDetailCreativeUpdateSchema.safeParse({
            profile_id,
            campaign_ids,
            video_id: editingVideo ? videoId : null,
            asin: editingProduct ? asin : null,
        });
    }, [profile_id, campaign_ids, editingVideo, videoId, editingProduct, asin]);

    const updateErrors = useMemo(() => {
        return updateResult.error?.format();
    }, [updateResult]);

    const valid = updateErrors === undefined;
    const busy = mutation.isPending;

    useEffect(() => {
        if (initialData) {
            const product: SelectedProductDynamic = {
                state: "loaded",
                ...initialData.product,
            };

            setProduct(product);
            setUsingCurrentVideo(initialData.video !== null);
        }
    }, [initialData]);

    const showConfirmation = useCallback(() => {
        setConfirmationVisible(true);
    }, []);

    const hideConfirmation = useCallback(() => {
        setConfirmationVisible(false);
    }, []);

    const handleUsingCurrentVideoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setUsingCurrentVideo(event.target.checked);
    }, []);

    const handleEditingVideoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingVideo(event.target.checked);
    }, []);

    const handleEditingProductChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingProduct(event.target.checked);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (updateResult.success) {
            await mutation.mutateAsync(updateResult.data);
        }
    }, [updateResult, mutation]);

    const error = videoProductDetailQuery.error;
    const errorIs404 = isAxiosError(error) && error.response?.status === 404;

    return videoProductDetailQuery.isLoading ? (
        <Loading />
    ) : (
        <Box>
            <AssetSelectModal
                type="video"
                title="Available Videos"
                options={videos ?? []}
                value={newVideoId}
                onChange={setNewVideoId}
                open={videoModalOpen}
                onClose={() => {
                    setVideoModalOpen(false);
                }}
            />
            <UpdateConfirmationModal
                open={confirmationVisible}
                busy={busy}
                onClose={hideConfirmation}
                onConfirm={handleSubmit}
            >
                <List>
                    {editingVideo && (
                        <>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>Video:</Typography>
                                &nbsp;
                                {video?.id}
                            </ListItem>
                            {video && (
                                <ListItem>
                                    <video src={video.url} style={AD_THUMBNAIL_STYLE} controls />
                                </ListItem>
                            )}
                        </>
                    )}
                    {editingProduct && (
                        <>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>SKU:</Typography>&nbsp;
                                {sku}
                            </ListItem>
                            <ListItem disablePadding>
                                <Typography fontWeight={500}>ASIN:</Typography>
                                &nbsp;{asin}
                            </ListItem>
                        </>
                    )}
                </List>
            </UpdateConfirmationModal>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Sales by Style/Color</Typography>
                        <SalesList profileId={profile_id} styles={styles} />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Selected Campaigns</Typography>
                        <CampaignList
                            profileId={profile_id}
                            campaignIds={campaign_ids}
                            campaignType="sponsored-video"
                        />
                    </Paper>
                </Grid>
                {error ? (
                    <Grid item xs={12}>
                        {errorIs404 ? (
                            <Paper sx={CARD_STYLE}>
                                <Typography fontWeight={500}>
                                    No ads currently live. Please create or enable a Banner Video ad
                                    first.
                                </Typography>
                            </Paper>
                        ) : (
                            <Paper sx={CARD_STYLE}>
                                <ErrorMessage>{error.message}</ErrorMessage>
                            </Paper>
                        )}
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <Paper sx={CARD_STYLE}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editingVideo}
                                            onChange={handleEditingVideoChange}
                                        />
                                    }
                                    label={<Typography fontWeight={500}>Video</Typography>}
                                />
                                <Box sx={{ opacity: editingVideo ? 1 : 0.5 }}>
                                    <Typography>Current video:</Typography>
                                    {video ? (
                                        <video
                                            src={video.url}
                                            style={AD_THUMBNAIL_STYLE}
                                            controls
                                        />
                                    ) : (
                                        <Typography fontStyle="italic">None</Typography>
                                    )}
                                </Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={usingCurrentVideo}
                                            onChange={handleUsingCurrentVideoChange}
                                        />
                                    }
                                    label="Use current video"
                                    disabled={!editingVideo}
                                />
                                {!usingCurrentVideo && (
                                    <FormControl
                                        fullWidth
                                        error={updateErrors?.video_id !== undefined}
                                        disabled={!editingVideo}
                                    >
                                        <Button
                                            onClick={() => {
                                                setVideoModalOpen(true);
                                            }}
                                        >
                                            Select Video
                                        </Button>
                                        {updateErrors?.video_id && (
                                            <FormHelperText>
                                                {updateErrors.video_id._errors[0]}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={CARD_STYLE}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editingProduct}
                                            onChange={handleEditingProductChange}
                                        />
                                    }
                                    label={<Typography fontWeight={500}>Current ASIN</Typography>}
                                />
                                {product ? (
                                    <ProductSelect
                                        id="product"
                                        product={product}
                                        onChange={setProduct}
                                        disabled={!editingProduct}
                                    />
                                ) : (
                                    <CircularProgress />
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            {mutation.error && (
                                <ErrorMessage>{mutation.error.message}</ErrorMessage>
                            )}
                            <Button
                                variant="contained"
                                onClick={showConfirmation}
                                disabled={!valid || busy}
                            >
                                Submit Ad
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}
