import { Zodios, type ZodiosOptions, mergeApis } from "@zodios/core";
import { type TokenProvider, pluginToken } from "@zodios/plugins";
import { useMemo } from "react";

import { type UseAuthHandle, useAuth } from "../auth";
import { api as amazonApi } from "./amazon";
import { api as authApi } from "./auth";
import { api as metaApi } from "./meta";
import { api as shopifyApi } from "./shopify";
import { api as tasksApi } from "./tasks";

const SERVER_URL = import.meta.env.SERVER_URL;
const API_URL = `${SERVER_URL}/api`;

const api = mergeApis({
    "": authApi,
    "/tasks": tasksApi,
    "/amazon": amazonApi,
    "/shopify": shopifyApi,
    "/meta": metaApi,
});

const BASE_API_CONFIG: ZodiosOptions = {
    axiosConfig: {
        paramsSerializer: {
            // don't append `[]` to array parameters
            indexes: null,
        },
    },
};

export function createApiClient(tokenProvider?: TokenProvider) {
    const client = new Zodios(API_URL, api, BASE_API_CONFIG);

    if (tokenProvider) {
        client.use(pluginToken(tokenProvider));
    }

    return client;
}

export const API_CLIENT_NO_AUTH = createApiClient();

export function createApiClientForAuth(auth: UseAuthHandle) {
    const getToken = async () => {
        if (auth.state !== "loggedIn") {
            return;
        }

        return auth.user.access_token;
    };

    const renewToken = async () => {
        if (auth.state !== "loggedIn") {
            return;
        }

        const { refresh_token } = auth.user;
        const credentials = await API_CLIENT_NO_AUTH.refresh({
            refresh_token,
        });

        try {
            await auth.refresh(credentials);
        } catch (error) {
            console.error(error);
            await auth.logOut({ callApi: false });
        }

        return credentials.access_token;
    };

    const tokenProvider = {
        getToken,
        renewToken,
    };

    return createApiClient(tokenProvider);
}

export function useApiClient() {
    const auth = useAuth();
    return useMemo(() => createApiClientForAuth(auth), [auth]);
}
