import { createFileRoute } from "@tanstack/react-router";
import { Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/amazon")({
    component: AmazonAds,
    beforeLoad: () => ({
        getTitle: () => "Amazon",
    }),
});

function AmazonAds() {
    return <Outlet />;
}
