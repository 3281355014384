import {
    Box,
    CircularProgress,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import * as Immutable from "immutable";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";

import { useApiClient } from "../api";
import { formatDate } from "../utils";
import { Currency } from "./Currency";
import { ErrorMessage } from "./ErrorMessage";

export interface SalesListProps {
    profileId: number;
    styles: number[];
}

interface SalesPeriod {
    label: string;
    startDateDaysAgo: number;
    endDateDaysAgo: number;
}

const SALES_PERIODS: SalesPeriod[] = [
    { label: "30 days", startDateDaysAgo: 30, endDateDaysAgo: 0 },
    { label: "60 days", startDateDaysAgo: 60, endDateDaysAgo: 0 },
    { label: "90 days", startDateDaysAgo: 90, endDateDaysAgo: 0 },
];

// TODO: update `CampaignList` in a similar way
// TODO: wrap this in <Paper> and call it `SalesListCard`?
export function SalesList({ profileId, styles }: SalesListProps) {
    const apiClient = useApiClient();
    const [salesPeriodIndex, setSalesPeriodIndex] = useState(0);
    const salesPeriod = SALES_PERIODS[salesPeriodIndex];

    const today = DateTime.now();
    const endDate = today.minus({ days: salesPeriod.endDateDaysAgo });
    const startDate = endDate.minus({ days: salesPeriod.startDateDaysAgo });

    const startDateString = formatDate(startDate);
    const endDateString = formatDate(endDate);
    const startDateIso = startDate.toISODate();
    const endDateIso = endDate.toISODate();
    const enabled = styles.length > 0;

    const salesQuery = useQuery({
        enabled,
        queryKey: ["sales", profileId, startDateIso, endDateIso, styles],
        queryFn: () =>
            apiClient.getSales({
                queries: {
                    profile_id: profileId,
                    start_date: startDateIso,
                    end_date: endDateIso,
                    styles,
                },
            }),
    });

    const handleSalesPeriodChange = (event: SelectChangeEvent) => {
        const index = Number.parseInt(event.target.value);
        setSalesPeriodIndex(index);
    };

    const sales = useMemo(() => Immutable.List(salesQuery.data ?? []), [salesQuery.data]);
    const salesByStyle = sales.groupBy((entry) => entry.style);
    const salesByStyleWithTotals = salesByStyle.entrySeq().map(([style, sales]) => {
        const total = sales.reduce((acc, entry) => acc + entry.order_revenue, 0);
        const sortedSales = sales.sort((a, b) => b.order_revenue - a.order_revenue);
        return {
            style,
            sales: sortedSales,
            total,
        };
    });

    const listItems = useMemo(
        () =>
            salesByStyleWithTotals
                .map(({ style, sales, total }) => {
                    return (
                        <Table size="small" key={style}>
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th">{style}</TableCell>
                                    <TableCell component="th" align="right">
                                        <Currency value={total} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sales.map((entry) => (
                                    <TableRow key={entry.style_color}>
                                        <TableCell>{entry.style_color}</TableCell>
                                        <TableCell align="right">
                                            <Currency value={entry.order_revenue} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    );
                })
                .toArray(),
        [salesByStyleWithTotals],
    );

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                }}
            >
                <Typography>
                    <span style={{ fontWeight: 500 }}>{startDateString}</span> to{" "}
                    <span style={{ fontWeight: 500 }}>{endDateString}</span>
                </Typography>
                <Select
                    value={salesPeriodIndex.toString()}
                    onChange={handleSalesPeriodChange}
                    size="small"
                >
                    {SALES_PERIODS.map((period, index) => (
                        <MenuItem key={period.label} value={index.toString()}>
                            {period.label}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            {salesQuery.isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 4,
                    }}
                >
                    <CircularProgress size={60} />
                </Box>
            )}
            {salesQuery.isError && <ErrorMessage>{salesQuery.error.message}</ErrorMessage>}
            {listItems}
        </>
    );
}
