import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    List,
    ListItem,
    Paper,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";
import * as Immutable from "immutable";
import { type ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { useAlerts } from "../../../alerts";
import { calculateKeywordBid } from "../../../amazon/bids";
import type { KeywordBidType } from "../../../amazon/types";
import { useApiClient } from "../../../api";
import {
    SPONSORED_PRODUCTS_BID_PLACEMENTS_LABELS,
    type SponsoredProductsBidPlacement,
    sponsoredProductsBiddingUpdateSchema,
} from "../../../api/types";
import {
    CampaignList,
    ErrorMessage,
    Loading,
    PageTitle,
    UpdateConfirmationModal,
} from "../../../components";
import { CARD_STYLE } from "../../../styles";
import { ensureNumber, pluralize } from "../../../utils";

const searchSchema = z.object({
    profile_id: z.number().int(),
    campaign_ids: z.array(z.string()),
});

export const Route = createFileRoute("/_auth/amazon/sponsored-products-bidding")({
    component: SponsoredProductsBidding,
    validateSearch: (search) => searchSchema.parse(search),
    beforeLoad: () => ({
        getTitle: () => "Sponsored Products (Bidding)",
    }),
    onError: (error: { routerCode: string }) => {
        if (error.routerCode === "VALIDATE_SEARCH") {
            throw redirect({
                to: "/amazon/bidding-update",
                search: { campaignType: "sponsored-products" },
            });
        }
    },
});

function SponsoredProductsBidding() {
    const { profile_id, campaign_ids } = Route.useSearch();
    const navigate = Route.useNavigate();

    const { addAlert } = useAlerts();

    const apiClient = useApiClient();
    const queryClient = useQueryClient();

    const biddingQuery = useQuery({
        queryKey: ["sponsoredProductsBidding", profile_id, campaign_ids],
        queryFn: () =>
            apiClient.getSponsoredProductsBidding({
                profile_id,
                campaign_ids,
            }),
    });

    const mutation = useMutation({
        mutationFn: apiClient.updateSponsoredProductsBidding,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["sponsoredProductsBidding", profile_id, campaign_ids],
            });

            const campaignCount = campaign_ids.length;
            const updatesWord = pluralize(campaignCount, "update");
            addAlert({ type: "success", message: `${campaignCount} ${updatesWord} queued` });
            await navigate({
                to: "/amazon/bidding-update",
                search: { campaignType: "sponsored-products" },
            });
        },
    });

    const [editingBidAdjustments, setEditingBidAdjustments] = useState<
        Immutable.Map<SponsoredProductsBidPlacement, boolean>
    >(Immutable.Map({}));
    const [bidAdjustments, setBidAdjustments] = useState<
        Immutable.Map<SponsoredProductsBidPlacement, number>
    >(
        Immutable.Map({
            PLACEMENT_TOP: 0,
            PLACEMENT_PRODUCT_PAGE: 0,
            PLACEMENT_REST_OF_SEARCH: 0,
        }),
    );

    const enabledBidAdjustments = useMemo(
        () =>
            bidAdjustments.filter(
                (value, placement) => editingBidAdjustments.get(placement) ?? false,
            ),
        [bidAdjustments, editingBidAdjustments],
    );

    const [editingKeywordBid, setEditingKeywordBid] = useState(false);
    const [keywordBidRelative, setKeywordBidRelative] = useState(0);
    const [keywordBidAbsolute, setKeywordBidAbsolute] = useState(0);
    const [keywordBidType, setKeywordBidType] = useState<KeywordBidType>("relative");

    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const initialData = biddingQuery.data;

    const keywordBid = calculateKeywordBid(
        keywordBidType,
        keywordBidRelative,
        keywordBidAbsolute,
        initialData?.keyword_bid ?? 0,
    );

    const updateResult = useMemo(
        () =>
            sponsoredProductsBiddingUpdateSchema.safeParse({
                profile_id,
                campaign_ids,
                bid_adjustments: editingBidAdjustments ? enabledBidAdjustments.toJS() : null,
                keyword_bid: editingKeywordBid ? keywordBid : null,
            }),
        [
            profile_id,
            campaign_ids,
            editingBidAdjustments,
            enabledBidAdjustments,
            editingKeywordBid,
            keywordBid,
        ],
    );

    const updateErrors = useMemo(() => updateResult.error?.format(), [updateResult]);

    const valid = updateErrors === undefined;
    const busy = biddingQuery.isLoading || mutation.isPending;

    useEffect(() => {
        if (initialData) {
            const initialAdjustments = Immutable.Map(
                initialData.bid_adjustments,
            ) as unknown as Immutable.Map<SponsoredProductsBidPlacement, number>;
            setBidAdjustments((adjustments) => adjustments.merge(initialAdjustments));
            setKeywordBidAbsolute(initialData.keyword_bid ?? 0);
        }
    }, [initialData]);

    const handleEditingBidAdjustmentsChange = useCallback(
        (placement: SponsoredProductsBidPlacement, event: ChangeEvent<HTMLInputElement>) => {
            setEditingBidAdjustments((editingBidAdjustments) =>
                editingBidAdjustments.set(placement, event.target.checked),
            );
        },
        [],
    );

    const handleEditingKeywordBidChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditingKeywordBid(event.target.checked);
    }, []);

    const handleKeywordBidAbsoluteChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = ensureNumber(event.target.value) ?? 0;
        setKeywordBidAbsolute(value);
    }, []);

    const handleKeywordBidRelativeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = ensureNumber(event.target.value) ?? 0;
        setKeywordBidRelative(value);
    }, []);

    const setBidAdjustment = useCallback(
        (placement: SponsoredProductsBidPlacement, stringValue: string) => {
            const value = ensureNumber(stringValue) ?? 0;
            setBidAdjustments((adjustments) => adjustments.set(placement, value));
        },
        [],
    );

    const showConfirmation = useCallback(() => {
        setConfirmationVisible(true);
    }, []);

    const hideConfirmation = useCallback(() => {
        setConfirmationVisible(false);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (updateResult.success) {
            await mutation.mutateAsync(updateResult.data);
        }
    }, [updateResult, mutation]);

    return (
        <Container maxWidth="lg">
            <UpdateConfirmationModal
                open={confirmationVisible}
                busy={busy}
                onClose={hideConfirmation}
                onConfirm={handleSubmit}
            >
                <List>
                    {bidAdjustments
                        .filter((value, placement) => editingBidAdjustments.get(placement))
                        .map((value, placement) => (
                            // biome-ignore lint/suspicious/noArrayIndexKey:
                            <ListItem disablePadding key={placement}>
                                <Typography fontWeight={500}>
                                    {SPONSORED_PRODUCTS_BID_PLACEMENTS_LABELS[placement]}:
                                </Typography>
                                &nbsp;{value}%
                            </ListItem>
                        ))
                        .valueSeq()}
                    {editingKeywordBid && (
                        <ListItem disablePadding>
                            <Typography fontWeight={500}>Keywords:</Typography>&nbsp; $
                            {keywordBid.toFixed(2)}
                        </ListItem>
                    )}
                </List>
            </UpdateConfirmationModal>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <PageTitle />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Selected Campaigns</Typography>
                        <CampaignList
                            profileId={profile_id}
                            campaignIds={campaign_ids}
                            campaignType="sponsored-products"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={CARD_STYLE}>
                        <Typography fontWeight={500}>Bid Adjustments</Typography>
                        {bidAdjustments
                            .map((value, placement) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey:
                                <Fragment key={placement}>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        editingBidAdjustments.get(placement) ??
                                                        false
                                                    }
                                                    onChange={(event) =>
                                                        handleEditingBidAdjustmentsChange(
                                                            placement,
                                                            event,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                SPONSORED_PRODUCTS_BID_PLACEMENTS_LABELS[placement]
                                            }
                                        />
                                        <FormControl>
                                            <TextField
                                                size="small"
                                                sx={{ width: "100px" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type="number"
                                                value={value}
                                                onChange={(event) =>
                                                    setBidAdjustment(placement, event.target.value)
                                                }
                                                disabled={
                                                    !(editingBidAdjustments.get(placement) ?? false)
                                                }
                                            />
                                        </FormControl>
                                    </Box>
                                    <Divider />
                                </Fragment>
                            ))
                            .valueSeq()}
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={editingKeywordBid}
                                        onChange={handleEditingKeywordBidChange}
                                    />
                                }
                                label="Keywords"
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    alignItems: "center",
                                }}
                            >
                                <ToggleButtonGroup
                                    color="primary"
                                    size="small"
                                    exclusive
                                    value={keywordBidType}
                                    onChange={(event, value: KeywordBidType | null) => {
                                        if (value === null) {
                                            return;
                                        }

                                        setKeywordBidType(value);
                                    }}
                                    disabled={!editingKeywordBid}
                                >
                                    <ToggleButton value="relative">&nbsp;%&nbsp;</ToggleButton>
                                    <ToggleButton value="absolute">&nbsp;$&nbsp;</ToggleButton>
                                </ToggleButtonGroup>
                                {keywordBidType === "relative" && (
                                    <>
                                        <FormControl>
                                            <TextField
                                                size="small"
                                                sx={{ width: "100px" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type="number"
                                                value={keywordBidRelative}
                                                onChange={handleKeywordBidRelativeChange}
                                                error={updateErrors?.keyword_bid !== undefined}
                                                helperText={updateErrors?.keyword_bid?._errors[0]}
                                                disabled={!editingKeywordBid}
                                            />
                                        </FormControl>
                                        <Typography>=</Typography>
                                        <Typography fontWeight={500}>
                                            ${keywordBid.toFixed(2)}
                                        </Typography>
                                    </>
                                )}
                                {keywordBidType === "absolute" && (
                                    <FormControl>
                                        <TextField
                                            size="small"
                                            sx={{ width: "100px" }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="number"
                                            value={keywordBidAbsolute}
                                            onChange={handleKeywordBidAbsoluteChange}
                                            error={updateErrors?.keyword_bid !== undefined}
                                            helperText={updateErrors?.keyword_bid?._errors[0]}
                                            disabled={!editingKeywordBid}
                                        />
                                    </FormControl>
                                )}
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                {biddingQuery.isLoading && (
                    <Grid item xs={12}>
                        <Loading />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {mutation.error && <ErrorMessage>{mutation.error.message}</ErrorMessage>}
                    <Button
                        variant="contained"
                        onClick={showConfirmation}
                        disabled={!valid || busy}
                    >
                        Submit Ad
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}
