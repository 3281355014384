import { redirect } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/meta/")({
    loader: () => {
        throw redirect({
            to: "/meta/ad-updates",
        });
    },
});
