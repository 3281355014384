import { Box, Paper, Typography } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";

import { PageTitle } from "../../components";

export const Route = createFileRoute("/_no-auth/password-reset-sent")({
    component: PasswordResetSent,
    beforeLoad: () => ({
        getTitle: () => "Password Reset Sent",
    }),
});

function PasswordResetSent() {
    return (
        <Paper sx={{ padding: 2 }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <PageTitle />
                <Box sx={{ marginTop: 2 }}>
                    <Typography variant="h6">Check your email to continue</Typography>
                </Box>
            </Box>
        </Paper>
    );
}
