import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/meta")({
    component: Meta,
    beforeLoad: () => ({
        getTitle: () => "Meta",
    }),
});

function Meta() {
    return <Outlet />;
}
