import localforage from "localforage";
import { useMemo } from "react";

export function useLocalStorage(): LocalForage {
    return useMemo(
        () =>
            localforage.createInstance({
                name: import.meta.env.APP_NAME,
            }),
        [],
    );
}
