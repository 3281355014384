import { createFileRoute } from "@tanstack/react-router";

import { useCallback } from "react";
import { z } from "zod";
import { TaskListPage } from "../../../components";

const taskPageSearchSchema = z.object({
    id: z.string().optional(),
});

export const Route = createFileRoute("/_auth/amazon/tasks")({
    component: AdsTasks,
    beforeLoad: () => ({
        getTitle: () => "Tasks",
    }),
    validateSearch: (search) => taskPageSearchSchema.parse(search),
});

function AdsTasks() {
    const { id } = Route.useSearch();
    const navigate = Route.useNavigate();

    const setTaskId = useCallback(
        (id: string | undefined) => {
            navigate({
                search: {
                    id,
                },
            });
        },
        [navigate],
    );

    return (
        <TaskListPage
            name="amazon"
            pinnedTaskDisplayNames={[
                "US Suspended Ads Report",
                "US Active Issues Report",
                "CA Suspended Ads Report",
                "CA Active Issues Report",
                "UK Suspended Ads Report",
                "UK Active Issues Report",
                "DE Suspended Ads Report",
                "DE Active Issues Report",
                "FR Suspended Ads Report",
                "FR Active Issues Report",
            ]}
            pinnedLabel="Latest reports"
            includeCountryFilter={true}
            selectedTaskId={id}
            onSelectedTaskIdChange={setTaskId}
            enableSelection={true}
        />
    );
}
